import { injectIntl } from "react-intl";
import { CircularProgress, Box, Typography } from "@mui/material";
import { LOADING } from "../../modules/language";
import { getMessageString } from "../../utility";
import { LoadingIcon } from "../../styles/images";

export const LoadingInfo = injectIntl(({ intl }) => (
  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
    <Box sx={{ display: "grid", justifyContent: "center" }}>
      <LoadingIcon />
      <CircularProgress sx={{ color: "#2b4281", height: "75px !important", width: "75px !important", margin: "auto", marginTop: "5em" }} />
      <Typography variant="h5" sx={{ color: "#2b4281", paddingTop: "1em" }}>
        {getMessageString(intl, LOADING)}
      </Typography>
    </Box>
  </Box>
));
