import { useEffect } from "react";
import { useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { SnackbarProvider, useSnackbar } from "notistack";
import { CustomNotificationComponent } from "./CustomNotificationComponent";
import { getNotification } from "../../modules/notification";
import { getMessageString } from "../../utility";

const CustomNotification = injectIntl(({ intl }) => {
  const notificationInStore = useSelector(getNotification);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (notificationInStore)
      enqueueSnackbar(getMessageString(intl, notificationInStore.message), {
        variant: "customNotification",
        type: notificationInStore.code,
        ...(notificationInStore.description && { description: getMessageString(intl, notificationInStore.description) }),
      });
    // eslint-disable-next-line
  }, [notificationInStore]);
});

export const Notification = () => (
  <SnackbarProvider
    maxSnack={10}
    anchorOrigin={{ horizontal: "center", vertical: "top" }}
    autoHideDuration={null}
    preventDuplicate
    Components={{
      customNotification: CustomNotificationComponent,
    }}
  >
    <CustomNotification />
  </SnackbarProvider>
);
