import { forwardRef } from "react";
import { SnackbarContent, useSnackbar } from "notistack";
import { Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import {
  Close as CloseIcon,
  WarningAmber as WarningAmberIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
  InfoOutlined as InfoOutlinedIcon,
} from "@mui/icons-material";
import { notificationCodes } from "../../modules/notification";

const SUCCESS_COLOR = "#43a047";
const ERROR_COLOR = "#d32f2f";
const INFO_COLOR = "#2979ff";
const WARNING_COLOR = "#ffa000";
const WHITE_COLOR = "#fff";

const coloraCard = (codiceNotifica) => {
  switch (codiceNotifica) {
    case notificationCodes.SUCCESS:
      return SUCCESS_COLOR;
    case notificationCodes.ERROR:
      return ERROR_COLOR;
    case notificationCodes.INFO:
      return INFO_COLOR;
    case notificationCodes.WARNING:
      return WARNING_COLOR;
    default:
      return WHITE_COLOR;
  }
};

const disegnaIcona = (codiceNotifica) => {
  switch (codiceNotifica) {
    case notificationCodes.SUCCESS:
      return <CheckCircleOutlineIcon sx={{ fontSize: "2em" }} />;
    case notificationCodes.ERROR:
      return <ErrorOutlineIcon sx={{ fontSize: "2em" }} />;
    case notificationCodes.INFO:
      return <InfoOutlinedIcon sx={{ fontSize: "2em" }} />;
    case notificationCodes.WARNING:
      return <WarningAmberIcon sx={{ fontSize: "2em" }} />;
    default:
      return <InfoOutlinedIcon sx={{ fontSize: "2em" }} />;
  }
};

export const CustomNotificationComponent = forwardRef(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <SnackbarContent ref={ref}>
      <Card sx={{ width: "25em", backgroundColor: coloraCard(props.type) }}>
        <CardHeader
          {...(props.type === notificationCodes.ERROR && { sx: { color: WHITE_COLOR, wordBreak: "break-word", alignItems: "flex-start" } })}
          title={<Typography variant="h5">{props.message}</Typography>}
          action={
            <IconButton aria-label="close" color="inherit" onClick={() => closeSnackbar(id)}>
              <CloseIcon />
            </IconButton>
          }
          avatar={disegnaIcona(props.type)}
        />
        {props.description && (
          <CardContent sx={{ paddingTop: 0, paddingLeft: "4em", ...(props.type === notificationCodes.ERROR && { color: WHITE_COLOR }) }}>
            {props.description}
          </CardContent>
        )}
      </Card>
    </SnackbarContent>
  );
});
