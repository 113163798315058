import { CircularProgress, Box, Typography } from "@mui/material";
import { LoadingIcon, ProntoSoccorsoLiveIcon } from "../../styles/images";

export const LoadingRaw = () => (
  <>
    <Typography variant="h1" sx={{ height: 0 }}>
      <div style={{ display: "none" }}>Caricamento</div>
    </Typography>
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "calc(100% - 4em)", padding: "2em" }}>
      <Box
        sx={{
          display: "grid",
          justifyContent: "center",
          height: "100%",
          ".prontoSoccorsoLiveIcon": {
            width: "20em",
            paddingBottom: "10em",
          },
        }}
      >
        <ProntoSoccorsoLiveIcon />
        <Box
          sx={{
            display: "grid",
            justifyContent: "center",
            ".loadingIcon": { width: "15em" },
          }}
        >
          <LoadingIcon />
          <CircularProgress sx={{ color: "#2b4281", height: "75px !important", width: "75px !important", margin: "auto", marginTop: "7em" }} />
        </Box>
      </Box>
    </Box>
  </>
);
