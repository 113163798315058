import "./Cerca.scss";

export const Cerca = () => (
  <svg id="uuid-3d3277f5-8557-4a0f-b553-103539553a85_cerca" viewBox="0 0 40.19 40.17" className="cercaIcon">
    <defs>
      <linearGradient id="uuid-56b3f0d8-342b-4169-8c8c-f41876a3eed0_cerca" x1="23.5" y1="31.84" x2="40.19" y2="31.84" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#4b78c6" />
        <stop offset="1" stopColor="#2b4281" />
      </linearGradient>
      <linearGradient id="uuid-b8b21191-c775-4f53-bfc7-f64a7a6d022d_cerca" x1="0" y1="17.22" x2="34.45" y2="17.22" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
    </defs>
    <g id="uuid-be6bcb05-ee75-4577-9151-c278540815f2_cerca">
      <path
        className="uuid-3eb551d9-7e85-40d5-a4c6-8589be3bb16d"
        d="m38.26,38.24c-1.29,1.29-2.97,1.93-4.66,1.93s-3.38-.64-4.66-1.93l-5.43-5.43c2.07-.8,4.01-2.04,5.68-3.71,1.65-1.65,2.87-3.55,3.68-5.59l5.4,5.4c2.57,2.58,2.57,6.75,0,9.33Z"
      />
      <path
        className="uuid-29a17ab5-0806-4a4c-99a3-c1eac142bf7a"
        d="m17.22,0C7.71,0,0,7.71,0,17.22s7.71,17.22,17.22,17.22,17.22-7.71,17.22-17.22S26.73,0,17.22,0Zm0,23.87c-3.67,0-6.64-2.98-6.64-6.64s2.98-6.64,6.64-6.64,6.64,2.98,6.64,6.64-2.98,6.64-6.64,6.64Z"
      />
    </g>
  </svg>
);
