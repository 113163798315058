import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Box, Checkbox, Grid, Typography, FormGroup, FormControlLabel } from "@mui/material";
import { GRADO_AFFOLLAMENTO, NORMO_AFFOLLATO_TITLE, POCO_AFFOLLATO_TITLE, AFFOLLATO_TITLE, SOVRAFFOLLATO_TITLE } from "../../modules/language";
import {
  AFFOLLAMENTO_NORMO_AFFOLLATO,
  AFFOLLAMENTO_POCO_AFFOLLATO,
  AFFOLLAMENTO_AFFOLLATO,
  AFFOLLAMENTO_SOVRAFFOLLATO,
} from "../../modules/prontoSoccorso/constants";
import { changeFilterValues, getFilterValues } from "../../modules/prontoSoccorso";
import { NormoAffollatoIcon, PocoAffollatoIcon, AffollatoIcon, SovraffollatoIcon } from "../../styles/images";
import { getMessageString } from "../../utility";

export const CheckboxAffollamento = injectIntl(({ intl }) => {
  const dispatch = useDispatch();
  const filterValues = useSelector(getFilterValues);
  const normoAffollatoChecked = filterValues[AFFOLLAMENTO_NORMO_AFFOLLATO];
  const pocoAffollatoChecked = filterValues[AFFOLLAMENTO_POCO_AFFOLLATO];
  const affollatoChecked = filterValues[AFFOLLAMENTO_AFFOLLATO];
  const sovraffollatoChecked = filterValues[AFFOLLAMENTO_SOVRAFFOLLATO];

  const Label = ({ label, icona }) => (
    <Box className="filtroBlock">
      <Typography variant="caption">{getMessageString(intl, label)}</Typography>
      {icona}
    </Box>
  );

  return (
    <Grid container spacing={2} className="checkboxAffollamento">
      <Grid item xs={12}>
        <Typography variant="h1">{getMessageString(intl, GRADO_AFFOLLAMENTO)}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => dispatch(changeFilterValues({ field: AFFOLLAMENTO_NORMO_AFFOLLATO, value: !normoAffollatoChecked }))}
                checked={normoAffollatoChecked}
              />
            }
            label={<Label label={NORMO_AFFOLLATO_TITLE} icona={<NormoAffollatoIcon />} />}
            className="filtroLabel"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => dispatch(changeFilterValues({ field: AFFOLLAMENTO_POCO_AFFOLLATO, value: !pocoAffollatoChecked }))}
                checked={pocoAffollatoChecked}
              />
            }
            label={<Label label={POCO_AFFOLLATO_TITLE} icona={<PocoAffollatoIcon />} />}
            className="filtroLabel"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => dispatch(changeFilterValues({ field: AFFOLLAMENTO_AFFOLLATO, value: !affollatoChecked }))}
                checked={affollatoChecked}
              />
            }
            label={<Label label={AFFOLLATO_TITLE} icona={<AffollatoIcon />} />}
            className="filtroLabel"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => dispatch(changeFilterValues({ field: AFFOLLAMENTO_SOVRAFFOLLATO, value: !sovraffollatoChecked }))}
                checked={sovraffollatoChecked}
              />
            }
            label={<Label label={SOVRAFFOLLATO_TITLE} icona={<SovraffollatoIcon />} />}
            className="filtroLabel"
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
});
