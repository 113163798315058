import "./CodicePriorità.scss";

export const CodicePriorità = () => (
  <svg id="uuid-9437d813-3f77-486b-a8e9-3f7aa1851612_codicePriorita" viewBox="0 0 44.41 40.22" className="codicePrioritaIcon">
    <defs>
      <linearGradient
        id="uuid-7f4de986-2a46-4c07-baa9-6c90bb207427_codicePriorita"
        x1="4.43"
        y1="20.11"
        x2="41.43"
        y2="20.11"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
      <linearGradient
        id="uuid-d94a25c1-ef64-4f78-9d78-3abf8e986fc1_codicePriorita"
        x1="0"
        y1="10.43"
        x2="16.1"
        y2="10.43"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4b78c6" />
        <stop offset="1" stopColor="#2b4281" />
      </linearGradient>
      <linearGradient
        id="uuid-960a3164-433b-48f1-a60a-6aa963ef4160_codicePriorita"
        x1="19.06"
        y1="21.1"
        x2="32.17"
        y2="21.1"
        xlinkHref="#uuid-d94a25c1-ef64-4f78-9d78-3abf8e986fc1_codicePriorita"
      />
      <linearGradient
        id="uuid-1a7271fa-4cfb-42ba-8381-61efbe8e5b95_codicePriorita"
        x1="35.68"
        y1="30.79"
        x2="44.41"
        y2="30.79"
        xlinkHref="#uuid-d94a25c1-ef64-4f78-9d78-3abf8e986fc1_codicePriorita"
      />
    </defs>
    <g id="uuid-cb788754-3124-47d0-b3a0-3da6022f22b4_codicePriorita">
      <rect className="uuid-f93cab90-7c06-41ae-be2c-3a00214b21be" x="4.43" width="37" height="40.22" rx="5.01" ry="5.01" />
      <path
        className="uuid-2fbdd687-45ee-4079-b763-a4af694289b6"
        d="m8.05,2.38c-2.15,0-4.17.84-5.69,2.36C.84,6.26,0,8.28,0,10.43c0,2.15.84,4.17,2.36,5.69,1.57,1.57,3.63,2.35,5.69,2.35,2.06,0,4.12-.78,5.69-2.35h0c1.52-1.52,2.36-3.54,2.36-5.69,0-2.15-.84-4.17-2.36-5.69-1.52-1.52-3.54-2.36-5.69-2.36h0"
      />
      <path
        className="uuid-977d466d-0ee5-4717-bf27-d2ceb9bc6430"
        d="m25.61,14.55c-1.75,0-3.39.68-4.63,1.92-1.24,1.24-1.92,2.88-1.92,4.63,0,1.75.68,3.39,1.92,4.63,1.28,1.28,2.95,1.92,4.63,1.92,1.68,0,3.35-.64,4.63-1.92h0c1.24-1.24,1.92-2.88,1.92-4.63,0-1.75-.68-3.39-1.92-4.63-1.24-1.24-2.88-1.92-4.63-1.92h0"
      />
      <path
        className="uuid-4d500e08-22a3-4b0c-bbe6-504e497f0a05"
        d="m40.05,26.43c-1.17,0-2.26.45-3.09,1.28-.82.82-1.28,1.92-1.28,3.09,0,1.17.45,2.26,1.28,3.09.85.85,1.97,1.28,3.09,1.28,1.12,0,2.24-.43,3.09-1.28h0c.82-.82,1.28-1.92,1.28-3.09,0-1.17-.45-2.26-1.28-3.09-.82-.82-1.92-1.28-3.09-1.28h0"
      />
    </g>
  </svg>
);
