import "./Alert.scss";

export const Alert = () => (
  <svg id="uuid-46b8dca1-fc78-4073-b23e-25bfa6b43d8f_alert" viewBox="0 0 4.63 26.87" className="alertIcon">
    <defs>
      <linearGradient id="uuid-4086a258-c700-4f73-8112-6be05e92d43d_alert" x1="0" y1="24.44" x2="4.63" y2="24.44" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
      <linearGradient id="uuid-4b1347f2-6267-49d1-a672-7b783052060c_alert" x1=".45" y1="9.41" x2="4.17" y2="9.41" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#4b78c6" />
        <stop offset="1" stopColor="#2b4281" />
      </linearGradient>
    </defs>
    <g id="uuid-670b86fb-8cee-40ae-b8b5-a728ba4c3862_alert">
      <path
        className="uuid-361cad04-facb-4406-a618-0faff812046a"
        d="m0,24.44c0-1.22.73-2.15,1.79-2.38,1.58-.34,2.97,1.03,2.83,2.64-.11,1.24-.98,2.17-2.3,2.17s-2.31-1.06-2.31-2.43Z"
      />
      <path
        className="uuid-c9d493f3-edb7-47e1-b30a-50a97460c772"
        d="m.96,18.07L.45.81c-.01-.44.34-.81.78-.81h2.15c.44,0,.8.36.78.81l-.5,17.26c-.01.42-.36.76-.78.76h-1.15c-.42,0-.77-.34-.78-.76Z"
      />
    </g>
  </svg>
);
