import "./ProntoSoccorsoLive.scss";

export const ProntoSoccorsoLive = () => (
  <svg id="uuid-da5976a8-fc9f-4172-b8f9-fb12eee4002b_prontoSoccorsoLive" viewBox="0 0 568.16 100.81" className="prontoSoccorsoLiveIcon">
    <g id="uuid-a2bf58af-b697-496c-afa1-8d3516a904da_prontoSoccorsoLive">
      <g id="uuid-cb6b030d-cd61-4185-8c44-ce36fb953a22_prontoSoccorsoLive" className="uuid-9c78df70-1a7e-4f87-bb8b-b7a8b21f6332">
        <path
          className="uuid-09b83b00-16ee-4425-91ee-c4e60c712188"
          d="m271.91,10.47c-2.6,0-4.61.52-6.05,1.54-1.43,1.03-2.15,2.57-2.15,4.63,0,1.89,1.04,3.56,3.12,4.99,2.08,1.43,4.86,2.8,8.32,4.1,2.81,1.03,5.22,2.21,7.22,3.53,2,1.33,3.66,3.15,4.99,5.48,1.32,2.33,1.99,5.28,1.99,8.85,0,3.08-.8,5.94-2.39,8.56-1.6,2.62-3.94,4.71-7.02,6.25-3.08,1.54-6.76,2.31-11.04,2.31-3.57,0-7.11-.53-10.63-1.58-3.52-1.06-6.79-2.66-9.82-4.83l5.19-9.17c2.06,1.52,4.41,2.76,7.06,3.73,2.65.97,5.03,1.46,7.14,1.46,2.49,0,4.65-.53,6.49-1.58,1.84-1.06,2.76-2.77,2.76-5.15,0-3.08-2.89-5.76-8.68-8.03-3.41-1.35-6.25-2.65-8.52-3.9-2.27-1.24-4.23-3.03-5.88-5.36-1.65-2.32-2.48-5.25-2.48-8.76,0-5.14,1.69-9.28,5.07-12.42,3.38-3.14,7.88-4.84,13.51-5.11,4.44,0,8.14.5,11.12,1.5,2.97,1,5.87,2.42,8.68,4.26l-4.46,9.01c-5.19-2.87-9.71-4.3-13.55-4.3Z"
        />
        <g>
          <path
            className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0"
            d="m294.84,32.14c1.7-2.87,4.11-5.12,7.22-6.78,3.11-1.65,6.64-2.48,10.59-2.48s7.43.83,10.43,2.48c3,1.65,5.32,3.91,6.94,6.78,1.62,2.87,2.43,6.11,2.43,9.74s-.81,6.9-2.43,9.82c-1.62,2.92-3.94,5.21-6.94,6.86-3,1.65-6.53,2.48-10.59,2.48s-7.45-.77-10.51-2.31c-3.06-1.54-5.44-3.75-7.14-6.61-1.7-2.87-2.56-6.28-2.56-10.23,0-3.62.85-6.87,2.56-9.74Zm11.32,16.84c1.62,1.87,3.68,2.8,6.17,2.8s4.69-.92,6.29-2.76c1.6-1.84,2.39-4.19,2.39-7.06s-.8-5.22-2.39-7.06c-1.6-1.84-3.69-2.76-6.29-2.76s-4.54.93-6.17,2.8-2.43,4.21-2.43,7.02.81,5.15,2.43,7.02Z"
          />
          <path
            className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0"
            d="m364.87,59.89c-2.35.76-4.67,1.14-6.94,1.14-6.17,0-11.05-1.66-14.65-4.99-3.6-3.33-5.4-7.91-5.4-13.76,0-3.79.88-7.1,2.64-9.94,1.76-2.84,4.07-5,6.94-6.49,2.87-1.49,5.95-2.23,9.25-2.23,5.9,0,10.68,1.57,14.36,4.71l-5.52,7.3c-2.33-1.84-4.92-2.76-7.79-2.76-2.38,0-4.4.89-6.05,2.68-1.65,1.79-2.48,4.03-2.48,6.74s.89,4.96,2.68,6.78c1.79,1.81,4.11,2.72,6.98,2.72,2.49,0,4.54-.68,6.17-2.03l5.68,7.14c-1.57,1.25-3.53,2.25-5.88,3Z"
          />
          <path
            className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0"
            d="m403.01,59.89c-2.35.76-4.67,1.14-6.94,1.14-6.17,0-11.05-1.66-14.65-4.99-3.6-3.33-5.4-7.91-5.4-13.76,0-3.79.88-7.1,2.64-9.94,1.76-2.84,4.07-5,6.94-6.49,2.87-1.49,5.95-2.23,9.25-2.23,5.9,0,10.68,1.57,14.36,4.71l-5.52,7.3c-2.33-1.84-4.92-2.76-7.79-2.76-2.38,0-4.4.89-6.05,2.68-1.65,1.79-2.48,4.03-2.48,6.74s.89,4.96,2.68,6.78c1.79,1.81,4.11,2.72,6.98,2.72,2.49,0,4.54-.68,6.17-2.03l5.68,7.14c-1.57,1.25-3.53,2.25-5.88,3Z"
          />
          <path
            className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0"
            d="m416.73,32.14c1.7-2.87,4.11-5.12,7.22-6.78,3.11-1.65,6.64-2.48,10.59-2.48s7.43.83,10.43,2.48c3,1.65,5.32,3.91,6.94,6.78,1.62,2.87,2.43,6.11,2.43,9.74s-.81,6.9-2.43,9.82c-1.62,2.92-3.94,5.21-6.94,6.86-3,1.65-6.53,2.48-10.59,2.48s-7.45-.77-10.51-2.31c-3.06-1.54-5.44-3.75-7.14-6.61-1.7-2.87-2.56-6.28-2.56-10.23,0-3.62.85-6.87,2.56-9.74Zm11.32,16.84c1.62,1.87,3.68,2.8,6.17,2.8s4.69-.92,6.29-2.76c1.6-1.84,2.39-4.19,2.39-7.06s-.8-5.22-2.39-7.06c-1.6-1.84-3.69-2.76-6.29-2.76s-4.54.93-6.17,2.8-2.43,4.21-2.43,7.02.81,5.15,2.43,7.02Z"
          />
          <path
            className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0"
            d="m477.68,27.59c1.7-1.51,3.45-2.69,5.23-3.53,1.79-.84,3.19-1.26,4.22-1.26l-.57,11.36c-.33-.05-.79-.08-1.38-.08-2.27,0-4.27.6-6.01,1.79-1.73,1.19-3.06,2.72-3.98,4.59-.92,1.87-1.38,3.77-1.38,5.72v13.88h-11.28V24.59h10.23l.89,8.12c.97-1.89,2.31-3.6,4.02-5.11Z"
          />
          <path
            className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0"
            d="m498.41,59.24c-2.54-1.03-4.65-2.46-6.33-4.3l4.71-6.25c3.14,2.92,6.09,4.38,8.85,4.38,1.46,0,2.64-.23,3.53-.69.89-.46,1.34-1.15,1.34-2.07,0-1.08-.51-1.91-1.54-2.48-1.03-.57-2.18-1.04-3.45-1.42-1.27-.38-2.12-.62-2.56-.73-3.08-.97-5.38-2.42-6.9-4.34-1.52-1.92-2.27-4.13-2.27-6.61,0-1.89.5-3.75,1.5-5.56,1-1.81,2.56-3.3,4.67-4.46,2.11-1.16,4.73-1.75,7.87-1.75,2.92,0,5.41.3,7.47.89,2.06.6,4.03,1.57,5.92,2.92l-4.3,6.66c-1.08-.86-2.29-1.58-3.61-2.15-1.33-.57-2.53-.85-3.61-.85-1.3,0-2.35.27-3.17.81s-1.22,1.19-1.22,1.95c-.05,1.14.42,1.99,1.42,2.56,1,.57,2.56,1.15,4.67,1.75.32.11.66.22,1.01.33.35.11.72.22,1.1.32,5.57,1.84,8.36,5.19,8.36,10.06,0,2.27-.57,4.36-1.7,6.25-1.14,1.89-2.85,3.42-5.15,4.59-2.3,1.16-5.1,1.74-8.4,1.74-2.92,0-5.65-.51-8.2-1.54Z"
          />
          <path
            className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0"
            d="m530.43,32.14c1.7-2.87,4.11-5.12,7.22-6.78,3.11-1.65,6.64-2.48,10.59-2.48s7.43.83,10.43,2.48c3,1.65,5.32,3.91,6.94,6.78,1.62,2.87,2.43,6.11,2.43,9.74s-.81,6.9-2.43,9.82c-1.62,2.92-3.94,5.21-6.94,6.86-3,1.65-6.53,2.48-10.59,2.48s-7.45-.77-10.51-2.31c-3.06-1.54-5.44-3.75-7.14-6.61-1.7-2.87-2.56-6.28-2.56-10.23,0-3.62.85-6.87,2.56-9.74Zm11.32,16.84c1.62,1.87,3.68,2.8,6.17,2.8s4.69-.92,6.29-2.76c1.6-1.84,2.39-4.19,2.39-7.06s-.8-5.22-2.39-7.06c-1.6-1.84-3.69-2.76-6.29-2.76s-4.54.93-6.17,2.8-2.43,4.21-2.43,7.02.81,5.15,2.43,7.02Z"
          />
        </g>
      </g>
      <g id="uuid-4ecb0c68-d2e1-4b7e-98ca-535e6aaf5df6_prontoSoccorsoLive" className="uuid-9c78df70-1a7e-4f87-bb8b-b7a8b21f6332">
        <path
          className="uuid-09b83b00-16ee-4425-91ee-c4e60c712188"
          d="m35.08,5.69c4.01,3.6,6.01,8.51,6.01,14.74,0,3.57-.69,6.92-2.07,10.03-1.38,3.11-3.63,5.67-6.74,7.67-3.11,2-7.13,3-12.06,3h-8.44v18.92H0V.29h18.11c7.31,0,12.96,1.8,16.97,5.4Zm-9.58,22.49c1.35-1,2.33-2.18,2.92-3.53.59-1.35.89-2.65.89-3.9,0-2.11-.62-4.15-1.87-6.13-1.25-1.98-3.65-2.96-7.23-2.96h-8.44v18.03h8.36c2.22,0,4.01-.5,5.36-1.5Z"
        />
        <g>
          <path
            className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0"
            d="m60.29,27.57c1.71-1.51,3.45-2.69,5.24-3.53,1.79-.84,3.19-1.26,4.22-1.26l-.57,11.37c-.33-.05-.79-.08-1.38-.08-2.27,0-4.28.6-6.01,1.79-1.73,1.19-3.06,2.72-3.98,4.59-.92,1.87-1.38,3.78-1.38,5.72v13.89h-11.29V24.57h10.23l.89,8.12c.97-1.89,2.31-3.6,4.02-5.12Z"
          />
          <path
            className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0"
            d="m75.88,32.12c1.71-2.87,4.11-5.13,7.23-6.78,3.11-1.65,6.64-2.48,10.6-2.48s7.43.83,10.43,2.48c3,1.65,5.32,3.91,6.94,6.78,1.62,2.87,2.44,6.12,2.44,9.74s-.81,6.9-2.44,9.83c-1.62,2.92-3.94,5.21-6.94,6.86-3,1.65-6.54,2.48-10.6,2.48s-7.46-.77-10.52-2.31c-3.06-1.54-5.44-3.75-7.15-6.62-1.71-2.87-2.56-6.28-2.56-10.23,0-3.63.85-6.87,2.56-9.74Zm11.33,16.85c1.62,1.87,3.68,2.8,6.17,2.8s4.7-.92,6.29-2.76c1.6-1.84,2.4-4.2,2.4-7.07s-.8-5.22-2.4-7.06c-1.6-1.84-3.69-2.76-6.29-2.76s-4.55.93-6.17,2.8-2.44,4.21-2.44,7.02.81,5.16,2.44,7.02Z"
          />
          <path
            className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0"
            d="m154.81,25.87c2.25,2,3.4,4.55,3.45,7.63v26.55h-11.37v-22.33c-.16-3.46-1.9-5.2-5.2-5.2-2.6,0-4.68,1.22-6.25,3.65-1.57,2.44-2.35,5.58-2.35,9.42v14.45h-11.37V24.57h10.31l.81,5.68c1.3-2.33,3.1-4.14,5.4-5.44,2.3-1.3,5.02-1.95,8.16-1.95,3.36,0,6.16,1,8.4,3Z"
          />
          <path
            className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0"
            d="m183.92,8.98v15.43h8.61v8.85h-8.61v26.8h-11.37v-26.8h-5.52v-8.85h5.52v-15.43h11.37Z"
          />
          <path
            className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0"
            d="m200.28,32.12c1.71-2.87,4.11-5.13,7.23-6.78,3.11-1.65,6.64-2.48,10.6-2.48s7.43.83,10.43,2.48c3,1.65,5.32,3.91,6.94,6.78,1.62,2.87,2.44,6.12,2.44,9.74s-.81,6.9-2.44,9.83c-1.62,2.92-3.94,5.21-6.94,6.86-3,1.65-6.54,2.48-10.6,2.48s-7.46-.77-10.52-2.31c-3.06-1.54-5.44-3.75-7.15-6.62-1.71-2.87-2.56-6.28-2.56-10.23,0-3.63.85-6.87,2.56-9.74Zm11.33,16.85c1.62,1.87,3.68,2.8,6.17,2.8s4.7-.92,6.29-2.76c1.6-1.84,2.4-4.2,2.4-7.07s-.8-5.22-2.4-7.06c-1.6-1.84-3.69-2.76-6.29-2.76s-4.55.93-6.17,2.8-2.44,4.21-2.44,7.02.81,5.16,2.44,7.02Z"
          />
        </g>
      </g>
      <g>
        <path className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0" d="m422.95,68.86v25.14h-1.55v-25.14h1.55Z" />
        <path
          className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0"
          d="m428.85,75.52c.23-.21.5-.31.81-.31s.57.11.79.31c.22.21.33.47.33.78,0,.33-.11.6-.33.81-.22.21-.49.31-.79.31s-.58-.11-.81-.33c-.23-.22-.35-.48-.35-.79s.12-.57.35-.78Zm1.54,5.54v12.93h-1.55v-12.93h1.55Z"
        />
        <path className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0" d="m447.42,81.06l-6.42,13.43h-.2l-6.55-13.43h1.82l4.9,10.65,4.73-10.65h1.72Z" />
        <path
          className="uuid-a006d8ec-02f6-4812-8a72-e4b034a71bf0"
          d="m460.56,91.48l.79,1.12c-.64.51-1.36.91-2.17,1.21-.81.3-1.62.45-2.43.45-1.32,0-2.51-.3-3.56-.89-1.05-.6-1.86-1.41-2.45-2.43-.58-1.03-.88-2.18-.88-3.46s.31-2.38.93-3.42c.62-1.05,1.46-1.88,2.51-2.5,1.06-.62,2.22-.93,3.47-.93,1.5,0,2.78.44,3.84,1.32,1.06.88,1.83,2.07,2.32,3.57l-11.05,4.27c.42.93,1.06,1.68,1.94,2.25.87.57,1.86.86,2.96.86,1.39,0,2.65-.47,3.77-1.42Zm-6.55-8.8c-.82.49-1.46,1.14-1.92,1.97s-.69,1.75-.69,2.76c0,.46.04.87.13,1.22l9.53-3.74c-.42-.88-1-1.59-1.75-2.13-.75-.54-1.62-.81-2.61-.81s-1.86.24-2.68.73Z"
        />
      </g>
      <g>
        <path className="uuid-09b83b00-16ee-4425-91ee-c4e60c712188" d="m470.28,70.08v20.81h12.17v3.11h-15.38v-23.92h3.21Z" />
        <path
          className="uuid-09b83b00-16ee-4425-91ee-c4e60c712188"
          d="m487.22,74.15c.37-.33.8-.5,1.29-.5s.91.17,1.27.5c.36.33.55.74.55,1.22s-.18.89-.55,1.22c-.36.33-.79.5-1.27.5s-.92-.17-1.29-.5c-.38-.33-.56-.74-.56-1.22s.19-.89.56-1.22Zm2.75,6.15v13.7h-3.08v-13.7h3.08Z"
        />
        <path
          className="uuid-09b83b00-16ee-4425-91ee-c4e60c712188"
          d="m509.78,80.3v13.79c0,1.26-.32,2.39-.96,3.41-.64,1.01-1.55,1.82-2.73,2.41-1.18.6-2.56.89-4.15.89-1.39,0-2.59-.23-3.59-.69-1-.46-2.01-1.15-3.03-2.05l1.82-2.12c.75.71,1.5,1.24,2.25,1.6.75.36,1.58.55,2.48.55,1.52,0,2.7-.36,3.52-1.09.83-.73,1.25-1.71,1.27-2.94l.03-2.58c-.51.86-1.25,1.55-2.23,2.07-.98.52-2.11.78-3.39.78s-2.41-.32-3.47-.96-1.9-1.51-2.53-2.61c-.63-1.1-.94-2.33-.94-3.67s.33-2.64.98-3.75c.65-1.11,1.53-1.99,2.63-2.63,1.1-.64,2.28-.96,3.54-.96,1.17,0,2.25.23,3.24.69.99.46,1.73,1.06,2.22,1.79l.23-1.92h2.81Zm-4.76,10.52c.86-.64,1.42-1.48,1.69-2.51v-2.71c-.31-.99-.89-1.79-1.75-2.4-.86-.61-1.85-.91-2.98-.91-1.35,0-2.47.44-3.37,1.32-.9.88-1.36,2.01-1.36,3.37,0,.88.21,1.69.63,2.41.42.73.99,1.31,1.72,1.74s1.52.65,2.38.65c1.17,0,2.18-.32,3.04-.96Z"
        />
        <path
          className="uuid-09b83b00-16ee-4425-91ee-c4e60c712188"
          d="m527.87,80.3v13.7h-2.78l-.2-2.61c-.46.93-1.18,1.68-2.15,2.27-.97.58-2.05.88-3.24.88-1.26,0-2.29-.38-3.09-1.14-.81-.76-1.23-1.75-1.27-2.96v-10.12h3.08v9.03c.04.77.27,1.38.68,1.84.41.45,1.02.69,1.84.71.75,0,1.43-.23,2.05-.68.62-.45,1.11-1.08,1.47-1.87.36-.79.55-1.68.55-2.65v-6.38h3.08Z"
        />
        <path
          className="uuid-09b83b00-16ee-4425-91ee-c4e60c712188"
          d="m537.71,81.56c.65-.57,1.34-1.01,2.07-1.32.73-.31,1.37-.47,1.92-.5l-.13,3.08c-.99-.04-1.89.15-2.7.6-.81.44-1.43,1.05-1.89,1.84-.45.78-.68,1.62-.68,2.5v6.25h-3.08v-13.7h2.75l.23,3.24c.35-.75.85-1.41,1.51-1.98Z"
        />
        <path
          className="uuid-09b83b00-16ee-4425-91ee-c4e60c712188"
          d="m546,74.15c.37-.33.8-.5,1.29-.5s.91.17,1.27.5c.36.33.55.74.55,1.22s-.18.89-.55,1.22c-.36.33-.79.5-1.27.5s-.92-.17-1.29-.5c-.38-.33-.56-.74-.56-1.22s.19-.89.56-1.22Zm2.75,6.15v13.7h-3.08v-13.7h3.08Z"
        />
        <path
          className="uuid-09b83b00-16ee-4425-91ee-c4e60c712188"
          d="m568.16,80.3v13.7h-3.08v-2.25c-.49.64-1.19,1.23-2.12,1.77-.93.54-1.97.81-3.14.81-1.3,0-2.48-.28-3.52-.84-1.05-.56-1.87-1.38-2.48-2.46-.61-1.08-.91-2.37-.91-3.87s.31-2.81.94-3.92c.63-1.11,1.48-1.97,2.55-2.58,1.07-.61,2.25-.91,3.56-.91,1.17,0,2.18.25,3.04.76.86.51,1.55,1.17,2.08,1.98l.23-2.18h2.84Zm-4.61,10.54c.8-.63,1.32-1.48,1.54-2.56v-2.71c-.26-.97-.82-1.76-1.65-2.37-.84-.61-1.8-.91-2.88-.91-.82,0-1.57.2-2.27.6-.69.4-1.25.95-1.65,1.67-.41.72-.61,1.53-.61,2.43s.21,1.69.63,2.41c.42.73.98,1.31,1.69,1.74.71.43,1.48.65,2.32.65,1.12,0,2.09-.31,2.89-.94Z"
        />
      </g>
    </g>
  </svg>
);
