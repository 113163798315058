import { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "../ProgressBar";
import {
  CLASSIFICAZIONE_PS,
  ID_OSPEDALE_JSON,
  NOME_BREVE_OSPEDALE_JSON,
  LOCALITA_JSON,
  CLASSIFICAZIONE_OSPEDALE_JSON,
  IS_OSPEDALE_CLOSE_JSON,
  PREV_LOCATION,
} from "../../modules/prontoSoccorso";
import { OspedaleClosedModal } from "../elencoProntoSoccorso/OspedaleClosedModal";
import { PATH_DASHBOARD, PATH_DETTAGLIO } from "../../path";
import { AperturaProntoSoccorsoDashboard } from "./AperturaProntoSoccorsoDashboard";

export const BodyDashboard = ({ data }) => {
  const [showClosedModal, setShowClosedModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Box className="bodyDashboard">
        <Button
          onClick={() => {
            if (data[IS_OSPEDALE_CLOSE_JSON]) setShowClosedModal(true);
            else {
              sessionStorage.setItem(PREV_LOCATION, PATH_DASHBOARD);
              navigate(PATH_DETTAGLIO + "/" + data[ID_OSPEDALE_JSON]);
            }
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2">{data[NOME_BREVE_OSPEDALE_JSON]}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="classificazione">{data[CLASSIFICAZIONE_OSPEDALE_JSON]}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="localita">{data[LOCALITA_JSON]}</Typography>
            </Grid>
            <Grid item xs={12}>
              {CLASSIFICAZIONE_PS.includes(data[CLASSIFICAZIONE_OSPEDALE_JSON]) ? (
                <ProgressBar ospedale={data} />
              ) : (
                <AperturaProntoSoccorsoDashboard ospedale={data} />
              )}
            </Grid>
          </Grid>
        </Button>
      </Box>
      <OspedaleClosedModal data={data} modalOpen={showClosedModal} handleClose={() => setShowClosedModal(false)} />
    </>
  );
};
