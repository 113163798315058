import update from "immutability-helper";
import { createSlice } from "@reduxjs/toolkit";
import { fetchConfig, fetchConfigSuccess, geolocalizzaUtente, setFromSaluteSimplex } from "./index";

export const initialState = {
  data: null,
  fetchEnded: false,
  userPosition: null,
  fromSaluteSimplex: null,
};

const fetchConfigHandler = (state) => {
  return update(state, {
    data: { $set: initialState.data },
    fetchEnded: { $set: false },
  });
};

const fetchConfigSuccessHandler = (state, action) => {
  return update(state, {
    data: { $set: action.payload },
    fetchEnded: { $set: true },
  });
};

const geolocalizzaUtenteHandler = (state, action) => {
  return update(state, {
    userPosition: { $set: action.payload },
  });
};

const setFromSaluteSimplexHandler = (state, action) => {
  return update(state, {
    fromSaluteSimplex: { $set: action.payload },
  });
};

const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConfig, (state, action) => fetchConfigHandler(state));
    builder.addCase(fetchConfigSuccess, (state, action) => fetchConfigSuccessHandler(state, action));
    builder.addCase(geolocalizzaUtente, (state, action) => geolocalizzaUtenteHandler(state, action));
    builder.addCase(setFromSaluteSimplex, (state, action) => setFromSaluteSimplexHandler(state, action));
  },
});

export const configurationReducer = configurationSlice.reducer;
