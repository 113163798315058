import { useState } from "react";
import { injectIntl } from "react-intl";
import { Typography, Grid, Box, Avatar, Card, CardHeader, Button, Popover } from "@mui/material";
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from "@mui/icons-material";
import {
  NORMO_AFFOLLATO_TITLE,
  POCO_AFFOLLATO_TITLE,
  AFFOLLATO_TITLE,
  SOVRAFFOLLATO_TITLE,
  NON_DISPONIBILE_TITLE,
  PPI_TITLE,
  GRADO_AFFOLLAMENTO_LEGENDA,
} from "../../modules/language";
import { NormoAffollatoIcon, PocoAffollatoIcon, AffollatoIcon, SovraffollatoIcon, NonDisponibileIcon, PpiIcon } from "../../styles/images";
import { getMessageString } from "../../utility";

const gradi = [
  {
    title: NORMO_AFFOLLATO_TITLE,
    avatar: <NormoAffollatoIcon />,
  },
  {
    title: POCO_AFFOLLATO_TITLE,
    avatar: <PocoAffollatoIcon />,
  },
  {
    title: AFFOLLATO_TITLE,
    avatar: <AffollatoIcon />,
  },
  {
    title: SOVRAFFOLLATO_TITLE,
    avatar: <SovraffollatoIcon />,
  },
  {
    title: NON_DISPONIBILE_TITLE,
    avatar: <NonDisponibileIcon />,
  },
  {
    title: PPI_TITLE,
    avatar: <PpiIcon />,
  },
];

export const LegendaGradoAffollamento = injectIntl(({ intl }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const GradiAffollamentoPopoverContent = () => (
    <Box className="gradiAffollamentoPopoverContent">
      <Grid container>
        {gradi.map((grado, index) => (
          <Grid item xs={12} key={index}>
            <Card>
              <CardHeader
                avatar={<Avatar>{grado.avatar}</Avatar>}
                title={<Typography variant="caption">{getMessageString(intl, grado.title)}</Typography>}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <Box className="legendaGradoAffollamento">
      <Button
        className={anchorEl ? "popoverButtonClicked" : "popoverButton"}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {getMessageString(intl, GRADO_AFFOLLAMENTO_LEGENDA)}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorReference="anchorEl"
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <GradiAffollamentoPopoverContent />
      </Popover>
    </Box>
  );
});
