import "./PocoAffollato.scss";

export const PocoAffollato = () => (
  <svg id="Livello_2_pocoAffollato" viewBox="0 0 35.47 50.5" className="pocoAffollatoIcon">
    <g id="Livello_1-2_pocoAffollato">
      <g>
        <path
          id="Tracciato_3201-2_pocoAffollato"
          className="cls-3"
          d="m34.72,14.7C33.09,5.31,24.16-.97,14.78.66c-2.15.37-4.21,1.15-6.07,2.29C3.21,6.39.56,11.49.41,18.32c.07.7.14,1.76.29,2.81.49,2.64,1.48,5.17,2.91,7.44,4.03,7.1,8.47,13.95,12.9,20.79.33.66,1.14.93,1.81.6.26-.13.48-.35.61-.61,1.34-2.07,2.72-4.11,4-6.22,3.35-5.5,6.72-10.99,9.94-16.57,2.02-3.6,2.68-7.81,1.86-11.85"
        />
        <g id="Raggruppa_1283_pocoAffollato">
          <path
            className="cls-1"
            d="m5.66,32.36c2.79,5.17,7.45,11.69,10.24,16.35.33,1.23,1.74,1.75,2.41,1.13.26-.25,1.19-.63,1.32-1.13,4.66-7.45,9.31-14.9,10.19-16.35H5.66Z"
          />
        </g>
        <path
          id="Tracciato_3201-2-2_pocoAffollato"
          className="cls-2"
          d="m34.72,14.7C33.09,5.31,24.16-.97,14.78.66c-2.15.37-4.21,1.15-6.07,2.29C3.21,6.39.56,11.49.41,18.32c.07.7.14,1.76.29,2.81.49,2.64,1.48,5.17,2.91,7.44,4.03,7.1,8.47,13.95,12.9,20.79.33.66,1.14.93,1.81.6.26-.13.48-.35.61-.61,1.34-2.07,2.72-4.11,4-6.22,3.35-5.5,6.72-10.99,9.94-16.57,2.02-3.6,2.68-7.81,1.86-11.85"
        />
      </g>
    </g>
  </svg>
);
