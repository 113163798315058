import { injectIntl } from "react-intl";
import { Box, Grid, Typography } from "@mui/material";
import { AREA, ASL, CLASSIFICAZIONE } from "../../modules/language";
import {
  AREA_OSPEDALE_JSON,
  ASL_ENTE_JSON,
  CLASSIFICAZIONE_OSPEDALE_JSON,
  DETTAGLIO_CLASSIFICAZIONE_OSPEDALE_JSON,
} from "../../modules/prontoSoccorso";
import { getMessageString } from "../../utility";

export const AnagraficaProntoSoccorso = injectIntl(({ intl, data }) => (
  <Box className="anagraficaDettaglio">
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={3} sm={4} md={3} lg={4}>
            <Typography variant="caption">{getMessageString(intl, AREA)}</Typography>
          </Grid>
          <Grid item xs={9} sm={8} md={9} lg={8}>
            <Typography variant="caption">{data[AREA_OSPEDALE_JSON]}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={3} sm={7} md={6} lg={4}>
            <Typography variant="caption">{getMessageString(intl, ASL)}</Typography>
          </Grid>
          <Grid item xs={9} sm={5} md={6} lg={8}>
            <Typography variant="caption">{data[ASL_ENTE_JSON]}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={10} md={9} lg={5} xl={4}>
            <Typography variant="caption">{getMessageString(intl, CLASSIFICAZIONE)}</Typography>
          </Grid>
          <Grid item xs={8} sm={2} md={3} lg={7} xl={8}>
            {/* prettier-ignore */}
            <Typography variant="caption">
              {data[CLASSIFICAZIONE_OSPEDALE_JSON] + (data[DETTAGLIO_CLASSIFICAZIONE_OSPEDALE_JSON] !== undefined ? " " + data[DETTAGLIO_CLASSIFICAZIONE_OSPEDALE_JSON] : "")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
));
