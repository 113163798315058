import { useState } from "react";
import { injectIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, Grid, Avatar, IconButton } from "@mui/material";
import {
  NormoAffollatoIcon,
  PocoAffollatoIcon,
  AffollatoIcon,
  SovraffollatoIcon,
  PreferitiVuotoIcon,
  PreferitiIcon,
  NonDisponibileIcon,
  PpiIcon,
} from "../../styles/images";
import {
  CLASSIFICAZIONE_OSPEDALE_JSON,
  AFFOLLAMENTO_PS_JSON,
  CAP_OSPEDALE_JSON,
  CIVICO_OSPEDALE_JSON,
  PROVINCIA_OSPEDALE_JSON,
  COMUNE_OSPEDALE_JSON,
  GDA_JSON,
  ID_OSPEDALE_JSON,
  INDIRIZZO_OSPEDALE_JSON,
  IS_OSPEDALE_CLOSE_JSON,
  LOCALITA_JSON,
  NOME_BREVE_OSPEDALE_JSON,
  PREFERITI,
  PREV_LOCATION,
  CLASSIFICAZIONE_PS,
  ORARIO_APERTURA_JSON,
  ORARIO_CHIUSURA_JSON,
} from "../../modules/prontoSoccorso";
import { getMessageString } from "../../utility";
import {
  NON_DISPONIBILE_TITLE,
  NORMO_AFFOLLATO_TITLE,
  POCO_AFFOLLATO_TITLE,
  AFFOLLATO_TITLE,
  SOVRAFFOLLATO_TITLE,
  ORARIO,
  CHIUSO_TITLE,
  APERTO_TITLE,
} from "../../modules/language";
import { PATH_DETTAGLIO, PATH_ELENCO } from "../../path";
import { OspedaleClosedModal } from "./OspedaleClosedModal";

export const ProntoSoccorso = injectIntl(({ intl, ospedale, preferiti, onChangePreferiti }) => {
  const [showClosedModal, setShowClosedModal] = useState(false);
  const navigate = useNavigate();

  const sincronizzaAiPreferiti = (id) => {
    let newPreferiti = localStorage.getItem(PREFERITI) ? JSON.parse(localStorage.getItem(PREFERITI)) : [];
    if (preferiti.includes(id)) newPreferiti = newPreferiti.filter((ps) => ps !== id);
    else newPreferiti.push(id);
    localStorage.setItem(PREFERITI, JSON.stringify(newPreferiti));
    onChangePreferiti(newPreferiti);
  };

  const renderAffollamentoIcon = () => {
    if (CLASSIFICAZIONE_PS.includes(ospedale[CLASSIFICAZIONE_OSPEDALE_JSON])) {
      const infoAffollamento = ospedale[AFFOLLAMENTO_PS_JSON];
      if (infoAffollamento) {
        const gda = parseInt(infoAffollamento[GDA_JSON]);
        if (gda < 60)
          return (
            <Box>
              <NormoAffollatoIcon />
              <Typography variant="caption">{getMessageString(intl, NORMO_AFFOLLATO_TITLE)}</Typography>
            </Box>
          );
        else if (60 <= gda && gda < 90)
          return (
            <Box>
              <PocoAffollatoIcon />
              <Typography variant="caption">{getMessageString(intl, POCO_AFFOLLATO_TITLE)}</Typography>
            </Box>
          );
        else if (90 <= gda && gda < 150)
          return (
            <Box>
              <AffollatoIcon />
              <Typography variant="caption">{getMessageString(intl, AFFOLLATO_TITLE)}</Typography>
            </Box>
          );
        else
          return (
            <Box>
              <SovraffollatoIcon />
              <Typography variant="caption">{getMessageString(intl, SOVRAFFOLLATO_TITLE)}</Typography>
            </Box>
          );
      } else
        return (
          <Box>
            <NonDisponibileIcon />
            <Typography variant="caption">{getMessageString(intl, NON_DISPONIBILE_TITLE)}</Typography>
          </Box>
        );
    } else {
      return (
        <Box>
          <PpiIcon />
          <Typography variant="caption">{getMessageString(intl, ospedale[IS_OSPEDALE_CLOSE_JSON] ? CHIUSO_TITLE : APERTO_TITLE)}</Typography>
        </Box>
      );
    }
  };

  return (
    <>
      <Box sx={{ marginTop: "2em" }}>
        <Grid container spacing={2}>
          <Grid item xs={1} className="gda">
            <Avatar {...(!CLASSIFICAZIONE_PS.includes(ospedale[CLASSIFICAZIONE_OSPEDALE_JSON]) && { className: "ppi" })}>
              {renderAffollamentoIcon()}
            </Avatar>
          </Grid>
          <Grid item xs={10} className="infoProntoSoccorso">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    if (ospedale[IS_OSPEDALE_CLOSE_JSON]) setShowClosedModal(true);
                    else {
                      sessionStorage.setItem(PREV_LOCATION, PATH_ELENCO);
                      navigate(PATH_DETTAGLIO + "/" + ospedale[ID_OSPEDALE_JSON]);
                    }
                  }}
                >
                  <Typography variant="h3" sx={{ textAlign: "center" }}>
                    {ospedale[NOME_BREVE_OSPEDALE_JSON]}
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: "0 !important" }}>
                <Typography variant="h4" sx={{ textAlign: "center" }} className="classificazioneOspedale">
                  {ospedale[CLASSIFICAZIONE_OSPEDALE_JSON]}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ textAlign: "center" }}>
                  {ospedale[LOCALITA_JSON]}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: "10px !important" }}>
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: "center",
                    opacity:
                      ospedale[ORARIO_APERTURA_JSON] &&
                      ospedale[ORARIO_APERTURA_JSON] !== undefined &&
                      ospedale[ORARIO_CHIUSURA_JSON] &&
                      ospedale[ORARIO_CHIUSURA_JSON] !== undefined
                        ? 1
                        : 0,
                  }}
                >
                  {getMessageString(intl, ORARIO) + ospedale[ORARIO_APERTURA_JSON] + " - " + ospedale[ORARIO_CHIUSURA_JSON]}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: "10px !important" }}>
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  {ospedale[INDIRIZZO_OSPEDALE_JSON] +
                    ", " +
                    (ospedale[CIVICO_OSPEDALE_JSON] ? ospedale[CIVICO_OSPEDALE_JSON] + ", " : "") +
                    ospedale[CAP_OSPEDALE_JSON] +
                    ", " +
                    ospedale[COMUNE_OSPEDALE_JSON] +
                    ", " +
                    ospedale[PROVINCIA_OSPEDALE_JSON]}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} className="iconaPreferiti">
            {preferiti.includes(ospedale[ID_OSPEDALE_JSON]) ? (
              <IconButton onClick={() => sincronizzaAiPreferiti(ospedale[ID_OSPEDALE_JSON])} aria-label="Rimuovi dai preferiti">
                <PreferitiIcon />
              </IconButton>
            ) : (
              <IconButton onClick={() => sincronizzaAiPreferiti(ospedale[ID_OSPEDALE_JSON])} aria-label="Aggiungi ai preferiti">
                <PreferitiVuotoIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Box>
      <OspedaleClosedModal data={ospedale} modalOpen={showClosedModal} handleClose={() => setShowClosedModal(false)} />
    </>
  );
});
