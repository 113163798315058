import "./Mappa.scss";

export const Mappa = () => (
  <svg id="uuid-2ef5d865-2a50-4abe-9e02-863e224c6ebf_mappa" viewBox="0 0 38.81 45.55" className="mappaIcon">
    <defs>
      <linearGradient id="uuid-12282bbe-a930-4d51-98a1-420850557f7b_mappa" x1="4.67" y1="21.13" x2="34.14" y2="21.13" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
      <linearGradient id="uuid-73402d09-4dc9-4116-8276-384da141f10b_mappa" x1="0" y1="36.5" x2="38.81" y2="36.5" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#4b78c6" />
        <stop offset="1" stopColor="#2b4281" />
      </linearGradient>
    </defs>
    <g id="uuid-32b70c5f-5176-45a7-9590-c7397118dc96_mappa">
      <path
        className="uuid-a340152d-f87e-4d00-a268-f3b8f82abac4"
        d="m33.85,12.15h0C32.47,4.18,24.87-1.17,16.89.22c-1.83.32-3.58.98-5.16,1.95-4.67,2.92-6.93,7.26-7.06,13.06.06.59.12,1.5.24,2.39.42,2.25,1.26,4.39,2.48,6.33,3.43,6.04,7.2,11.86,10.97,17.69.28.56.97.79,1.54.51.22-.11.41-.3.51-.52,1.14-1.76,2.31-3.5,3.4-5.29,2.85-4.68,5.72-9.35,8.46-14.1,1.72-3.06,2.28-6.64,1.59-10.08Zm-14.45,8.98c-3.66,0-6.62-2.96-6.62-6.62s2.96-6.62,6.62-6.62,6.62,2.96,6.62,6.62-2.96,6.62-6.62,6.62Z"
      />
      <path
        className="uuid-2186b725-0ae1-4bfc-81ce-d49b42cbaa51"
        d="m38.77,41.44l-1.7-10.86c-.28-1.79-2.02-3.12-4.07-3.12h-3.81s0,0,0,0c-1.78,2.97-3.58,5.92-5.38,8.87-1.09,1.79-2.26,3.53-3.4,5.29-.11.23-.29.41-.51.52-.56.28-1.25.06-1.54-.51-3.02-4.66-6.04-9.33-8.88-14.1-.02-.03-.03-.05-.05-.08h-3.62c-2.05,0-3.79,1.33-4.07,3.12L.04,41.44c-.34,2.17,1.58,4.11,4.07,4.11h30.59c2.49,0,4.41-1.94,4.07-4.11Z"
      />
    </g>
  </svg>
);
