import { useState } from "react";
import { injectIntl } from "react-intl";
import { Box } from "@mui/material";
import { HeaderProntoSoccorso } from "./HeaderProntoSoccorso";
import { AffluenzaProntoSoccorso } from "./AffluenzaProntoSoccorso";
import { GoogleMapsModalAlert } from "./GoogleMapsModalAlert";
import { AnagraficaProntoSoccorso } from "./AnagraficaProntoSoccorso";
import { OrarioProntoSoccorso } from "./OrarioProntoSoccorso";
import { PosizioneProntoSoccorso } from "./PosizioneProntoSoccorso";
import { ErroreDettaglioProntoSoccorso } from "./ErroreDettaglioProntoSoccorso";
import {
  CAP_OSPEDALE_JSON,
  CIVICO_OSPEDALE_JSON,
  COMUNE_OSPEDALE_JSON,
  INDIRIZZO_OSPEDALE_JSON,
  NOME_COMPLETO_OSPEDALE_JSON,
  PROVINCIA_OSPEDALE_JSON,
} from "../../modules/prontoSoccorso";

const GOOGLE_MAPS_BASE_URL = "https://www.google.it/maps/place/";

export const DettaglioProntoSoccorso = injectIntl(({ intl, data }) => {
  const [googleMapsModalOpen, setGoogleMapsModalOpen] = useState(false);

  const handleModalClose = () => setGoogleMapsModalOpen(false);

  const handleModalOk = () => {
    window.open(
      GOOGLE_MAPS_BASE_URL +
        data[INDIRIZZO_OSPEDALE_JSON].toString().replaceAll(" ", "+") +
        (data[CIVICO_OSPEDALE_JSON] && data[CIVICO_OSPEDALE_JSON] !== undefined ? ",+" + data[CIVICO_OSPEDALE_JSON] : "") +
        ",+" +
        data[CAP_OSPEDALE_JSON] +
        "+" +
        data[COMUNE_OSPEDALE_JSON] +
        "+" +
        data[PROVINCIA_OSPEDALE_JSON],
      "_blank"
    );
    handleModalClose();
  };

  return data[NOME_COMPLETO_OSPEDALE_JSON] !== undefined ? (
    <Box className="dettaglioProntoSoccorso">
      <HeaderProntoSoccorso data={data} />
      <Box className="bodyDettaglio">
        <AnagraficaProntoSoccorso data={data} />
        <OrarioProntoSoccorso data={data} />
        <PosizioneProntoSoccorso data={data} onNaviga={() => setGoogleMapsModalOpen(true)} />
        <AffluenzaProntoSoccorso data={data} />
      </Box>
      <GoogleMapsModalAlert modalOpen={googleMapsModalOpen} handleClose={handleModalClose} handleOk={handleModalOk} />
    </Box>
  ) : (
    <ErroreDettaglioProntoSoccorso />
  );
});
