import "./Info.scss";

export const Info = () => (
  <svg id="uuid-f8280689-8e16-4136-a0a0-52151e48823d_info" viewBox="0 0 46.91 45.78" className="infoIcon">
    <defs>
      <linearGradient id="uuid-90e2012c-8f9d-438f-addd-db6f231eafbe_info" x1="0" y1="21.08" x2="42.17" y2="21.08" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
      <linearGradient id="uuid-857c3707-3782-41ee-8ac7-45cf9f73f5ce_info" x1="25.83" y1="3.62" x2="25.83" y2="45.78" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#4b78c6" />
        <stop offset="1" stopColor="#2b4281" />
      </linearGradient>
    </defs>
    <g id="uuid-04473685-4d5a-47c4-8d96-634d6c9f2ef0_info">
      <circle className="uuid-5b07a404-4c03-4c16-aebc-78abf61b00b1" cx="21.08" cy="21.08" r="21.08" />
      <circle className="uuid-cc269e2e-95e4-483c-8e40-e1f7f5e5e9b6" cx="25.83" cy="24.7" r="21.08" />
      <path
        className="uuid-0297db72-dfb8-4f64-bd89-caa61e153973"
        d="m30.69,33.52c-4.07,5.64-6.64,7.71-8.86,7.71-1.79,0-3.07-1.36-3.07-3.36,0-1.29.57-2.86,2.29-5.79l5.79-9.86c.43-.71.71-1.5.71-1.86s-.29-.57-.71-.57c-1.29,0-6,5.43-6.79,6.36l-1.21-.71c2.57-3.21,4.14-5.07,6-6.43,1.07-.79,2-1.21,2.93-1.21,1.71,0,3.29,1.43,3.29,3.14,0,1-.5,2.57-1.29,3.93l-5.07,8.64c-1.86,3.21-2.36,4.14-2.36,4.79,0,.5.29.79.79.79.71,0,2.07-.71,6.43-6.36l1.14.79Zm-2.93-23.29c0-1.86,1.5-3.36,3-3.36,1.21,0,2.14,1,2.14,2.14s-1,3.36-3.07,3.36c-1.21,0-2.07-.71-2.07-2.14Z"
      />
    </g>
  </svg>
);
