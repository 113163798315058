import "./Privacy.scss";

export const Privacy = () => (
  <svg id="Livello_2_privacy" viewBox="0 0 36.71 40" className="privacyIcon">
    <defs>
      <linearGradient id="Sfumatura_senza_nome_30_privacy" x1="6.1" y1=".48" x2="36.86" y2="37.17" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#4b78c6" />
        <stop offset="1" stopColor="#2b4281" />
      </linearGradient>
      <linearGradient id="Sfumatura_senza_nome_14_privacy" x1="0" y1="28.7" x2="17.39" y2="28.7" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
    </defs>
    <g id="Livello_1-2_privacy">
      <path
        className="cls-3"
        d="m34.67,6.33L20.67.17c-.52-.23-1.12-.23-1.64,0L5.03,6.33c-1.24.55-2.05,1.78-2.05,3.14v13.55c0,7.17,16.84,16.97,16.86,16.98h0s0,0,0,0h0s16.86-9.81,16.86-16.98v-13.55c0-1.36-.8-2.59-2.05-3.14Z"
      />
      <g>
        <rect className="cls-2" y="17.52" width="17.39" height="22.36" rx="4.6" ry="4.6" />
        <path
          className="cls-1"
          d="m3.01,33.02c-.29-.09-.49-.36-.49-.68,0-.4.32-.71.72-.71l8.09.03c.4,0,.71.32.71.72,0,.4-.32.71-.72.71l-8.09-.03c-.08,0-.15-.01-.22-.04Z"
        />
        <path
          className="cls-1"
          d="m3.06,36.34c-.29-.09-.49-.36-.49-.68,0-.4.32-.72.72-.71l6.19.02c.4,0,.71.32.71.72,0,.4-.32.72-.72.71l-6.19-.02c-.08,0-.15-.01-.22-.04Z"
        />
      </g>
    </g>
  </svg>
);
