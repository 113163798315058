import { createListenerMiddleware } from "@reduxjs/toolkit";
import { createRequest, GET } from "../../utility";
import {
  estraiDatiElencoProntoSoccorso,
  estraiDatiProntoSoccorso,
  fetchDettaglioProntoSoccorso,
  fetchDettaglioProntoSoccorsoSuccess,
  fetchElencoProntoSoccorso,
  fetchElencoProntoSoccorsoSuccess,
  fetchCodiciColore,
  fetchCodiciColoreSuccess,
  fetchFaq,
  fetchFaqSuccess,
  fetchPrivacy,
  fetchPrivacySuccess,
} from "./index";
import { notificationCodes, setNotification } from "../notification";
import {
  ELENCO_OSPEDALI_ERROR_TITLE,
  ELENCO_OSPEDALI_ERROR_DESCRIPTION,
  DETTAGLIO_OSPEDALE_ERROR_TITLE,
  DETTAGLIO_OSPEDALE_ERROR_DESCRIPTION,
  CODICI_COLORE_ERROR_DESCRIPTION,
  CODICI_COLORE_ERROR_TITLE,
  FAQ_ERROR_DESCRIPTION,
  FAQ_ERROR_TITLE,
  PRIVACY_ERROR_TITLE,
  PRIVACY_ERROR_DESCRIPTION,
} from "../language";

const fetchElencoProntoSoccorsoWorker = (listenerApi) => {
  createRequest(GET, "/api/prontosoccorso/all")
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchElencoProntoSoccorsoSuccess(estraiDatiElencoProntoSoccorso(response.data)));
      else throw response.message;
    })
    .catch((e) => {
      listenerApi.dispatch(setNotification({ code: notificationCodes.ERROR, message: ELENCO_OSPEDALI_ERROR_TITLE, description: ELENCO_OSPEDALI_ERROR_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchElencoProntoSoccorsoSuccess([]));
    });
};

const fetchDettaglioProntoSoccorsoWorker = (action, listenerApi) => {
  createRequest(GET, "/api/prontosoccorso/" + action.payload)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchDettaglioProntoSoccorsoSuccess(estraiDatiProntoSoccorso(response.data)));
      else throw response.message;
    })
    .catch((e) => {
      listenerApi.dispatch(setNotification({ code: notificationCodes.ERROR,  message: DETTAGLIO_OSPEDALE_ERROR_TITLE, description: (e.response && e.response.data ? e.response.data.message : DETTAGLIO_OSPEDALE_ERROR_DESCRIPTION) })); //prettier-ignore
      listenerApi.dispatch(fetchDettaglioProntoSoccorsoSuccess(null));
    });
};

const fetchFaqWorker = (action, listenerApi) => {
  createRequest(GET, "/api/faq/all?languageCulture=" + action.payload)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchFaqSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      listenerApi.dispatch(setNotification({ code: notificationCodes.ERROR, message: FAQ_ERROR_TITLE, description: FAQ_ERROR_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchFaqSuccess([]));
    });
};

const fetchCodiciColoreWorker = (action, listenerApi) => {
  createRequest(GET, "/api/codice/all?languageCulture=" + action.payload)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchCodiciColoreSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      listenerApi.dispatch(setNotification({ code: notificationCodes.ERROR, message: CODICI_COLORE_ERROR_TITLE, description: CODICI_COLORE_ERROR_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchCodiciColoreSuccess([]));
    });
};

const fetchPrivacyWorker = (action, listenerApi) => {
  createRequest(GET, "/api/privacy?languageCulture=" + action.payload)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchPrivacySuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      listenerApi.dispatch(setNotification({ code: notificationCodes.ERROR, message: PRIVACY_ERROR_TITLE, description: PRIVACY_ERROR_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchPrivacySuccess(null));
    });
};

export const prontoSoccorsoMiddleware = createListenerMiddleware();

prontoSoccorsoMiddleware.startListening({
  actionCreator: fetchElencoProntoSoccorso,
  effect: async (action, listenerApi) => fetchElencoProntoSoccorsoWorker(listenerApi),
});

prontoSoccorsoMiddleware.startListening({
  actionCreator: fetchDettaglioProntoSoccorso,
  effect: async (action, listenerApi) => fetchDettaglioProntoSoccorsoWorker(action, listenerApi),
});

prontoSoccorsoMiddleware.startListening({
  actionCreator: fetchFaq,
  effect: async (action, listenerApi) => fetchFaqWorker(action, listenerApi),
});

prontoSoccorsoMiddleware.startListening({
  actionCreator: fetchCodiciColore,
  effect: async (action, listenerApi) => fetchCodiciColoreWorker(action, listenerApi),
});

prontoSoccorsoMiddleware.startListening({
  actionCreator: fetchPrivacy,
  effect: async (action, listenerApi) => fetchPrivacyWorker(action, listenerApi),
});
