import { injectIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon } from "@mui/icons-material";
import { PREV_LOCATION } from "../../modules/prontoSoccorso";
import { NOT_FOUND_TITLE, RESOURCE_NOT_FOUND_DESCRIPTION } from "../../modules/language";
import { PATH_MAPPA } from "../../path";
import { getMessageString } from "../../utility";

export const ErroreDettaglioProntoSoccorso = injectIntl(({ intl }) => {
  const navigate = useNavigate();

  const onBack = () => {
    const prevLocation = sessionStorage.getItem(PREV_LOCATION);
    if (prevLocation && prevLocation !== undefined) {
      sessionStorage.clear();
      navigate(prevLocation);
    } else navigate(PATH_MAPPA);
  };

  return (
    <Grid container spacing={2} className="erroreDettaglioProntoSoccorso">
      <Grid item xs={12}>
        <IconButton onClick={onBack} aria-label="Torna indietro">
          <KeyboardArrowLeftOutlinedIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography variant="h1">{getMessageString(intl, NOT_FOUND_TITLE)}</Typography>
          <Typography variant="h2">{getMessageString(intl, RESOURCE_NOT_FOUND_DESCRIPTION)}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
});
