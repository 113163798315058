import "./Elenco.scss";

export const Elenco = () => (
  <svg id="uuid-2db21a64-9572-4b32-b1bb-b5b6be3ac8d8_elenco" viewBox="0 0 39.47 39.79" className="elencoIcon">
    <defs>
      <linearGradient id="uuid-df4e665d-b604-48c4-99da-67482a9714f8_elenco" x1="6.62" y1="15.93" x2="39.47" y2="15.93" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#4b78c6" />
        <stop offset="1" stopColor="#2b4281" />
      </linearGradient>
      <linearGradient id="uuid-3bfc1445-8462-4f9c-8e0f-2ebba0de32a0_elenco" x1="0" y1="23.97" x2="31.63" y2="23.97" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
    </defs>
    <g id="uuid-d1dc1f0e-a5e9-44d6-b4b0-45d328244528_elenco">
      <path
        className="uuid-acbd7e5b-b4f6-4f52-8d90-5f33877e6f6a"
        d="m34.6,0H11.49c-2.69,0-4.87,2.18-4.87,4.87v3.26h19.51c3.06,0,5.54,2.48,5.54,5.54v18.18h2.93c2.69,0,4.87-2.18,4.87-4.87V4.87c0-2.69-2.18-4.87-4.87-4.87Z"
      />
      <rect className="uuid-9540e13c-b88e-4ab5-b1e4-7be528a99f86" y="8.15" width="31.63" height="31.63" rx="5.54" ry="5.54" />
      <path
        className="uuid-1e521a71-0781-49c0-aa6e-db64ff185c4b"
        d="m19.76,27.96H3.92c-.7,0-1.27-.57-1.27-1.27h0c0-.7.57-1.27,1.27-1.27h15.85c.7,0,1.27.57,1.27,1.27h0c0,.7-.57,1.27-1.27,1.27Z"
      />
      <path
        className="uuid-1e521a71-0781-49c0-aa6e-db64ff185c4b"
        d="m13.31,33.51H3.92c-.7,0-1.27-.57-1.27-1.27h0c0-.7.57-1.27,1.27-1.27h9.39c.7,0,1.27.57,1.27,1.27h0c0,.7-.57,1.27-1.27,1.27Z"
      />
    </g>
  </svg>
);
