import { injectIntl } from "react-intl";
import { Box, Grid, Typography } from "@mui/material";
import { InfoHome } from "./InfoHome";
import { InfoIcon } from "../../styles/images";
import { NAVBAR_INFO } from "../../modules/language";
import { getMessageString } from "../../utility";

export const Info = injectIntl(({ intl }) => (
  <Box className="infoPage">
    <Grid container spacing={2}>
      <Grid item xs={12} className="infoPageTitle">
        <InfoIcon />
        <Typography variant="h1">{getMessageString(intl, NAVBAR_INFO)}</Typography>
      </Grid>
      <Grid item xs={12} className="infoPageDetail">
        <InfoHome />
      </Grid>
    </Grid>
  </Box>
));
