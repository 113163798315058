import { createListenerMiddleware } from "@reduxjs/toolkit";
import { it, en } from "../../intl";
import { IT, EN, setLocale, fetchMessages, fetchMessagesSuccess } from "./index";

const setLocaleWorker = (action, listenerApi) => {
  const locale = action.payload;
  try {
    const messages = new Map(JSON.parse(listenerApi.getState().language.messages)).get(locale);
    if (messages === undefined) listenerApi.dispatch(fetchMessages(locale));
  } catch (e) {
    console.error("Errore durante il set della lingua:", e);
  }
};

const fetchMessagesWorker = (action, listenerApi) => {
  const locale = action.payload;
  try {
    if (locale === IT) listenerApi.dispatch(fetchMessagesSuccess({ locale: locale, messages: it }));
    if (locale === EN) listenerApi.dispatch(fetchMessagesSuccess({ locale: locale, messages: en }));
  } catch (e) {
    console.error("Errore durante il caricamento del dizionario:", e);
  }
};

export const languageMiddleware = createListenerMiddleware();

languageMiddleware.startListening({
  actionCreator: setLocale,
  effect: async (action, listenerApi) => setLocaleWorker(action, listenerApi),
});

languageMiddleware.startListening({
  actionCreator: fetchMessages,
  effect: async (action, listenerApi) => fetchMessagesWorker(action, listenerApi),
});
