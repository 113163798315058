export const CS = "cs_CZ";
export const DA = "da_DK";
export const DE = "de_DE";
export const EL = "el_GR";
export const EN = "en_GB";
export const ES = "es_ES";
export const FI = "fi_FI";
export const FR = "fr_FR";
export const HU = "hu_HU";
export const IT = "it_IT";
export const JA = "ja_JP";
export const KO = "ko_KP";
export const NO = "nn_NO";
export const PL = "pl_PL";
export const PT = "pt_PT";
export const RO = "ro_RO";
export const RU = "ru_RU";
export const SV = "sv_SE";
export const TR = "tr_TR";
export const UK = "uk_UA";
