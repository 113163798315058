import "./DatoNonDisponibile.scss";

export const DatoNonDisponibile = () => (
  <svg id="uuid-ed0880bd-be80-4695-9e8c-6066f5119c1b_datoNonDisponibile" viewBox="0 0 110.34 94.79" className="datoNonDisponibileIcon">
    <defs>
      <linearGradient
        id="uuid-8e2f786f-64bd-4a0b-936e-a65021b3dad1_datoNonDisponibile"
        x1="19.6"
        y1="78.53"
        x2="81.58"
        y2="78.53"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5c6d93" />
        <stop offset="1" stopColor="#1d294b" />
      </linearGradient>
      <linearGradient
        id="uuid-7dda0e18-529d-4939-8d3e-0e2f105ffff3_datoNonDisponibile"
        x1="0"
        y1="39.59"
        x2="43"
        y2="39.59"
        xlinkHref="#uuid-8e2f786f-64bd-4a0b-936e-a65021b3dad1_datoNonDisponibile"
      />
      <linearGradient
        id="uuid-d097ff5b-1010-4cc1-aba4-3a010ebd18ec_datoNonDisponibile"
        x1="5.61"
        y1="55.87"
        x2="37.45"
        y2="55.87"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
      <linearGradient
        id="uuid-44afaf3f-04ef-47f5-9630-80fa26cd744b_datoNonDisponibile"
        x1="22.66"
        y1="18.13"
        x2="30.99"
        y2="18.13"
        xlinkHref="#uuid-d097ff5b-1010-4cc1-aba4-3a010ebd18ec_datoNonDisponibile"
      />
      <linearGradient
        id="uuid-ed1be922-dbce-4359-be2b-a6df9ad5cb8d_datoNonDisponibile"
        x1="12.24"
        y1="18.13"
        x2="20.57"
        y2="18.13"
        xlinkHref="#uuid-d097ff5b-1010-4cc1-aba4-3a010ebd18ec_datoNonDisponibile"
      />
      <linearGradient
        id="uuid-3df1ab68-6b66-463a-a4a8-2935ffa00d86_datoNonDisponibile"
        x1="19.6"
        y1="78.53"
        x2="81.58"
        y2="78.53"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4b78c6" />
        <stop offset="1" stopColor="#2b4281" />
      </linearGradient>
      <linearGradient
        id="uuid-929b3f8c-06a1-46d4-adfa-62d6a14a25ba_datoNonDisponibile"
        x1="0"
        y1="39.59"
        x2="43"
        y2="39.59"
        xlinkHref="#uuid-3df1ab68-6b66-463a-a4a8-2935ffa00d86_datoNonDisponibile"
      />
      <linearGradient
        id="uuid-015619ac-03b5-4036-924b-38ee855d21f3_datoNonDisponibile"
        x1="5.61"
        y1="55.87"
        x2="37.45"
        y2="55.87"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
      <linearGradient
        id="uuid-1538c53e-4301-4763-af50-13a8a4104e9d_datoNonDisponibile"
        x1="22.66"
        y1="18.13"
        x2="30.99"
        y2="18.13"
        xlinkHref="#uuid-015619ac-03b5-4036-924b-38ee855d21f3_datoNonDisponibile"
      />
      <linearGradient
        id="uuid-a81295e4-ef0c-4d9e-be7c-b825e0d3008e_datoNonDisponibile"
        x1="12.24"
        y1="18.13"
        x2="20.57"
        y2="18.13"
        xlinkHref="#uuid-015619ac-03b5-4036-924b-38ee855d21f3_datoNonDisponibile"
      />
    </defs>
    <g id="uuid-7a88aee4-acb3-42d7-931e-e8e2b2e07eb3_datoNonDisponibile">
      <g id="uuid-560cf248-db88-440e-9e64-f378944b5ad4_datoNonDisponibile">
        <path
          className="uuid-e6cd10ec-1aa4-4ada-b410-5109a6f79949"
          d="m50.59,94.79c-8.43,0-16.3-3.46-22.16-9.75-5.69-6.1-8.82-14.18-8.83-22.75h4.83c0,7.81,2.82,15.14,7.93,20.62,4.93,5.29,11.4,8.2,18.23,8.2h0c14.42,0,26.15-12.94,26.15-28.84h4.83c0,8.57-3.13,16.65-8.81,22.75-5.86,6.29-13.73,9.76-22.17,9.76h-.01Z"
        />
      </g>
      <g id="uuid-fd7d8b57-a3e4-418b-8072-fa1a59396781_datoNonDisponibile">
        <path
          className="uuid-dfe0398d-b84b-43d5-ac67-5eb8f82550a0"
          d="m21.83,62.81c-.11,0-.22,0-.33,0-.11,0-.23,0-.34,0v-.02c-5.48-.32-10.5-4.49-14.56-12.13C2.77,43.46.76,34.98.16,29.45c-.46-4.29.05-7.18,1.63-9.1,2.1-2.57,5.61-2.89,9.67-3.26,1.81-.17,3.68-.34,5.65-.71l.49,2.6c-2.09.4-4.11.58-5.9.75-3.61.33-6.46.59-7.86,2.3-1.08,1.32-1.42,3.66-1.05,7.14,1.39,12.86,8.65,30.61,18.71,30.99,10.06-.38,17.32-18.13,18.71-30.99.38-3.49.04-5.82-1.05-7.14-1.4-1.71-4.25-1.97-7.86-2.3-1.78-.16-3.81-.35-5.9-.75l.49-2.6c1.97.37,3.84.55,5.65.71,4.06.37,7.56.7,9.67,3.26,1.57,1.92,2.09,4.82,1.63,9.1-.6,5.53-2.6,14.01-6.43,21.2-4.06,7.64-9.08,11.82-14.56,12.13v.02h-.01Z"
        />
      </g>
      <g id="uuid-adb47e91-aff1-4d3d-bc70-02fca07aeb22_datoNonDisponibile">
        <path
          className="uuid-7251c0f7-7272-4f0f-9da0-73393b791557"
          d="m21.85,63.79c-.11,0-.22,0-.33,0-.11,0-.23,0-.34,0-.17,0-.34-.02-.5-.05-5.59-.52-10.69-4.76-14.79-12.35-.61-1.12-.19-2.52.93-3.12s2.52-.19,3.12.93c2.41,4.46,6.36,9.76,11.57,9.98,5.23-.22,9.18-5.54,11.59-10.02.6-1.12,2-1.54,3.12-.93,1.12.6,1.54,2,.93,3.12-4.1,7.61-9.2,11.87-14.81,12.39-.16.04-.33.06-.51.05Z"
        />
      </g>
      <g id="uuid-24b6e635-f001-46e8-aede-95a1fb7911fc_datoNonDisponibile">
        <circle className="uuid-75c20353-3e3e-4eeb-8b9e-018f6072cefb" cx="26.82" cy="18.13" r="4.16" />
      </g>
      <g id="uuid-fa092ac6-a701-4510-b0b1-0c484aa7845c_datoNonDisponibile">
        <circle className="uuid-ebf0045a-64fe-4def-a372-4ff1025c0b87" cx="16.41" cy="18.13" r="4.16" />
      </g>
      <path
        className="uuid-d64ed4fb-b1b0-4b93-8914-b28f9031d2fd"
        d="m110.34,32.02c0-4.32-.85-8.51-2.52-12.46-1.61-3.81-3.92-7.24-6.86-10.18-2.94-2.94-6.36-5.25-10.18-6.86-3.95-1.67-8.14-2.52-12.46-2.52s-8.51.85-12.46,2.52c-2.55,1.08-4.93,2.48-7.1,4.16h0c-.08.06-.16.13-.25.2-.36.29-.72.58-1.07.88-.13.12-.27.23-.4.35-.46.41-.91.83-1.35,1.27-.44.44-.86.89-1.27,1.35-.12.13-.23.27-.35.4-.3.35-.6.7-.88,1.07-.07.08-.14.16-.2.25h0c-1.68,2.18-3.08,4.56-4.16,7.11-1.67,3.95-2.52,8.14-2.52,12.46s.85,8.51,2.52,12.46c1.61,3.81,3.92,7.24,6.86,10.18s6.36,5.25,10.18,6.86c3.95,1.67,8.14,2.52,12.46,2.52s8.51-.85,12.46-2.52c2.55-1.08,4.93-2.48,7.1-4.16h0c.08-.06.16-.13.25-.2.36-.29.72-.58,1.07-.88.13-.12.27-.23.4-.35.46-.41.91-.83,1.35-1.27.44-.44.86-.89,1.27-1.35.12-.13.23-.27.35-.4.3-.35.6-.7.88-1.07.07-.08.14-.16.2-.25h0c1.68-2.18,3.08-4.56,4.16-7.11,1.67-3.95,2.52-8.14,2.52-12.46Zm-32.02,23.86c-13.16,0-23.86-10.7-23.86-23.86,0-5.11,1.62-9.84,4.36-13.73l33.22,33.22c-3.89,2.74-8.62,4.36-13.73,4.36Zm-13.73-43.36c3.89-2.74,8.62-4.36,13.73-4.36,13.16,0,23.86,10.7,23.86,23.86,0,5.11-1.62,9.84-4.36,13.73L64.6,12.52Z"
      />
      <g id="uuid-33bf32f7-a78e-4df9-a799-3f99cc78875f_datoNonDisponibile">
        <path
          className="uuid-746c9c4d-4fc6-48e0-8630-500ac92c7d32"
          d="m50.59,94.79c-8.43,0-16.3-3.46-22.16-9.75-5.69-6.1-8.82-14.18-8.83-22.75h4.83c0,7.81,2.82,15.14,7.93,20.62,4.93,5.29,11.4,8.2,18.23,8.2h0c14.42,0,26.15-12.94,26.15-28.84h4.83c0,8.57-3.13,16.65-8.81,22.75-5.86,6.29-13.73,9.76-22.17,9.76h-.01Z"
        />
      </g>
      <g id="uuid-75a9dd50-4443-490a-80e0-a9c6a590e386_datoNonDisponibile">
        <path
          className="uuid-fe14c8f8-3db3-4fcf-8f9c-120cb3f89486"
          d="m21.83,62.81c-.11,0-.22,0-.33,0-.11,0-.23,0-.34,0v-.02c-5.48-.32-10.5-4.49-14.56-12.13C2.77,43.46.76,34.98.16,29.45c-.46-4.29.05-7.18,1.63-9.1,2.1-2.57,5.61-2.89,9.67-3.26,1.81-.17,3.68-.34,5.65-.71l.49,2.6c-2.09.4-4.11.58-5.9.75-3.61.33-6.46.59-7.86,2.3-1.08,1.32-1.42,3.66-1.05,7.14,1.39,12.86,8.65,30.61,18.71,30.99,10.06-.38,17.32-18.13,18.71-30.99.38-3.49.04-5.82-1.05-7.14-1.4-1.71-4.25-1.97-7.86-2.3-1.78-.16-3.81-.35-5.9-.75l.49-2.6c1.97.37,3.84.55,5.65.71,4.06.37,7.56.7,9.67,3.26,1.57,1.92,2.09,4.82,1.63,9.1-.6,5.53-2.6,14.01-6.43,21.2-4.06,7.64-9.08,11.82-14.56,12.13v.02h-.01Z"
        />
      </g>
      <g id="uuid-1cdfdf34-407b-4d9c-b713-ae78a7a7521d_datoNonDisponibile">
        <path
          className="uuid-9926585d-34d2-4331-97df-b978eec0db43"
          d="m21.85,63.79c-.11,0-.22,0-.33,0-.11,0-.23,0-.34,0-.17,0-.34-.02-.5-.05-5.59-.52-10.69-4.76-14.79-12.35-.61-1.12-.19-2.52.93-3.12s2.52-.19,3.12.93c2.41,4.46,6.36,9.76,11.57,9.98,5.23-.22,9.18-5.54,11.59-10.02.6-1.12,2-1.54,3.12-.93,1.12.6,1.54,2,.93,3.12-4.1,7.61-9.2,11.87-14.81,12.39-.16.04-.33.06-.51.05Z"
        />
      </g>
      <g id="uuid-23241852-e4fe-47c1-bcf5-b064455cd83d_datoNonDisponibile">
        <circle className="uuid-44f68dd1-6b34-4720-958f-ea8ac768634d" cx="26.82" cy="18.13" r="4.16" />
      </g>
      <g id="uuid-083ffbee-f3f5-4858-b015-e449bb2e6d0c_datoNonDisponibile">
        <circle className="uuid-bc0c7dae-3b9b-4b63-ac8c-714882ff99f9" cx="16.41" cy="18.13" r="4.16" />
      </g>
      <path
        className="uuid-d64ed4fb-b1b0-4b93-8914-b28f9031d2fd"
        d="m110.34,32.02c0-4.32-.85-8.51-2.52-12.46-1.61-3.81-3.92-7.24-6.86-10.18-2.94-2.94-6.36-5.25-10.18-6.86-3.95-1.67-8.14-2.52-12.46-2.52s-8.51.85-12.46,2.52c-2.55,1.08-4.93,2.48-7.1,4.16h0c-.08.06-.16.13-.25.2-.36.29-.72.58-1.07.88-.13.12-.27.23-.4.35-.46.41-.91.83-1.35,1.27-.44.44-.86.89-1.27,1.35-.12.13-.23.27-.35.4-.3.35-.6.7-.88,1.07-.07.08-.14.16-.2.25h0c-1.68,2.18-3.08,4.56-4.16,7.11-1.67,3.95-2.52,8.14-2.52,12.46s.85,8.51,2.52,12.46c1.61,3.81,3.92,7.24,6.86,10.18s6.36,5.25,10.18,6.86c3.95,1.67,8.14,2.52,12.46,2.52s8.51-.85,12.46-2.52c2.55-1.08,4.93-2.48,7.1-4.16h0c.08-.06.16-.13.25-.2.36-.29.72-.58,1.07-.88.13-.12.27-.23.4-.35.46-.41.91-.83,1.35-1.27.44-.44.86-.89,1.27-1.35.12-.13.23-.27.35-.4.3-.35.6-.7.88-1.07.07-.08.14-.16.2-.25h0c1.68-2.18,3.08-4.56,4.16-7.11,1.67-3.95,2.52-8.14,2.52-12.46Zm-32.02,23.86c-13.16,0-23.86-10.7-23.86-23.86,0-5.11,1.62-9.84,4.36-13.73l33.22,33.22c-3.89,2.74-8.62,4.36-13.73,4.36Zm-13.73-43.36c3.89-2.74,8.62-4.36,13.73-4.36,13.16,0,23.86,10.7,23.86,23.86,0,5.11-1.62,9.84-4.36,13.73L64.6,12.52Z"
      />
    </g>
  </svg>
);
