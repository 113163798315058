import { injectIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { getMessageString } from "../utility";
import { NOT_FOUND_TITLE, NOT_FOUND_DESCRIPTION } from "../modules/language";

export const NotFound = injectIntl(({ intl }) => (
  <Box sx={{ display: "grid", alignItems: "center", height: "100%" }}>
    <Box className="notFoundPage">
      <Typography variant="h1">{getMessageString(intl, NOT_FOUND_TITLE)}</Typography>
      <Typography variant="h2">{getMessageString(intl, NOT_FOUND_DESCRIPTION)}</Typography>
    </Box>
  </Box>
));
