import dayjs from "dayjs";
import { getUserPosition, LATITUDINE, LONGITUDINE } from "../configuration";
import {
  // Api
  SORT_OSPEDALE_API,
  ID_OSPEDALE_API,
  NOME_COMPLETO_OSPEDALE_API,
  NOME_BREVE_OSPEDALE_API,
  AREA_OSPEDALE_API,
  ASL_ENTE_API,
  CLASSIFICAZIONE_OSPEDALE_API,
  DETTAGLIO_CLASSIFICAZIONE_OSPEDALE_API,
  NOTE_OSPEDALE_API,
  LATITUDINE_OSPEDALE_API,
  LONGITUDINE_OSPEDALE_API,
  INDIRIZZO_OSPEDALE_API,
  CIVICO_OSPEDALE_API,
  CAP_OSPEDALE_API,
  COMUNE_OSPEDALE_API,
  PROVINCIA_OSPEDALE_API,
  LOCALITA_API,
  ORARIO_APERTURA_API,
  ORARIO_CHIUSURA_API,
  IS_OSPEDALE_CLOSE_API,
  AFFOLLAMENTO_PS_API,
  GDA_API_FALLBACK,
  GDA_API,
  NUM_ATTESA_BIANCO_API,
  NUM_ATTESA_VERDE_API,
  NUM_ATTESA_AZZURRO_API,
  NUM_ATTESA_ARANCIONE_API,
  NUM_ATTESA_ROSSO_API,
  NUM_TRATTATO_BIANCO_API,
  NUM_TRATTATO_VERDE_API,
  NUM_TRATTATO_AZZURRO_API,
  NUM_TRATTATO_ARANCIONE_API,
  NUM_TRATTATO_ROSSO_API,
  LAST_UPDATE_API,
  // JSON
  SORT_OSPEDALE_JSON,
  ID_OSPEDALE_JSON,
  NOME_COMPLETO_OSPEDALE_JSON,
  NOME_BREVE_OSPEDALE_JSON,
  AREA_OSPEDALE_JSON,
  ASL_ENTE_JSON,
  CLASSIFICAZIONE_OSPEDALE_JSON,
  DETTAGLIO_CLASSIFICAZIONE_OSPEDALE_JSON,
  NOTE_OSPEDALE_JSON,
  DISTANZA_OSPEDALE_JSON,
  INDIRIZZO_OSPEDALE_JSON,
  CIVICO_OSPEDALE_JSON,
  CAP_OSPEDALE_JSON,
  COMUNE_OSPEDALE_JSON,
  PROVINCIA_OSPEDALE_JSON,
  LOCALITA_JSON,
  LATITUDINE_OSPEDALE_JSON,
  LONGITUDINE_OSPEDALE_JSON,
  ORARIO_APERTURA_JSON,
  ORARIO_CHIUSURA_JSON,
  IS_OSPEDALE_CLOSE_JSON,
  AFFOLLAMENTO_PS_JSON,
  GDA_JSON,
  GDA_PROGRESS_JSON,
  NUM_ATTESA_TOT_JSON,
  NUM_TRATTATO_TOT_JSON,
  NUM_ATTESA_BIANCO_JSON,
  NUM_ATTESA_VERDE_JSON,
  NUM_ATTESA_AZZURRO_JSON,
  NUM_ATTESA_ARANCIONE_JSON,
  NUM_ATTESA_ROSSO_JSON,
  NUM_TRATTATO_BIANCO_JSON,
  NUM_TRATTATO_VERDE_JSON,
  NUM_TRATTATO_AZZURRO_JSON,
  NUM_TRATTATO_ARANCIONE_JSON,
  NUM_TRATTATO_ROSSO_JSON,
  LAST_UPDATE_JSON,
  GIORNI,
  ORE,
  MINUTI,
  SECONDI,
} from "./index";

const calcolaDiff = (time) => {
  const days = time / 1000 / 60 / 60 / 24;
  const daysSplitted = days.toString().split(".");
  const giorni = parseInt(daysSplitted[0]);

  const oreTime = time - giorni * 24 * 60 * 60 * 1000;
  const hours = oreTime / 1000 / 60 / 60;
  const hoursSplitted = hours.toString().split(".");
  const ore = parseInt(hoursSplitted[0]);

  const minutiTime = oreTime - ore * 60 * 60 * 1000;
  const minutes = minutiTime / 1000 / 60;
  const minutesSplitted = minutes.toString().split(".");
  const minuti = parseInt(minutesSplitted[0]);

  const secondiTime = minutiTime - minuti * 60 * 1000;
  const seconds = secondiTime / 1000;
  const secondsSplitted = seconds.toString().split(".");
  const secondi = parseInt(secondsSplitted[0]);

  return { [GIORNI]: giorni, [ORE]: ore, [MINUTI]: minuti, [SECONDI]: secondi };
};

const calcolaInAttesaTotali = (ps) =>
  parseInt(ps[AFFOLLAMENTO_PS_API][NUM_ATTESA_BIANCO_API]) +
  parseInt(ps[AFFOLLAMENTO_PS_API][NUM_ATTESA_VERDE_API]) +
  parseInt(ps[AFFOLLAMENTO_PS_API][NUM_ATTESA_AZZURRO_API]) +
  parseInt(ps[AFFOLLAMENTO_PS_API][NUM_ATTESA_ARANCIONE_API]) +
  parseInt(ps[AFFOLLAMENTO_PS_API][NUM_ATTESA_ROSSO_API]);

const calcolaTrattatiTotali = (ps) =>
  parseInt(ps[AFFOLLAMENTO_PS_API][NUM_TRATTATO_BIANCO_API]) +
  parseInt(ps[AFFOLLAMENTO_PS_API][NUM_TRATTATO_VERDE_API]) +
  parseInt(ps[AFFOLLAMENTO_PS_API][NUM_TRATTATO_AZZURRO_API]) +
  parseInt(ps[AFFOLLAMENTO_PS_API][NUM_TRATTATO_ARANCIONE_API]) +
  parseInt(ps[AFFOLLAMENTO_PS_API][NUM_TRATTATO_ROSSO_API]);

// const proporzionaGda = (gda) => gda / 2;
const proporzionaGda = (datiAffollamento) => {
  const gda = datiAffollamento[GDA_API] !== undefined && datiAffollamento[GDA_API] ? datiAffollamento[GDA_API] : datiAffollamento[GDA_API_FALLBACK];
  return parseInt(gda) < 200 ? gda / 2 : 100;
};

const deg2rad = (deg) => deg * (Math.PI / 180);

const calcolaDistanzaPs = (ps) => {
  const { store } = require("../../utility/store/");
  const userPosition = getUserPosition(store.getState());
  let distanza = null;
  if (userPosition && userPosition[LATITUDINE] && userPosition[LONGITUDINE]) {
    const latUtente = userPosition[LATITUDINE];
    const longUtente = userPosition[LONGITUDINE];
    const latPs = ps[LATITUDINE_OSPEDALE_API];
    const longPs = ps[LONGITUDINE_OSPEDALE_API];

    var R = 6371; // Raggio della Terra in Km
    var dLat = deg2rad(latPs - latUtente); // deg2rad below
    var dLon = deg2rad(longPs - longUtente);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(latUtente)) * Math.cos(deg2rad(latPs)) * Math.sin(dLon / 2) * Math.sin(dLon / 2); //prettier-ignore
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    distanza = R * c; // Distanza in km
  }
  return distanza;
};

export const estraiDatiProntoSoccorso = (ps) => {
  let prontoSoccorso = null;
  if (ps) {
    prontoSoccorso = {
      [SORT_OSPEDALE_JSON]: ps[SORT_OSPEDALE_API],
      [ID_OSPEDALE_JSON]: ps[ID_OSPEDALE_API],
      [NOME_COMPLETO_OSPEDALE_JSON]: ps[NOME_COMPLETO_OSPEDALE_API],
      [NOME_BREVE_OSPEDALE_JSON]: ps[NOME_BREVE_OSPEDALE_API],
      [AREA_OSPEDALE_JSON]: ps[AREA_OSPEDALE_API],
      [ASL_ENTE_JSON]: ps[ASL_ENTE_API],
      [CLASSIFICAZIONE_OSPEDALE_JSON]: ps[CLASSIFICAZIONE_OSPEDALE_API],
      ...(ps[DETTAGLIO_CLASSIFICAZIONE_OSPEDALE_API] && { [DETTAGLIO_CLASSIFICAZIONE_OSPEDALE_JSON]: ps[DETTAGLIO_CLASSIFICAZIONE_OSPEDALE_API] }),
      [NOTE_OSPEDALE_JSON]: ps[NOTE_OSPEDALE_API],
      [INDIRIZZO_OSPEDALE_JSON]: ps[INDIRIZZO_OSPEDALE_API],
      [LATITUDINE_OSPEDALE_JSON]: ps[LATITUDINE_OSPEDALE_API],
      [LONGITUDINE_OSPEDALE_JSON]: ps[LONGITUDINE_OSPEDALE_API],
      ...(calcolaDistanzaPs(ps) && { [DISTANZA_OSPEDALE_JSON]: calcolaDistanzaPs(ps) }),
      [CIVICO_OSPEDALE_JSON]: ps[CIVICO_OSPEDALE_API],
      [CAP_OSPEDALE_JSON]: ps[CAP_OSPEDALE_API],
      [COMUNE_OSPEDALE_JSON]: ps[COMUNE_OSPEDALE_API],
      [PROVINCIA_OSPEDALE_JSON]: ps[PROVINCIA_OSPEDALE_API],
      [LOCALITA_JSON]: ps[LOCALITA_API],
      ...(ps[ORARIO_APERTURA_API] && { [ORARIO_APERTURA_JSON]: ps[ORARIO_APERTURA_API] }),
      ...(ps[ORARIO_CHIUSURA_API] && { [ORARIO_CHIUSURA_JSON]: ps[ORARIO_CHIUSURA_API] }),
      [IS_OSPEDALE_CLOSE_JSON]: ps[IS_OSPEDALE_CLOSE_API],
      ...(ps[AFFOLLAMENTO_PS_API]
        ? {
            [AFFOLLAMENTO_PS_JSON]: {
              [GDA_JSON]: (ps[AFFOLLAMENTO_PS_API][GDA_API] !== undefined && ps[AFFOLLAMENTO_PS_API][GDA_API]) ? ps[AFFOLLAMENTO_PS_API][GDA_API] : ps[AFFOLLAMENTO_PS_API][GDA_API_FALLBACK], //prettier-ignore
              [GDA_PROGRESS_JSON]: proporzionaGda(ps[AFFOLLAMENTO_PS_API]),
              [NUM_ATTESA_TOT_JSON]: calcolaInAttesaTotali(ps),
              [NUM_TRATTATO_TOT_JSON]: calcolaTrattatiTotali(ps),
              [NUM_ATTESA_BIANCO_JSON]: ps[AFFOLLAMENTO_PS_API][NUM_ATTESA_BIANCO_API],
              [NUM_ATTESA_VERDE_JSON]: ps[AFFOLLAMENTO_PS_API][NUM_ATTESA_VERDE_API],
              [NUM_ATTESA_AZZURRO_JSON]: ps[AFFOLLAMENTO_PS_API][NUM_ATTESA_AZZURRO_API],
              [NUM_ATTESA_ARANCIONE_JSON]: ps[AFFOLLAMENTO_PS_API][NUM_ATTESA_ARANCIONE_API],
              [NUM_ATTESA_ROSSO_JSON]: ps[AFFOLLAMENTO_PS_API][NUM_ATTESA_ROSSO_API],
              [NUM_TRATTATO_BIANCO_JSON]: ps[AFFOLLAMENTO_PS_API][NUM_TRATTATO_BIANCO_API],
              [NUM_TRATTATO_VERDE_JSON]: ps[AFFOLLAMENTO_PS_API][NUM_TRATTATO_VERDE_API],
              [NUM_TRATTATO_AZZURRO_JSON]: ps[AFFOLLAMENTO_PS_API][NUM_TRATTATO_AZZURRO_API],
              [NUM_TRATTATO_ARANCIONE_JSON]: ps[AFFOLLAMENTO_PS_API][NUM_TRATTATO_ARANCIONE_API],
              [NUM_TRATTATO_ROSSO_JSON]: ps[AFFOLLAMENTO_PS_API][NUM_TRATTATO_ROSSO_API],
              [LAST_UPDATE_JSON]: calcolaDiff(dayjs().diff(dayjs(ps[AFFOLLAMENTO_PS_API][LAST_UPDATE_API]))),
            },
          }
        : { [AFFOLLAMENTO_PS_JSON]: null }),
    };
  }
  return prontoSoccorso;
};

export const estraiDatiElencoProntoSoccorso = (elencoPS) =>
  elencoPS
    .map((ps) => estraiDatiProntoSoccorso(ps))
    .sort((a, b) => {
      const { store } = require("../../utility/store/");
      const userPosition = getUserPosition(store.getState());
      if (userPosition && userPosition[LATITUDINE] && userPosition[LONGITUDINE])
        return a[DISTANZA_OSPEDALE_JSON] > b[DISTANZA_OSPEDALE_JSON] ? 1 : a[DISTANZA_OSPEDALE_JSON] < b[DISTANZA_OSPEDALE_JSON] ? -1 : 0;
      else return a[SORT_OSPEDALE_JSON] > b[SORT_OSPEDALE_JSON] ? 1 : a[SORT_OSPEDALE_JSON] < b[SORT_OSPEDALE_JSON] ? -1 : 0;
    });
