import "./PreferitiVuoto.scss";

export const PreferitiVuoto = () => (
  <svg id="uuid-18be8be0-5c3d-4d9e-8a60-f386753cdc46_preferitiVuoto" viewBox="0 0 37.03 35.9" className="preferitiVuotoIcon">
    <defs>
      <linearGradient
        id="uuid-e56dca71-6bc8-4db6-a6ba-0d650d13cede_preferitiVuoto"
        x1="0"
        y1="17.95"
        x2="37.03"
        y2="17.95"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
      <linearGradient
        id="uuid-6859cde3-9adf-4fad-ab10-6e9773fbf089_preferitiVuoto"
        x1="5.96"
        y1="18.28"
        x2="31.07"
        y2="18.28"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4b78c6" />
        <stop offset="1" stopColor="#2b4281" />
      </linearGradient>
    </defs>
    <g id="uuid-5cd3f540-437a-4161-a6a8-2b6e93e6ba4d_preferitiVuoto">
      <path
        className="uuid-0dc1638a-de95-43e1-9eba-1c754042a657"
        d="m35.18,12.11l-8.86-1.35c-.7-.11-1.3-.54-1.62-1.17L20.46,1.2c-.81-1.6-3.08-1.6-3.89,0l-4.24,8.4c-.32.63-.92,1.07-1.62,1.17l-8.86,1.35c-1.83.28-2.5,2.57-1.11,3.79l6.48,5.66c.58.5.85,1.28.71,2.03l-1.76,9.72c-.33,1.81,1.6,3.19,3.21,2.29l8.08-4.5c.66-.37,1.46-.37,2.12,0l8.08,4.5c1.61.9,3.53-.48,3.21-2.29l-1.76-9.72c-.14-.75.13-1.52.71-2.03l6.48-5.66c1.4-1.22.73-3.52-1.11-3.79Z"
      />
      <path
        className="uuid-23987925-c836-4005-9be2-be5af323a8b4"
        d="m29.81,14.32l-6.01-.91c-.47-.07-.88-.37-1.1-.79l-2.87-5.69c-.55-1.08-2.09-1.08-2.64,0l-2.87,5.69c-.22.43-.62.72-1.1.79l-6.01.91c-1.24.19-1.7,1.75-.75,2.57l4.39,3.84c.39.34.57.86.48,1.38l-1.2,6.59c-.22,1.23,1.08,2.16,2.17,1.55l5.48-3.05c.45-.25.99-.25,1.44,0l5.48,3.05c1.09.61,2.4-.33,2.17-1.55l-1.2-6.59c-.09-.51.09-1.03.48-1.38l4.39-3.84c.95-.83.49-2.38-.75-2.57Z"
      />
    </g>
  </svg>
);
