import { createListenerMiddleware } from "@reduxjs/toolkit";
import { CONFIG_ERROR_DESCRIPTION, CONFIG_ERROR_TITLE } from "../language";
import { fetchConfig, fetchConfigSuccess } from "./index";
import { createRequest, GET } from "../../utility";
import { notificationCodes, setNotification } from "../notification";
import { fetchFaq, fetchCodiciColore, fetchPrivacy } from "../prontoSoccorso";

const getConfigWorker = (listenerApi) => {
  createRequest(GET, "/api/config")
    .then((response) => {
      if (response.status === 200) {
        listenerApi.dispatch(fetchConfigSuccess(response.data));
        const defaultLanguage = response.data.defaultLanguage;
        if (defaultLanguage) {
          listenerApi.dispatch(fetchFaq(defaultLanguage));
          listenerApi.dispatch(fetchCodiciColore(defaultLanguage));
          listenerApi.dispatch(fetchPrivacy(defaultLanguage));
        }
      } else throw response.message;
    })
    .catch((e) => {
      listenerApi.dispatch(setNotification({ code: notificationCodes.ERROR, message: CONFIG_ERROR_TITLE, description: CONFIG_ERROR_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchConfigSuccess(null));
    });
};

export const configurationMiddleware = createListenerMiddleware();

configurationMiddleware.startListening({
  actionCreator: fetchConfig,
  effect: async (action, listenerApi) => getConfigWorker(listenerApi),
});
