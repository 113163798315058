import { injectIntl } from "react-intl";
import { Grid, Typography } from "@mui/material";
import { RicercaOspedale } from "../ricercaOspedale/RicercaOspedale";
import { NAVBAR_DASHBOARD } from "../../modules/language";
import { DashboardIcon } from "../../styles/images";
import { getMessageString } from "../../utility";

export const HeaderDashboard = injectIntl(({ intl }) => (
  <Grid container spacing={2} className="headerDashboard">
    <Grid item xs={12}>
      <RicercaOspedale />
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={2} className="title">
        <Grid item xs={2}>
          <DashboardIcon />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h1">{getMessageString(intl, NAVBAR_DASHBOARD)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
));
