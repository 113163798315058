import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { isFromSaluteSimplex, setFromSaluteSimplex } from "../modules/configuration";
import { PATH_MAPPA } from "../path";
import { ProntoSoccorsoLiveIcon } from "../styles/images";

export const MobileHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fromSaluteSimplex = useSelector(isFromSaluteSimplex);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (fromSaluteSimplex == null) dispatch(setFromSaluteSimplex(searchParams.get("saluteSimplex") != null ? true : false));
    // eslint-disable-next-line
  }, []);

  return (
    fromSaluteSimplex == null ||
    (fromSaluteSimplex != null && !fromSaluteSimplex && (
      <Box className="mobileHeader">
        <Button onClick={() => navigate(PATH_MAPPA)} aria-label="Ritorna alla home">
          <ProntoSoccorsoLiveIcon />
        </Button>
      </Box>
    ))
  );
};
