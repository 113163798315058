import { Box, Typography, Button } from "@mui/material";
import { ProntoSoccorsoLiveIcon } from "../styles/images";

export const ConfigFailed = ({ onRetry }) => (
  <Box className="configFailedPage">
    <Typography variant="h1" sx={{ height: 0 }}>
      <div style={{ display: "none" }}>Config failed</div>
    </Typography>
    <ProntoSoccorsoLiveIcon />
    <Box className="bloccoArancione">
      <Typography variant="h2">Caricamento configurazione fallito</Typography>
      <Button onClick={onRetry}>Riprova</Button>
    </Box>
  </Box>
);
