import { injectIntl } from "react-intl";
import { Box, LinearProgress, Typography } from "@mui/material";
import { getMessageString } from "../utility";
import { AFFOLLATO_TITLE, NON_DISPONIBILE_TITLE, POCO_AFFOLLATO_TITLE, SOVRAFFOLLATO_TITLE, NORMO_AFFOLLATO_TITLE } from "../modules/language";
import { AFFOLLAMENTO_PS_JSON, GDA_JSON, GDA_PROGRESS_JSON } from "../modules/prontoSoccorso";
import { NormoAffollatoIcon, PocoAffollatoIcon, AffollatoIcon, SovraffollatoIcon } from "../styles/images";

export const ProgressBar = injectIntl(({ intl, ospedale }) => {
  const infoAffollamento = ospedale[AFFOLLAMENTO_PS_JSON];
  const renderizzaIcona = () => {
    const value = infoAffollamento[GDA_JSON];
    const val = parseInt(value, 0);

    return (
      <Box sx={{ left: "calc(" + infoAffollamento[GDA_PROGRESS_JSON] + "% - 12.5px)" }} className={"indicazioneAffollamento"}>
        {0 <= val && val < 60 && <NormoAffollatoIcon />}
        {60 <= val && val < 90 && <PocoAffollatoIcon />}
        {90 <= val && val < 150 && <AffollatoIcon />}
        {val >= 150 && <SovraffollatoIcon />}
      </Box>
    );
  };

  const determinaGradiente = () => {
    if (infoAffollamento) {
      const value = infoAffollamento[GDA_PROGRESS_JSON];
      const val = parseInt(value, 0);
      // Verde
      if (0 <= val && val <= 8) return "linear-gradient(to right, #62BE6B, #62BE6B)";
        else if (8 < val && val <= 15) return "linear-gradient(to right, #62BE6B, #4DD163)";
        else if (15 < val && val <= 30) return "linear-gradient(to right, #62BE6B, #4DD163 85%, #ACD350 100%)";
        // Giallo
        else if (30 < val && val <= 37) return "linear-gradient(to right, #62BE6B, #4DD163 70%, #ACD350 90%, #F6E94D 100%)";
        else if (37 < val && val <= 45) return "linear-gradient(to right, #62BE6B, #4DD163 70%, #ACD350 82%, #F6E94D 95%, #F3B22E 100%)";
        // Arancio
        else if (45 < val && val <= 52) return "linear-gradient(to right, #62BE6B, #4DD163 50%, #ACD350 65%, #F6E94D 80%, #F3B22E 92%, #E8912E 100%)";
        else if (52 < val && val <= 60) return "linear-gradient(to right, #62BE6B, #4DD163 50%, #ACD350 62%, #F6E94D 80%, #F3B22E 87%, #E8912E 95%, #E75C2A 100%)";
        // Rosso
        else if (60 < val && val <= 68) return "linear-gradient(to right, #62BE6B, #4DD163 30%, #ACD350 55%, #F6E94D 65%, #F3B22E 75%, #E8912E 80%, #E75C2A 92%, #FE540C 100%)"; //prettier-ignore
        else if (68 < val && val <= 75) return "linear-gradient(to right, #62BE6B, #4DD163 30%, #ACD350 50%, #F6E94D 60%, #F3B22E 75%, #E8912E 80%, #E75C2A 87%, #FE540C 97%, #AF330D 100%)"; //prettier-ignore
        // Rosso scuro
        else if (75 < val && val <= 87) return "linear-gradient(to right, #62BE6B, #4DD163 30%, #ACD350 40%, #F6E94D 50%, #F3B22E 60%, #E8912E 65%, #E75C2A 75%, #FE540C 85%, #AF330D 92%, #3B0100 100%)"; //prettier-ignore
        else if (val > 87) return "linear-gradient(to right, #62BE6B, #4DD163 20%, #ACD350 30%, #F6E94D 40%, #F3B22E 57%, #E8912E 62%, #E75C2A 70%, #FE540C 77%, #AF330D 87%, #3B0100 95%)"; //prettier-ignore
    } else return "linear-gradient(to right, lightgray, gray)";
  };

  return (
    <Box sx={{ position: "relative" }} className="progressBar">
      {infoAffollamento && <Box className="iconaGda">{renderizzaIcona()}</Box>}
      <LinearProgress
        sx={{
          height: "25px",
          borderRadius: "20px",
          backgroundColor: "white",
          boxShadow: "2px 2px 10px #000000d9",
          "& .MuiLinearProgress-bar": { backgroundImage: determinaGradiente(), backgroundColor: "transparent" },
        }}
        variant="determinate"
        value={infoAffollamento ? infoAffollamento[GDA_PROGRESS_JSON] : 100}
      />
      {infoAffollamento ? (
        <>
          <Typography variant="caption" className="vuoto">
            {getMessageString(intl, NORMO_AFFOLLATO_TITLE)}
          </Typography>
          <Typography variant="caption" className="pocoAffollato">
            {getMessageString(intl, POCO_AFFOLLATO_TITLE)}
          </Typography>
          <Typography variant="caption" className="affollato">
            {getMessageString(intl, AFFOLLATO_TITLE)}
          </Typography>
          <Typography variant="caption" className="sovraffollato">
            {getMessageString(intl, SOVRAFFOLLATO_TITLE)}
          </Typography>
        </>
      ) : (
        <Typography variant="caption" className="nonDisponibile">
          {getMessageString(intl, NON_DISPONIBILE_TITLE)}
        </Typography>
      )}
    </Box>
  );
});
