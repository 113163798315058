import {
  // Label comuni
  NO_DATA,
  CERCA,
  FILTRI,
  GRADO_AFFOLLAMENTO,
  PROVINCIA,
  AZZERA,
  OK,
  PREFERITI_LABEL,
  NO_LABEL,
  YES_LABEL,
  LOADING,
  // Label navbar
  NAVBAR_MAPPA,
  NAVBAR_ELENCO,
  NAVBAR_DASHBOARD,
  NAVBAR_INFO,
  // Label per le info
  CODICI_COLORE_MENU,
  GRADO_AFFOLLAMENTO_MENU,
  PRIVACY_POLICY_MENU,
  // Label per l'ospedale chiuso
  OSPEDALE_CHIUSO_INFO,
  OSPEDALE_CHIUSO_INFO_2,
  OSPEDALE_CHIUSO_DETAIL,
  OSPEDALE_CHIUSO_DETAIL_2,
  // Label per i codici priorità
  CODICI_COLORE_SOTTOTITOLO,
  // Label per la mappa
  GRADO_AFFOLLAMENTO_LEGENDA,
  // Label per il grado affollamento
  GRADO_AFFOLLAMENTO_SOTTOTITOLO,
  NORMO_AFFOLLATO_TITLE,
  NORMO_AFFOLLATO_DESCRIZIONE,
  POCO_AFFOLLATO_TITLE,
  POCO_AFFOLLATO_DESCRIZIONE,
  AFFOLLATO_TITLE,
  AFFOLLATO_DESCRIZIONE,
  SOVRAFFOLLATO_TITLE,
  SOVRAFFOLLATO_DESCRIZIONE,
  NON_DISPONIBILE_TITLE,
  NON_DISPONIBILE_DESCRIZIONE,
  PPI_TITLE,
  PPI_DESCRIZIONE,
  APERTO_TITLE,
  CHIUSO_TITLE,
  // Label per il dettaglio
  AREA,
  ASL,
  CLASSIFICAZIONE,
  SPECIALIZZAZIONE,
  ORARIO,
  ORARIO_DESCRIZIONE,
  INDIRIZZO,
  NAVIGA,
  AFFLUENZA,
  AFFLUENZA_ATTESA,
  AFFLUENZA_IN_CURA,
  PAZIENTI_ATTESA,
  PAZIENTI_IN_CURA,
  LAST_UPDATE,
  LAST_UPDATE_GIORNO,
  LAST_UPDATE_GIORNI,
  LAST_UPDATE_ORA,
  LAST_UPDATE_ORE,
  LAST_UPDATE_MINUTO,
  LAST_UPDATE_MINUTI,
  LAST_UPDATE_SECONDI,
  LAST_UPDATE_AGO,
  GOOGLE_MAPS_MODAL,
  // Label per le faq
  FAQ_SEARCH,
  // Label per la pagina not found
  NOT_FOUND_TITLE,
  NOT_FOUND_DESCRIPTION,
  RESOURCE_NOT_FOUND_DESCRIPTION,
  // Label per i sagas
  CONFIG_ERROR_TITLE,
  CONFIG_ERROR_DESCRIPTION,
  ELENCO_OSPEDALI_ERROR_TITLE,
  ELENCO_OSPEDALI_ERROR_DESCRIPTION,
  DETTAGLIO_OSPEDALE_ERROR_TITLE,
  DETTAGLIO_OSPEDALE_ERROR_DESCRIPTION,
  FAQ_ERROR_TITLE,
  FAQ_ERROR_DESCRIPTION,
  CODICI_COLORE_ERROR_TITLE,
  CODICI_COLORE_ERROR_DESCRIPTION,
  PRIVACY_ERROR_TITLE,
  PRIVACY_ERROR_DESCRIPTION,
} from "../modules/language";

const common = {
  [NO_DATA]: "Nessun risultato",
  [CERCA]: "Cerca",
  [FILTRI]: "Filtri",
  [GRADO_AFFOLLAMENTO]: "Grado di affollamento stimato",
  [PROVINCIA]: "Provincia",
  [AZZERA]: "Azzera",
  [OK]: "Ok",
  [PREFERITI_LABEL]: "Preferiti",
  [NO_LABEL]: "No",
  [YES_LABEL]: "Sì",
  [LOADING]: "Dati in caricamento...",
};

const navbar = {
  [NAVBAR_MAPPA]: "Mappa",
  [NAVBAR_ELENCO]: "Elenco",
  [NAVBAR_DASHBOARD]: "Dashboard",
  [NAVBAR_INFO]: "Info",
};

const info = {
  [CODICI_COLORE_MENU]: "Codici colore di priorità Pronto Soccorso",
  [GRADO_AFFOLLAMENTO_MENU]: "Legenda grado di affollamento",
  [PRIVACY_POLICY_MENU]: "Privacy Policy",
};

const ospedaleChiuso = {
  [OSPEDALE_CHIUSO_INFO]: "L'ospedale ",
  [OSPEDALE_CHIUSO_INFO_2]: " è al momento chiuso",
  [OSPEDALE_CHIUSO_DETAIL]: "Riaprirà alle ore ",
  [OSPEDALE_CHIUSO_DETAIL_2]: " e seguirà il seguente orario: ",
};

const codiciColore = {
  [CODICI_COLORE_SOTTOTITOLO]: "Informazioni generali per il corretto utilizzo del sistema di Emergenza-Urgenza.",
};

const mappa = {
  [GRADO_AFFOLLAMENTO_LEGENDA]: "Legenda Mappa",
};

const gradoAffollamento = {
  [GRADO_AFFOLLAMENTO_SOTTOTITOLO]: "Si tratta di una stima della coda in funzione del numero di pazienti in attesa rispetto alle salette mediche attive e dedicate a visitarli", //prettier-ignore
  [NORMO_AFFOLLATO_TITLE]: "Normale",
  [NORMO_AFFOLLATO_DESCRIZIONE]: "Attesa stimata: entro i 60 minuti",
  [POCO_AFFOLLATO_TITLE]: "Lievemente affollato",
  [POCO_AFFOLLATO_DESCRIZIONE]: "Attesa stimata: entro i 90 minuti",
  [AFFOLLATO_TITLE]: "Affollato",
  [AFFOLLATO_DESCRIZIONE]: "Attesa stimata: entro i 150 minuti",
  [SOVRAFFOLLATO_TITLE]: "Molto affollato",
  [SOVRAFFOLLATO_DESCRIZIONE]: "Attesa stimata: oltre i 150 minuti",
  [NON_DISPONIBILE_TITLE]: "Dati non disponibili",
  [NON_DISPONIBILE_DESCRIZIONE]: "Si intende che la struttura non sta comunicando i dati da alcune ore",
  [PPI_TITLE]: "Punto di Primo Intervento o Ambulatorio a Bassa Complessità",
  [PPI_DESCRIZIONE]: "Si intende che la struttura è dedicata a patologie e prestazioni di bassa complessità",
  [APERTO_TITLE]: "Aperto",
  [CHIUSO_TITLE]: "Chiuso",
};

const dettaglio = {
  [AREA]: "Area: ",
  [ASL]: "ASL/Ente: ",
  [CLASSIFICAZIONE]: "Classificazione: ",
  [SPECIALIZZAZIONE]: "Specializzazione: ",
  [ORARIO]: "Orario: ",
  [ORARIO_DESCRIZIONE]: " con il seguente orario: ",
  [INDIRIZZO]: "Indirizzo",
  [NAVIGA]: "Vai al percorso",
  [AFFLUENZA]: "Attualmente in pronto soccorso:",
  [AFFLUENZA_ATTESA]: "Persona in attesa",
  [AFFLUENZA_IN_CURA]: "Persone in cura",
  [PAZIENTI_ATTESA]: "Pazienti in attesa: ",
  [PAZIENTI_IN_CURA]: "Pazienti in cura: ",
  [LAST_UPDATE]: "Ultima rilevazione: ",
  [LAST_UPDATE_GIORNO]: " giorno",
  [LAST_UPDATE_GIORNI]: " giorni",
  [LAST_UPDATE_ORA]: " ora",
  [LAST_UPDATE_ORE]: " ore",
  [LAST_UPDATE_MINUTO]: " minuto",
  [LAST_UPDATE_MINUTI]: " minuti",
  [LAST_UPDATE_SECONDI]: " secondi",
  [LAST_UPDATE_AGO]: " fa",
  [GOOGLE_MAPS_MODAL]: "L'applicazione desidera aprire Google Maps, continuare?",
};

const faq = {
  [FAQ_SEARCH]: "Inserisci parola chiave",
};

const notFound = {
  [NOT_FOUND_TITLE]: "404",
  [NOT_FOUND_DESCRIPTION]: "La pagina richiesta non esiste",
  [RESOURCE_NOT_FOUND_DESCRIPTION]: "La risorsa richiesta non esiste",
};

const sagas = {
  [CONFIG_ERROR_TITLE]: "Errore durante il recupero delle configurazioni applicative",
  [CONFIG_ERROR_DESCRIPTION]: "Si è verificato un errore durante il recupero delle configurazioni applicative",
  [ELENCO_OSPEDALI_ERROR_TITLE]: "Errore durante il recupero dell'elenco dei pronto soccorso",
  [ELENCO_OSPEDALI_ERROR_DESCRIPTION]: "Si è verificato un errore durante il recupero dell'elenco dei pronto soccorso",
  [DETTAGLIO_OSPEDALE_ERROR_TITLE]: "Errore durante il recupero del dettaglio del pronto soccorso",
  [DETTAGLIO_OSPEDALE_ERROR_DESCRIPTION]: "Si è verificato un errore durante il recupero del dettaglio del pronto soccorso",
  [FAQ_ERROR_TITLE]: "Errore durante il recupero delle FAQ",
  [FAQ_ERROR_DESCRIPTION]: "Si è verificato un errore durante il recupero deille FAQ",
  [CODICI_COLORE_ERROR_TITLE]: "Errore durante il recupero dei codici colore",
  [CODICI_COLORE_ERROR_DESCRIPTION]: "Si è verificato un errore durante il recupero dei codici colore",
  [PRIVACY_ERROR_TITLE]: "Errore durante il recupero delle privacy policy",
  [PRIVACY_ERROR_DESCRIPTION]: "Si è verificato un errore durante il recupero delle privacy policy",
};

const it = {
  ...common,
  ...navbar,
  ...info,
  ...ospedaleChiuso,
  ...codiciColore,
  ...mappa,
  ...gradoAffollamento,
  ...dettaglio,
  ...faq,
  ...notFound,
  ...sagas,
};

export { it };
