import { injectIntl } from "react-intl";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { NOME_BREVE_OSPEDALE_JSON, ORARIO_APERTURA_JSON, ORARIO_CHIUSURA_JSON } from "../../modules/prontoSoccorso";
import { getMessageString } from "../../utility";
import { OSPEDALE_CHIUSO_DETAIL, OSPEDALE_CHIUSO_DETAIL_2, OSPEDALE_CHIUSO_INFO, OSPEDALE_CHIUSO_INFO_2 } from "../../modules/language";

export const OspedaleClosedModal = injectIntl(
  ({ intl, data, modalOpen, handleClose }) =>
    data && (
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        className="ospedaleClosedModal"
        aria-labelledby={"Ospedale " + data[NOME_BREVE_OSPEDALE_JSON] + " chiuso"}
      >
        <Box id={"Ospedale " + data[NOME_BREVE_OSPEDALE_JSON] + " chiuso"}>
          <DialogContent>
            <DialogContentText>
              {getMessageString(intl, OSPEDALE_CHIUSO_INFO)}
              {data[NOME_BREVE_OSPEDALE_JSON]}
              {getMessageString(intl, OSPEDALE_CHIUSO_INFO_2)}
            </DialogContentText>
            {data[ORARIO_APERTURA_JSON] !== undefined && data[ORARIO_CHIUSURA_JSON] !== undefined && (
              <DialogContentText>
                {getMessageString(intl, OSPEDALE_CHIUSO_DETAIL)}
                {data[ORARIO_APERTURA_JSON]}
                {getMessageString(intl, OSPEDALE_CHIUSO_DETAIL_2)}
                {data[ORARIO_APERTURA_JSON]} - {data[ORARIO_CHIUSURA_JSON]}
              </DialogContentText>
            )}
            <DialogActions>
              <Button onClick={handleClose}>OK</Button>
            </DialogActions>
          </DialogContent>
        </Box>
      </Dialog>
    )
);
