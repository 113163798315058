import { useState } from "react";
import { useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Box, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { NavigateNext as NavigateNextIcon } from "@mui/icons-material";
import { CODICI_COLORE_MENU, GRADO_AFFOLLAMENTO_MENU, PRIVACY_POLICY_MENU } from "../../modules/language";
import { getFaq } from "../../modules/prontoSoccorso";
import { PATH_INFO_CODICI_COLORE, PATH_INFO_GRADO_AFFOLLAMENTO, PATH_INFO_PRIVACY_POLICY } from "../../path";
import { getMessageString } from "../../utility";
import { Accordion, AccordionSummary, AccordionDetails } from "../Accordion";

const NextIcon = () => <NavigateNextIcon sx={{ color: "#2b4281", fontSize: "2em" }} />;

export const InfoHome = injectIntl(({ intl }) => {
  const navigate = useNavigate();
  const faqs = useSelector(getFaq);
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => setAccordionExpanded(newExpanded ? panel : false);

  return (
    <Box className="info">
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ paddingLeft: "0 !important" }}>
          {faqs.map((faq, index) => (
            <Accordion expanded={accordionExpanded === index} onChange={handleChange(index)} key={index}>
              <AccordionSummary>
                <Typography>{faq.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.description}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
        <List sx={{ display: "grid", paddingTop: 0 }}>
          <ListItemButton
            onClick={() => {
              setAccordionExpanded(false);
              navigate(PATH_INFO_CODICI_COLORE);
            }}
          >
            <ListItemIcon>
              <NextIcon />
            </ListItemIcon>
            <ListItemText primary={getMessageString(intl, CODICI_COLORE_MENU)} />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              setAccordionExpanded(false);
              navigate(PATH_INFO_GRADO_AFFOLLAMENTO);
            }}
          >
            <ListItemIcon>
              <NextIcon />
            </ListItemIcon>
            <ListItemText primary={getMessageString(intl, GRADO_AFFOLLAMENTO_MENU)} />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              setAccordionExpanded(false);
              navigate(PATH_INFO_PRIVACY_POLICY);
            }}
          >
            <ListItemIcon>
              <NextIcon />
            </ListItemIcon>
            <ListItemText primary={getMessageString(intl, PRIVACY_POLICY_MENU)} />
          </ListItemButton>
        </List>
      </Grid>
    </Box>
  );
});
