import { injectIntl } from "react-intl";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH_DASHBOARD, PATH_ELENCO, PATH_INFO, PATH_MAPPA } from "../path";
import { ProntoSoccorsoLiveIcon, MappaIcon, ElencoIcon, DashboardIcon, InfoIcon } from "../styles/images";
import { NAVBAR_MAPPA, NAVBAR_ELENCO, NAVBAR_DASHBOARD, NAVBAR_INFO } from "../modules/language";
import { getMessageString } from "../utility";
import { InfoHome } from "./info/InfoHome";

export const Navbar = injectIntl(({ intl }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} className="header">
          <Button onClick={() => navigate(PATH_MAPPA)} className="logoApp" aria-label="Ritorna alla home">
            <ProntoSoccorsoLiveIcon />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={() => navigate(PATH_MAPPA)} startIcon={<MappaIcon />}>
            <Typography variant="caption" {...(path === PATH_MAPPA && { className: "selectedPath" })}>
              {getMessageString(intl, NAVBAR_MAPPA)}
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={() => navigate(PATH_ELENCO)} startIcon={<ElencoIcon />}>
            <Typography variant="caption" {...(path === PATH_ELENCO && { className: "selectedPath" })}>
              {getMessageString(intl, NAVBAR_ELENCO)}
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={() => navigate(PATH_DASHBOARD)} startIcon={<DashboardIcon />}>
            <Typography variant="caption" {...(path === PATH_DASHBOARD && { className: "selectedPath" })}>
              {getMessageString(intl, NAVBAR_DASHBOARD)}
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => {
              const width = window.innerWidth;
              if (width <= 768) navigate(PATH_INFO);
            }}
            startIcon={<InfoIcon />}
          >
            <Typography variant="caption" {...(path === PATH_INFO && { className: "selectedPath" })}>
              {getMessageString(intl, NAVBAR_INFO)}
            </Typography>
          </Button>
          <InfoHome />
        </Grid>
      </Grid>
    </Box>
  );
});
