import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Button, Card, CardContent, CardHeader, IconButton, Popover, Grid, Typography, CardActions, Divider } from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { CheckboxAffollamento } from "./CheckboxAffollamento";
import { CheckboxProvince } from "./CheckboxProvince";
import { getMessageString } from "../../utility";
import { AZZERA, OK } from "../../modules/language";
import { resetFilterValues } from "../../modules/prontoSoccorso";

export const PopoverFiltri = injectIntl(({ intl, anchorEl, onPopoverClose }) => {
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);

  const azzera = () => dispatch(resetFilterValues());

  return (
    <Popover
      id="filtriPopover"
      open={open}
      anchorEl={anchorEl}
      onClose={onPopoverClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Card>
        <CardHeader
          action={
            <IconButton onClick={onPopoverClose} aria-label="Chiudi">
              <CancelIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CheckboxAffollamento />
            </Grid>
            <Divider />
            <Grid item xs={12}>
              <CheckboxProvince />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button onClick={azzera}>
                <Typography variant="h2">{getMessageString(intl, AZZERA)}</Typography>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button onClick={onPopoverClose}>
                <Typography variant="h2">{getMessageString(intl, OK)}</Typography>
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Popover>
  );
});
