import { Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails } from "@mui/material";
import { NavigateNext as NavigateNextIcon } from "@mui/icons-material";

const Accordion = (props) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    {...props}
    sx={{
      paddingLeft: "16px",
      backgroundColor: "transparent",
      "&:before": {
        backgroundColor: "transparent",
      },
      ...(props.expanded && { outline: "1px solid #2b4281" }),
    }}
  />
);

const AccordionSummary = (props) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={<NavigateNextIcon sx={{ fontSize: "2em", color: "#2b4281" }} />}
    sx={{
      paddingLeft: "0",
      flexDirection: "row-reverse",
      "& .MuiAccordionSummary-expandIconWrapper": {
        transform: "rotate(90deg)",
      },
      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(270deg)",
      },
      "& .MuiAccordionSummary-content": {
        marginLeft: "4px",
      },
      "& .MuiAccordionSummary-content .MuiTypography-root": {
        fontSize: "18px",
      },
    }}
  />
);

const AccordionDetails = (props) => (
  <MuiAccordionDetails
    {...props}
    sx={{
      padding: "10px",
      marginLeft: "30px",
    }}
  />
);

export { Accordion, AccordionSummary, AccordionDetails };
