import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { configurationReducer } from "../../modules/configuration";
import { languageReducer } from "../../modules/language";
import { notificationReducer } from "../../modules/notification";
import { prontoSoccorsoReducer } from "../../modules/prontoSoccorso";

// Importare eventuali altri reducers ed aggiungerli qua sotto

const applicationReducers = combineReducers({
  configuration: configurationReducer,
  language: languageReducer,
  notification: notificationReducer,
  prontoSoccorso: prontoSoccorsoReducer,
});

export const createReducers = (whitelist) => {
  const persistConfig = {
    key: "root",
    storage,
    whitelist: whitelist,
  };
  return persistReducer(persistConfig, applicationReducers);
};
