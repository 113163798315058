import "./Dashboard.scss";

export const Dashboard = () => (
  <svg id="uuid-94143c83-cee9-4b10-ab86-ea391ffd5456_dashboard" viewBox="0 0 46.52 42.48" className="dashboardIcon">
    <defs>
      <linearGradient
        id="uuid-bbb43bd4-1996-474e-8219-926febec997b_dashboard"
        x1="-9.24"
        y1="11.32"
        x2="49.62"
        y2="55.77"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
      <linearGradient
        id="uuid-40a8ff77-6109-4d3a-bb73-8529c12b3103_dashboard"
        x1="10"
        y1="18.23"
        x2="57.92"
        y2="54.42"
        xlinkHref="#uuid-bbb43bd4-1996-474e-8219-926febec997b_dashboard"
      />
      <linearGradient
        id="uuid-f9304091-a125-4cd3-adf9-0c88ccc98b3a_dashboard"
        x1="30.6"
        y1="28.27"
        x2="62.65"
        y2="52.47"
        xlinkHref="#uuid-bbb43bd4-1996-474e-8219-926febec997b_dashboard"
      />
      <linearGradient
        id="uuid-7a2d35e5-fd65-4a20-84dc-548ef9293804_dashboard"
        x1="22.28"
        y1="10.98"
        x2="44.24"
        y2="10.98"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4b78c6" />
        <stop offset="1" stopColor="#2b4281" />
      </linearGradient>
    </defs>
    <g id="uuid-0d6cda13-d8bb-4f7d-b6ab-3b031bdfba14_dashboard">
      <g>
        <rect className="uuid-7b1df494-31a4-4e76-99df-cc1415dcd738" y="4.76" width="14.1" height="37.72" rx="4.96" ry="4.96" />
        <rect className="uuid-3e7caf9d-0cba-46a8-af33-ff1c8b2bdfc8" x="16.21" y="14.02" width="14.1" height="28.46" rx="4.96" ry="4.96" />
        <rect className="uuid-0ded5346-6f70-44ff-822d-1f04d07af16d" x="32.42" y="27.45" width="14.1" height="15.03" rx="4.96" ry="4.96" />
      </g>
      <circle className="uuid-181226f1-2a74-435b-93c3-16cd9ae5304c" cx="33.26" cy="10.98" r="10.98" />
    </g>
  </svg>
);
