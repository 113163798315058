import { useDispatch } from "react-redux";
import { Box, Chip } from "@mui/material";
import { changeFilterValues } from "../../modules/prontoSoccorso";

export const TagFiltri = (props) => {
  const dispatch = useDispatch();

  const handleDelete = (filterKey) => dispatch(changeFilterValues({ field: [filterKey], value: false }));

  const tags = [];
  Object.entries(props).forEach(([key, value]) => {
    if (value.checked) tags.push(<Chip label={value.label} onDelete={() => handleDelete(key)} />);
  });

  return (
    <Box className="tagFiltri">
      {tags.map((tag, index) => (
        <Box key={index}>{tag}</Box>
      ))}
    </Box>
  );
};
