import { useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { Box, Grid, Typography } from "@mui/material";
import { LoadingInfo } from "../loading/LoadingInfo";
import { getPrivacyPolicy, fetchPrivacyPolicyEnded } from "../../modules/prontoSoccorso";
import { PRIVACY_POLICY_MENU } from "../../modules/language";
import { PrivacyIcon } from "../../styles/images";
import { getMessageString } from "../../utility";

export const PrivacyPolicy = injectIntl(({ intl }) => {
  const privacyPolicy = useSelector(getPrivacyPolicy);
  const privacyEnded = useSelector(fetchPrivacyPolicyEnded);

  return (
    <Box className="privacyPolicy">
      <Grid container spacing={2}>
        <Grid item xs={12} className="privacyPolicyTitle">
          <PrivacyIcon />
          <Typography variant="h1">{getMessageString(intl, PRIVACY_POLICY_MENU)}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "2em" }}>
          {privacyEnded ? privacyPolicy && <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} /> : <LoadingInfo />}
        </Grid>
      </Grid>
    </Box>
  );
});
