import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Grid, Typography, FormGroup, FormControlLabel } from "@mui/material";
import { PROVINCIA } from "../../modules/language";
import { getMessageString } from "../../utility";
import { changeFilterValues, getFilterValues, GENOVA, IMPERIA, LA_SPEZIA, SAVONA } from "../../modules/prontoSoccorso";

export const CheckboxProvince = injectIntl(({ intl }) => {
  const dispatch = useDispatch();
  const filterValues = useSelector(getFilterValues);
  const imperiaChecked = filterValues[IMPERIA];
  const savonaChecked = filterValues[SAVONA];
  const genovaChecked = filterValues[GENOVA];
  const speziaChecked = filterValues[LA_SPEZIA];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1">{getMessageString(intl, PROVINCIA)}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox onChange={() => dispatch(changeFilterValues({ field: [GENOVA], value: !genovaChecked }))} checked={genovaChecked} />}
            label={<Typography variant="caption">{GENOVA}</Typography>}
          />
          <FormControlLabel
            control={<Checkbox onChange={() => dispatch(changeFilterValues({ field: [SAVONA], value: !savonaChecked }))} checked={savonaChecked} />}
            label={<Typography variant="caption">{SAVONA}</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox onChange={() => dispatch(changeFilterValues({ field: [IMPERIA], value: !imperiaChecked }))} checked={imperiaChecked} />
            }
            label={<Typography variant="caption">{IMPERIA}</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox onChange={() => dispatch(changeFilterValues({ field: [LA_SPEZIA], value: !speziaChecked }))} checked={speziaChecked} />
            }
            label={<Typography variant="caption">{LA_SPEZIA}</Typography>}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
});
