import { createAction } from "@reduxjs/toolkit";

export const fetchElencoProntoSoccorso = createAction("FETCH_ELENCO_PRONTO_SOCCORSO@prontoSoccorso");

export const fetchElencoProntoSoccorsoSuccess = createAction("FETCH_ELENCO_PRONTO_SOCCORSO_SUCCESS@prontoSoccorso");

export const fetchDettaglioProntoSoccorso = createAction("FETCH_DETTAGLIO_PRONTO_SOCCORSO@prontoSoccorso");

export const fetchDettaglioProntoSoccorsoSuccess = createAction("FETCH_DETTAGLIO_PRONTO_SOCCORSO_SUCCESS@prontoSoccorso");

export const resetDettaglioProntoSoccorso = createAction("RESET_DETTAGLIO_PRONTO_SOCCORSO_SUCCESS@prontoSoccorso");

export const changeFilterValues = createAction("CHANGE_FILTER_VALUES@prontoSoccorso");

export const resetFilterValues = createAction("RESET_FILTER_VALUES@prontoSoccorso");

export const fetchFaq = createAction("FETCH_FAQ@prontoSoccorso");

export const fetchFaqSuccess = createAction("FETCH_FAQ_SUCCESS@prontoSoccorso");

export const fetchCodiciColore = createAction("FETCH_CODICI_COLORE@prontoSoccorso");

export const fetchCodiciColoreSuccess = createAction("FETCH_CODICI_COLORE_SUCCESS@prontoSoccorso");

export const fetchPrivacy = createAction("FETCH_PRIVACY@prontoSoccorso");

export const fetchPrivacySuccess = createAction("FETCH_PRIVACY_SUCCESS@prontoSoccorso");
