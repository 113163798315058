import { Box, Button, Typography } from "@mui/material";
import { injectIntl } from "react-intl";
import { INDIRIZZO, NAVIGA } from "../../modules/language";
import {
  CAP_OSPEDALE_JSON,
  CIVICO_OSPEDALE_JSON,
  COMUNE_OSPEDALE_JSON,
  INDIRIZZO_OSPEDALE_JSON,
  PROVINCIA_OSPEDALE_JSON,
} from "../../modules/prontoSoccorso";
import { getMessageString } from "../../utility";

export const PosizioneProntoSoccorso = injectIntl(({ intl, data, onNaviga }) => (
  <Box className="posizioneDettaglio">
    <Typography variant="h3">{getMessageString(intl, INDIRIZZO)}</Typography>
    <Box className="indirizzo">
      <Typography variant="caption">{data[INDIRIZZO_OSPEDALE_JSON] + " " + data[CIVICO_OSPEDALE_JSON]}</Typography>
      <Typography variant="caption">{data[CAP_OSPEDALE_JSON] + " " + data[COMUNE_OSPEDALE_JSON] + " " + data[PROVINCIA_OSPEDALE_JSON]}</Typography>
    </Box>
    <Button variant="contained" onClick={onNaviga}>
      {getMessageString(intl, NAVIGA)}
    </Button>
  </Box>
));
