import axios from "axios";

const customHeader = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const GET = "GET";
const POST = "POST";

const buildApiRequestParams = (method, baseUrl, apiUrl, queryParams, payload, headers) => {
  let pathUrl = baseUrl;
  if (apiUrl && apiUrl !== undefined && apiUrl.length > 0) pathUrl += apiUrl;

  let apiHeaders = customHeader;
  if (headers && headers !== undefined) apiHeaders = { ...apiHeaders, ...headers };

  let queryParamsString = null;
  if (queryParams && queryParams !== undefined) {
    queryParamsString = "?";
    for (let [key, value] of Object.entries(queryParams)) {
      queryParamsString += "" + key + "=" + value + "&";
    }
    queryParamsString = queryParamsString.substr(0, queryParamsString.length - 1);
  }

  if (queryParamsString && queryParamsString !== undefined) pathUrl += queryParamsString;

  return {
    method: method,
    url: pathUrl,
    headers: apiHeaders,
    ...(payload && { data: payload }),
  };
};

const apiCreateRequest = (method, baseUrl, apiUrl, queryParams, payload, headers) => {
  const apiParams = buildApiRequestParams(method, baseUrl, apiUrl, queryParams, payload, { ...headers });
  if (apiParams.url) return axios(apiParams);
  else return { status: 400, message: "missing url parameter" };
};

const createRequest = (method, baseUrl, apiUrl, queryParams, payload, headers) => {
  try {
    const response = apiCreateRequest(method, baseUrl, apiUrl, queryParams, payload, headers);
    return response;
  } catch (e) {
    throw e;
  }
};

export { GET, POST, createRequest };
