import "./Affollato.scss";

export const Affollato = () => (
  <svg id="Livello_2_affollato" viewBox="0 0 35.47 50.5" className="affollatoIcon">
    <g id="Livello_1-2_affollato">
      <g id="Raggruppa_1284_affollato">
        <path
          id="Tracciato_3201-3_affollato"
          className="cls-3"
          d="m34.72,14.7C33.09,5.31,24.16-.97,14.78.66c-2.15.37-4.21,1.15-6.07,2.3C3.21,6.39.56,11.49.41,18.32c.07.7.14,1.76.29,2.81.49,2.64,1.48,5.17,2.91,7.44,4.03,7.1,8.47,13.95,12.9,20.79.33.66,1.14.93,1.81.6.26-.13.48-.35.61-.61,1.34-2.07,2.72-4.11,4-6.22,3.35-5.5,6.72-10.99,9.94-16.57,2.02-3.6,2.68-7.81,1.86-11.85"
        />
        <path
          id="Tracciato_3222_affollato"
          className="cls-1"
          d="m1.3,21.65h33.1c-2.49,7.72-7.14,15.17-14.59,26.34-.18.28-.66,1.68-1.08,2.08-.48.46-1.24.44-1.71-.04-.26-.27-.57-1.56-.94-2.04-2.9-3.84-8.27-11.62-10.24-14.9-.93-2.79-6.14-8.8-4.55-11.44Z"
        />
        <path
          id="Tracciato_3201-3-2_affollato"
          className="cls-2"
          d="m34.72,14.7C33.09,5.31,24.16-.97,14.78.66c-2.15.37-4.21,1.15-6.07,2.3C3.21,6.39.56,11.49.41,18.32c.07.7.14,1.76.29,2.81.49,2.64,1.48,5.17,2.91,7.44,4.03,7.1,8.47,13.95,12.9,20.79.33.66,1.14.93,1.81.6.26-.13.48-.35.61-.61,1.34-2.07,2.72-4.11,4-6.22,3.35-5.5,6.72-10.99,9.94-16.57,2.02-3.6,2.68-7.81,1.86-11.85"
        />
      </g>
    </g>
  </svg>
);
