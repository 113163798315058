import update from "immutability-helper";
import { createSlice } from "@reduxjs/toolkit";
import { setNotification, resetNotification } from "./index";

export const initialState = null;

const setNotificationHandler = (state, action) => {
  return update(state, {
    $set: {
      code: action.payload.code,
      message: action.payload.message && action.payload.message !== undefined ? action.payload.message : null,
      description: action.payload.description && action.payload.description !== undefined ? action.payload.description : null,
    },
  });
};

const resetNotificationHandler = (state) => {
  return update(state, { $set: initialState });
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setNotification, (state, action) => setNotificationHandler(state, action));
    builder.addCase(resetNotification, (state) => resetNotificationHandler(state));
  },
});

export const notificationReducer = notificationSlice.reducer;
