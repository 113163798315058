import { injectIntl } from "react-intl";
import { Box, Grid, Typography } from "@mui/material";
import { IS_OSPEDALE_CLOSE_JSON, ORARIO_APERTURA_JSON, ORARIO_CHIUSURA_JSON } from "../../modules/prontoSoccorso";
import { APERTO_TITLE, CHIUSO_TITLE, ORARIO_DESCRIZIONE } from "../../modules/language";
import { getMessageString } from "../../utility";

export const AperturaProntoSoccorsoDashboard = injectIntl(({ intl, ospedale }) => (
  <Box className="aperturaProntoSoccorsoDashboard">
    {ospedale[IS_OSPEDALE_CLOSE_JSON] ? (
      <Grid item xs={12}>
        <Typography variant="caption">{getMessageString(intl, CHIUSO_TITLE)}</Typography>
      </Grid>
    ) : (
      <Grid item xs={12}>
        <Typography variant="caption">{getMessageString(intl, APERTO_TITLE)}</Typography>
        {ospedale[ORARIO_APERTURA_JSON] &&
          ospedale[ORARIO_APERTURA_JSON] !== undefined &&
          ospedale[ORARIO_CHIUSURA_JSON] &&
          ospedale[ORARIO_CHIUSURA_JSON] !== undefined && (
            <Typography variant="caption">
              {getMessageString(intl, ORARIO_DESCRIZIONE) + ospedale[ORARIO_APERTURA_JSON] + " - " + ospedale[ORARIO_CHIUSURA_JSON]}
            </Typography>
          )}
      </Grid>
    )}
  </Box>
));
