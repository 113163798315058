import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getConfig } from "../../modules/configuration";
import { fetchDettaglioProntoSoccorso, fetchDettaglioProntoSoccorsoEnded, getDettaglioProntoSoccorso } from "../../modules/prontoSoccorso";
import { DettaglioProntoSoccorso } from "./DettaglioProntoSoccorso";
import { LoadingInfo } from "../loading/LoadingInfo";
import { ErroreDettaglioProntoSoccorso } from "./ErroreDettaglioProntoSoccorso";

export const Dettaglio = () => {
  const { idProntoSoccorso } = useParams();
  const dispatch = useDispatch();
  const config = useSelector(getConfig);
  const dettaglioProntoSoccorso = useSelector(getDettaglioProntoSoccorso);
  const fetchEnded = useSelector(fetchDettaglioProntoSoccorsoEnded);

  useEffect(() => {
    if (config && idProntoSoccorso && idProntoSoccorso !== undefined) {
      dispatch(fetchDettaglioProntoSoccorso(idProntoSoccorso));
      const intervallo = setInterval(() => {
        dispatch(fetchDettaglioProntoSoccorso(idProntoSoccorso));
      }, config.pollingInterval);
      return () => clearInterval(intervallo);
    }
    // eslint-disable-next-line
  }, [idProntoSoccorso, config]);

  return fetchEnded ? (
    dettaglioProntoSoccorso ? (
      <DettaglioProntoSoccorso data={dettaglioProntoSoccorso} />
    ) : (
      <ErroreDettaglioProntoSoccorso />
    )
  ) : (
    <LoadingInfo />
  );
};
