import { createSelector } from "@reduxjs/toolkit";

const fetchConfigStore = (state) => state.configuration;

export const getConfig = createSelector(fetchConfigStore, (configuration) => configuration.data);

export const fetchConfigEnded = createSelector(fetchConfigStore, (configuration) => configuration.fetchEnded);

export const getUserPosition = createSelector(fetchConfigStore, (configuration) => configuration.userPosition);

export const isFromSaluteSimplex = createSelector(fetchConfigStore, (configuration) => configuration.fromSaluteSimplex);
