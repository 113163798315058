import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { ProntoSoccorsoLiveIcon } from "../styles/images";
import { LogoAlisaIcon, LogoLDIcon, LogoRegioneIcon } from "../styles/images";
import { getUserPosition, geolocalizzaUtente, LATITUDINE, LONGITUDINE } from "../modules/configuration";

export const SplashScreen = ({ onTimeoutEnd }) => {
  const dispatch = useDispatch();
  const userPosition = useSelector(getUserPosition);

  const localizzaUtente = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentPosition = {
            latitudine: position.coords.latitude,
            longitudine: position.coords.longitude,
          };
          dispatch(geolocalizzaUtente({ [LATITUDINE]: currentPosition.latitudine, [LONGITUDINE]: currentPosition.longitudine }));
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) console.log("L'utente non ha permesso la geolocalizzazione");
          else if (error.code === error.POSITION_UNAVAILABLE) console.log("Impossibile geolocalizzare la posizione del dispositivo");
          else if (error.code === error.TIMEOUT) console.log("Tempo scaduto");
          else console.log("Errore sconosciuto");
          dispatch(geolocalizzaUtente({ [LATITUDINE]: null, [LONGITUDINE]: null }));
        }
      );
    } else {
      console.log("Il browser non supporta la geolocalizzazione");
      dispatch(geolocalizzaUtente({ [LATITUDINE]: null, [LONGITUDINE]: null }));
    }
  };

  useEffect(() => {
    if (userPosition) onTimeoutEnd();
    else localizzaUtente();
    // eslint-disable-next-line
  }, [userPosition]);

  return (
    <Box className="splashScreen">
      <Typography variant="h1" sx={{ height: 0 }}>
        <div style={{ display: "none" }}>Splash screen</div>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} className="logo">
          <ProntoSoccorsoLiveIcon />
        </Grid>
        <Grid item xs={12} className="footer">
          <Grid container spacing={2}>
            <Grid item xs={4} className="logoRegione">
              <LogoRegioneIcon />
            </Grid>
            <Grid item xs={4} className="logoAlisa">
              <LogoAlisaIcon />
            </Grid>
            <Grid item xs={4} className="logoLD">
              <LogoLDIcon />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
