import "./LogoAlisa.scss";

export const LogoAlisa = () => (
  <svg id="Livello_2_alisa" data-name="Livello 2" viewBox="0 0 257.33 110.79" className="logoAlisa">
    <g id="Livello_2-2_alisa" data-name="Livello 2">
      <g id="Livello_1-2_alisa" data-name="Livello 1-2">
        <g>
          <path
            className="cls-1"
            d="m0,82.91L35.38.94h23.43l31.61,81.98h-21.52l-5.8-15.94H28.13l-6.5,15.94H0Zm33.99-30.01h23.96l-11.37-31.17-12.59,31.17Z"
          />
          <rect className="cls-1" x="94.38" y=".94" width="17.34" height="81.98" />
          <path
            className="cls-1"
            d="m123.67,9.37c0-2.62.9-4.83,2.7-6.65,1.8-1.82,3.99-2.72,6.58-2.72s4.83.9,6.61,2.69c1.78,1.8,2.67,4.02,2.67,6.68s-.89,4.83-2.67,6.62c-1.78,1.8-3.98,2.7-6.61,2.7s-4.79-.91-6.58-2.72c-1.8-1.82-2.7-4.01-2.7-6.59m.64,17.99h17.34v55.55h-17.34V27.36h0Z"
          />
          <path
            className="cls-1"
            d="m151.98,79.92v-12.36c3.48,1.6,6.7,2.84,9.66,3.72,2.96.88,5.44,1.32,7.45,1.32,2.75,0,4.82-.35,6.21-1.05,1.39-.7,2.09-1.76,2.09-3.16,0-1.17-.52-2.22-1.57-3.13-1.04-.92-3.42-2.16-7.13-3.72-.39-.16-1.01-.41-1.86-.76-10.56-4.41-15.84-10.08-15.84-16.99,0-5.27,1.94-9.51,5.83-12.72,3.89-3.2,9.04-4.8,15.46-4.8,3.44,0,6.7.37,9.77,1.11s6.06,1.86,8.96,3.34v12.25c-3.64-1.95-6.87-3.38-9.72-4.28-2.84-.9-5.46-1.35-7.86-1.35-2.09,0-3.69.37-4.81,1.11s-1.68,1.8-1.68,3.16c0,1.8,3.13,4,9.4,6.62,2.55,1.02,4.56,1.86,6.03,2.52,4.02,1.76,6.95,3.87,8.79,6.33s2.76,5.51,2.76,9.14c0,5.74-1.96,10.16-5.89,13.24-3.93,3.09-9.5,4.63-16.73,4.63-3.6,0-7.01-.34-10.24-1.03-3.23-.68-6.26-1.73-9.08-3.13"
          />
          <path
            className="cls-2"
            d="m232.9,77.76c-2.59,2.11-5.29,3.67-8.09,4.69-2.8,1.02-5.81,1.52-9.02,1.52-5.3,0-9.3-1.3-12.01-3.9-2.71-2.6-4.06-6.46-4.06-11.57,0-4.45,2-8.12,6-10.99s12.77-6.28,26.31-10.22c.04-.2.07-.41.09-.64.02-.23.03-.55.03-.94,0-2.66-.88-4.62-2.64-5.89s-4.48-1.9-8.15-1.9c-3.09,0-6.26.76-9.51,2.29-3.25,1.52-6.42,3.73-9.51,6.62l.23-13.83c3.33-2.27,6.99-3.96,10.99-5.07s8.5-1.67,13.49-1.67c7.93,0,13.67,1.54,17.23,4.63s5.34,8.03,5.34,14.83v22.09c0,1.29.17,2.19.52,2.7.35.51.93.76,1.74.76.73,0,1.5-.1,2.29-.29s1.85-.57,3.16-1.11v9.73c-3.13,1.52-5.91,2.64-8.32,3.34-2.42.7-4.71,1.05-6.87,1.05-2.75,0-4.9-.52-6.47-1.55-1.57-1.03-2.49-2.59-2.76-4.66m-.87-8.14v-13.59c-5.92,2.11-9.87,3.96-11.86,5.54s-2.99,3.47-2.99,5.65c0,1.8.54,3.22,1.62,4.28,1.08,1.05,2.57,1.58,4.47,1.58,1.28,0,2.65-.29,4.12-.88s3.02-1.44,4.64-2.58h0Z"
          />
          <g>
            <path
              className="cls-1"
              d="m.2,105.99v-2.58c.88.55,1.62.92,2.22,1.14.6.22,1.18.32,1.75.32s1-.09,1.3-.26c.29-.18.44-.43.44-.78,0-.28-.09-.5-.28-.67-.18-.17-.61-.37-1.28-.6-1.66-.58-2.8-1.18-3.42-1.79C.31,100.16,0,99.39,0,98.45,0,97.46.41,96.65,1.22,96.03c.81-.62,1.87-.93,3.17-.93.66,0,1.32.08,1.99.24.66.16,1.32.4,1.97.71v2.39c-.78-.39-1.48-.69-2.1-.87-.62-.19-1.18-.28-1.69-.28-.55,0-.98.08-1.27.24-.29.16-.44.39-.44.69,0,.24.13.47.39.69.26.22.65.42,1.18.61.11.05.28.11.51.19,1.11.41,1.9.76,2.39,1.06.51.31.89.71,1.14,1.2.26.49.39,1.06.39,1.71,0,1.08-.41,1.92-1.22,2.53-.81.62-1.94.92-3.38.92-.72,0-1.42-.09-2.09-.28-.67-.19-1.32-.47-1.94-.85"
            />
            <path
              className="cls-1"
              d="m10.24,96.48c0-.37.13-.69.39-.95.26-.26.57-.39.94-.39s.69.13.95.38c.26.26.38.57.38.95s-.13.69-.38.94c-.25.26-.57.38-.95.38s-.68-.13-.94-.39c-.26-.26-.39-.57-.39-.94m.09,10.44v-7.89h2.49v7.89h-2.49Z"
            />
            <path
              className="cls-1"
              d="m14.29,106.49v-1.76c.5.23.96.4,1.39.53s.78.19,1.07.19c.39,0,.69-.05.89-.15.2-.1.3-.25.3-.45,0-.17-.07-.31-.22-.45-.15-.13-.49-.31-1.02-.53-.05-.02-.14-.06-.27-.11-1.51-.62-2.26-1.43-2.26-2.41,0-.75.28-1.35.84-1.8.56-.45,1.29-.68,2.22-.68.49,0,.96.05,1.4.16.44.1.87.26,1.29.47v1.74c-.52-.28-.99-.48-1.4-.61s-.78-.19-1.13-.19c-.3,0-.53.05-.69.16s-.24.26-.24.45c0,.26.45.57,1.35.94.37.14.66.26.87.36.58.25,1,.55,1.26.9s.39.78.39,1.3c0,.81-.28,1.44-.84,1.88-.56.44-1.36.66-2.4.66-.51,0-1-.05-1.47-.14-.46-.1-.89-.25-1.3-.45"
            />
            <path
              className="cls-1"
              d="m21.98,100.75h-1.12v-.53l3.22-3.49h.37v2.3h2.69v1.72h-2.69v2.91c0,.6.09,1.01.26,1.21s.48.3.94.3c.22,0,.46-.03.7-.1.24-.06.51-.16.79-.29l.02,1.86c-.4.15-.8.26-1.2.33-.4.07-.8.11-1.19.11-1,0-1.72-.26-2.15-.77-.43-.52-.64-1.41-.64-2.67v-2.9h0Z"
            />
            <path
              className="cls-1"
              d="m30.54,102.2h3.07c-.05-.52-.2-.92-.46-1.2-.26-.29-.6-.43-1.02-.43s-.78.14-1.06.43c-.28.29-.45.69-.52,1.2m5.38,3.85c-.54.35-1.1.61-1.7.78-.6.17-1.23.26-1.91.26-1.36,0-2.42-.37-3.19-1.12-.77-.74-1.16-1.77-1.16-3.09,0-1.21.38-2.19,1.13-2.92.76-.73,1.76-1.09,3.01-1.09s2.21.39,2.93,1.18,1.07,1.88,1.07,3.26v.08h-5.59v.03c0,.62.21,1.12.64,1.48s1.01.55,1.75.55c.5,0,1-.09,1.5-.27s1-.44,1.5-.79v1.64l.02.02Z"
            />
            <path
              className="cls-1"
              d="m37.46,106.92v-7.89h2.47v.99c.38-.39.79-.68,1.22-.87.43-.18.91-.28,1.44-.28.59,0,1.07.12,1.46.36.38.24.68.6.88,1.09.51-.53.98-.91,1.43-1.12.45-.22.97-.32,1.56-.32.86,0,1.52.26,1.98.79.46.52.69,1.28.69,2.27v4.99h-2.49v-4.65c0-.58-.09-1-.26-1.25s-.46-.37-.85-.37c-.34,0-.66.1-.95.31s-.56.52-.81.93v5.02h-2.42v-4.65c0-.58-.09-.99-.26-1.24-.18-.25-.46-.38-.85-.38-.34,0-.66.1-.95.31-.3.21-.57.52-.81.93v5.02h-2.48Z"
            />
            <path
              className="cls-1"
              d="m56.63,106.19c-.37.3-.76.52-1.16.67-.4.14-.83.22-1.29.22-.76,0-1.33-.18-1.72-.55s-.58-.92-.58-1.64c0-.63.29-1.15.86-1.56.57-.41,1.83-.89,3.77-1.45,0-.03,0-.06,0-.09v-.14c0-.37-.13-.65-.38-.83s-.64-.27-1.17-.27c-.44,0-.9.11-1.37.32-.47.22-.92.53-1.36.94l.03-1.96c.48-.32,1-.56,1.58-.72.57-.16,1.22-.24,1.93-.24,1.14,0,1.96.22,2.47.66.51.44.77,1.14.77,2.1v3.14c0,.18.03.31.07.38.05.07.13.11.25.11.11,0,.22-.01.33-.04s.27-.08.45-.16v1.38c-.45.22-.85.37-1.19.47-.35.1-.68.15-.99.15-.39,0-.7-.07-.93-.22-.22-.15-.35-.37-.39-.66m-.12-1.16v-1.93c-.85.3-1.42.56-1.7.79-.29.22-.43.49-.43.8,0,.26.08.46.23.61.16.15.37.22.64.22.18,0,.38-.04.59-.12s.43-.2.66-.36h.01Z"
            />
            <path
              className="cls-1"
              d="m65.85,105.99v-2.58c.88.55,1.62.92,2.22,1.14s1.18.32,1.75.32,1-.09,1.3-.26c.29-.18.44-.43.44-.78,0-.28-.09-.5-.28-.67-.18-.17-.61-.37-1.28-.6-1.66-.58-2.8-1.18-3.42-1.79-.62-.61-.93-1.38-.93-2.32,0-.99.41-1.8,1.22-2.42.81-.62,1.87-.93,3.17-.93.66,0,1.32.08,1.99.24.66.16,1.32.4,1.97.71v2.39c-.78-.39-1.48-.69-2.1-.87-.62-.19-1.18-.28-1.69-.28-.55,0-.98.08-1.27.24-.29.16-.44.39-.44.69,0,.24.13.47.39.69s.65.42,1.18.61c.11.05.28.11.51.19,1.11.41,1.9.76,2.39,1.06.51.31.89.71,1.14,1.2.26.49.39,1.06.39,1.71,0,1.08-.41,1.92-1.22,2.53-.81.62-1.94.92-3.38.92-.72,0-1.42-.09-2.09-.28s-1.32-.47-1.94-.85"
            />
            <path
              className="cls-1"
              d="m80.24,106.19c-.37.3-.76.52-1.16.67-.4.14-.83.22-1.29.22-.76,0-1.33-.18-1.72-.55s-.58-.92-.58-1.64c0-.63.29-1.15.86-1.56.57-.41,1.83-.89,3.77-1.45,0-.03,0-.06.01-.09v-.14c0-.37-.13-.65-.38-.83s-.64-.27-1.17-.27c-.44,0-.9.11-1.37.32-.47.22-.92.53-1.36.94l.03-1.96c.48-.32,1-.56,1.58-.72.57-.16,1.22-.24,1.93-.24,1.14,0,1.96.22,2.47.66s.77,1.14.77,2.1v3.14c0,.18.03.31.07.38.05.07.13.11.25.11.11,0,.22-.01.33-.04s.27-.08.45-.16v1.38c-.45.22-.85.37-1.19.47-.35.1-.68.15-.99.15-.39,0-.7-.07-.93-.22-.22-.15-.35-.37-.39-.66m-.12-1.16v-1.93c-.85.3-1.42.56-1.7.79-.29.22-.43.49-.43.8,0,.26.08.46.23.61.16.15.37.22.64.22.18,0,.38-.04.59-.12s.43-.2.66-.36h0Z"
            />
            <path
              className="cls-1"
              d="m84.76,106.92v-7.89h2.47v.99c.39-.4.79-.69,1.22-.87s.91-.28,1.46-.28c.86,0,1.53.26,1.99.79.46.52.69,1.28.69,2.27v4.99h-2.49v-4.65c0-.56-.09-.97-.27-1.23s-.46-.39-.85-.39c-.34,0-.66.1-.96.31-.3.21-.56.52-.8.93v5.02h-2.47,0Z"
            />
            <path
              className="cls-1"
              d="m94.28,96.48c0-.37.13-.69.39-.95.26-.26.57-.39.94-.39s.69.13.95.38c.26.26.38.57.38.95s-.13.69-.38.94c-.25.26-.57.38-.95.38s-.68-.13-.94-.39-.39-.57-.39-.94m.09,10.44v-7.89h2.49v7.89h-2.49Z"
            />
            <path
              className="cls-1"
              d="m99,100.75h-1.12v-.53l3.22-3.49h.37v2.3h2.69v1.72h-2.69v2.91c0,.6.09,1.01.26,1.21.17.2.48.3.94.3.22,0,.46-.03.7-.1.24-.06.51-.16.79-.29l.02,1.86c-.4.15-.8.26-1.2.33-.4.07-.8.11-1.19.11-1,0-1.72-.26-2.15-.77-.43-.52-.64-1.41-.64-2.67v-2.9h0Z"
            />
            <path
              className="cls-1"
              d="m109.69,106.19c-.37.3-.76.52-1.16.67-.4.14-.83.22-1.29.22-.76,0-1.33-.18-1.72-.55s-.58-.92-.58-1.64c0-.63.29-1.15.86-1.56.57-.41,1.83-.89,3.77-1.45,0-.03,0-.06.01-.09v-.14c0-.37-.13-.65-.38-.83s-.64-.27-1.17-.27c-.44,0-.9.11-1.37.32-.47.22-.92.53-1.36.94l.03-1.96c.48-.32,1-.56,1.58-.72.57-.16,1.22-.24,1.93-.24,1.14,0,1.96.22,2.47.66s.77,1.14.77,2.1v3.14c0,.18.03.31.07.38.05.07.13.11.25.11.11,0,.22-.01.33-.04s.27-.08.45-.16v1.38c-.45.22-.85.37-1.19.47-.35.1-.68.15-.99.15-.39,0-.7-.07-.93-.22-.22-.15-.35-.37-.39-.66m-.12-1.16v-1.93c-.85.3-1.42.56-1.7.79-.29.22-.43.49-.43.8,0,.26.08.46.23.61.16.15.37.22.64.22.18,0,.38-.04.59-.12s.43-.2.66-.36h0Z"
            />
            <path
              className="cls-1"
              d="m114.21,106.92v-7.89h2.49v2.11c.42-.79.83-1.37,1.21-1.71.39-.35.81-.52,1.27-.52.23,0,.47.05.72.14s.54.24.85.44l-.67,2.27c-.25-.2-.48-.34-.69-.43-.21-.09-.42-.13-.62-.13-.47,0-.89.21-1.27.62-.37.42-.64.98-.8,1.71v3.39s-2.49,0-2.49,0Z"
            />
            <path
              className="cls-1"
              d="m121.42,96.48c0-.37.13-.69.39-.95.26-.26.57-.39.94-.39s.69.13.95.38c.26.26.38.57.38.95s-.13.69-.38.94c-.25.26-.57.38-.95.38s-.68-.13-.94-.39-.39-.57-.39-.94m.09,10.44v-7.89h2.49v7.89h-2.49Z"
            />
            <path
              className="cls-1"
              d="m125.32,102.99c0-1.21.4-2.2,1.2-2.96.8-.76,1.84-1.14,3.12-1.14s2.33.38,3.14,1.15c.81.77,1.21,1.75,1.21,2.96s-.4,2.19-1.21,2.95c-.8.76-1.85,1.14-3.14,1.14s-2.32-.38-3.12-1.14-1.2-1.74-1.2-2.95m2.55,0c0,.67.16,1.21.49,1.61s.76.6,1.29.6.97-.2,1.29-.6c.32-.4.48-.94.48-1.61s-.16-1.21-.49-1.62-.75-.61-1.29-.61-.95.2-1.28.61-.49.95-.49,1.62h0Z"
            />
            <path
              className="cls-1"
              d="m140.26,106.92v-11.63h4.29c1.58,0,2.74.28,3.5.82.75.55,1.13,1.4,1.13,2.55,0,.64-.17,1.19-.5,1.65s-.84.84-1.53,1.16c.29.11.55.28.78.53s.43.56.6.94l1.86,3.99h-3.28l-1.48-3.35c-.24-.53-.51-.9-.81-1.1-.31-.2-.74-.3-1.3-.3h-.34v4.73h-2.92Zm4.31-6.85c.54,0,.94-.11,1.2-.34.26-.22.39-.56.39-1.02s-.14-.77-.41-1c-.28-.23-.67-.34-1.19-.34h-1.4v2.71h1.41Z"
            />
            <path
              className="cls-1"
              d="m152.92,102.2h3.07c-.05-.52-.2-.92-.46-1.2-.26-.29-.6-.43-1.02-.43s-.78.14-1.06.43-.45.69-.52,1.2m5.38,3.85c-.54.35-1.1.61-1.7.78-.6.17-1.23.26-1.91.26-1.36,0-2.42-.37-3.19-1.12-.77-.74-1.16-1.77-1.16-3.09,0-1.21.38-2.19,1.13-2.92.76-.73,1.76-1.09,3.01-1.09s2.21.39,2.93,1.18c.72.79,1.07,1.88,1.07,3.26v.08h-5.59v.03c0,.62.21,1.12.64,1.48s1.01.55,1.75.55c.5,0,1-.09,1.5-.27s1-.44,1.5-.79v1.64l.02.02Z"
            />
            <path
              className="cls-1"
              d="m161.59,104.33c-.7-.21-1.22-.52-1.56-.94-.34-.42-.51-.96-.51-1.61,0-.89.35-1.58,1.06-2.04.7-.47,1.73-.7,3.06-.7h4v1.65h-1.11c.13.2.22.41.28.63.06.22.09.46.09.72,0,.71-.21,1.27-.63,1.7-.42.42-1.05.71-1.9.85-.2.04-.46.07-.79.1-.65.06-.98.24-.98.53s.45.47,1.35.56c.34.03.61.06.82.09.84.11,1.48.36,1.92.76.44.39.66.91.66,1.56,0,.85-.35,1.5-1.06,1.94-.71.44-1.75.66-3.14.66-1.27,0-2.27-.2-2.99-.62-.72-.41-1.08-.98-1.08-1.7,0-.46.18-.86.54-1.19.35-.33.87-.59,1.56-.76-.31-.09-.53-.22-.69-.38-.15-.16-.23-.36-.23-.59,0-.3.11-.56.33-.76.22-.2.55-.35,1-.45m1.65-3.79c-.43,0-.77.12-1.03.37s-.39.57-.39.98.13.73.39.98.6.37,1.03.37.77-.12,1.03-.37.39-.57.39-.98-.13-.73-.39-.98-.6-.37-1.03-.37Zm-1.84,7.78c0,.32.15.56.46.73.31.16.77.24,1.38.24s1.08-.08,1.39-.24c.32-.16.48-.4.48-.71,0-.29-.16-.51-.48-.68-.32-.16-.77-.24-1.34-.24-.6,0-1.07.08-1.4.24-.33.16-.49.38-.49.67h0Z"
            />
            <path
              className="cls-1"
              d="m168.69,96.48c0-.37.13-.69.39-.95.26-.26.57-.39.94-.39s.69.13.95.38c.26.26.38.57.38.95s-.13.69-.38.94c-.25.26-.57.38-.95.38s-.68-.13-.94-.39c-.26-.26-.39-.57-.39-.94m.09,10.44v-7.89h2.49v7.89h-2.49Z"
            />
            <path
              className="cls-1"
              d="m172.59,102.99c0-1.21.4-2.2,1.2-2.96s1.84-1.14,3.12-1.14,2.33.38,3.14,1.15c.81.77,1.21,1.75,1.21,2.96s-.4,2.19-1.21,2.95c-.8.76-1.85,1.14-3.14,1.14s-2.32-.38-3.12-1.14c-.8-.76-1.2-1.74-1.2-2.95m2.55,0c0,.67.16,1.21.49,1.61s.76.6,1.29.6.97-.2,1.29-.6c.32-.4.48-.94.48-1.61s-.16-1.21-.49-1.62-.75-.61-1.29-.61-.95.2-1.28.61-.49.95-.49,1.62h0Z"
            />
            <path
              className="cls-1"
              d="m182.57,106.92v-7.89h2.47v.99c.39-.4.79-.69,1.22-.87.43-.18.91-.28,1.46-.28.86,0,1.53.26,1.99.79.46.52.69,1.28.69,2.27v4.99h-2.49v-4.65c0-.56-.09-.97-.27-1.23-.18-.26-.46-.39-.85-.39-.34,0-.66.1-.96.31s-.56.52-.8.93v5.02h-2.47.01Z"
            />
            <path
              className="cls-1"
              d="m194.33,102.2h3.07c-.05-.52-.2-.92-.46-1.2-.26-.29-.6-.43-1.02-.43s-.78.14-1.06.43-.45.69-.52,1.2m5.38,3.85c-.54.35-1.1.61-1.7.78-.6.17-1.23.26-1.91.26-1.36,0-2.42-.37-3.19-1.12-.77-.74-1.16-1.77-1.16-3.09,0-1.21.38-2.19,1.13-2.92.76-.73,1.76-1.09,3.01-1.09s2.21.39,2.93,1.18c.72.79,1.07,1.88,1.07,3.26v.08h-5.59v.03c0,.62.21,1.12.64,1.48s1.01.55,1.75.55c.5,0,1-.09,1.5-.27s1-.44,1.5-.79v1.64l.02.02Z"
            />
            <polygon className="cls-1" points="206.13 106.92 206.13 95.29 209.04 95.29 209.04 104.67 214.05 104.67 214.05 106.92 206.13 106.92" />
            <path
              className="cls-1"
              d="m215.04,96.48c0-.37.13-.69.39-.95.26-.26.57-.39.94-.39s.69.13.95.38c.26.26.38.57.38.95s-.13.69-.38.94c-.25.26-.57.38-.95.38s-.68-.13-.94-.39c-.26-.26-.39-.57-.39-.94m.09,10.44v-7.89h2.49v7.89h-2.49Z"
            />
            <path
              className="cls-1"
              d="m221.17,104.33c-.7-.21-1.22-.52-1.56-.94-.34-.42-.51-.96-.51-1.61,0-.89.35-1.58,1.06-2.04.7-.47,1.73-.7,3.06-.7h4v1.65h-1.11c.13.2.22.41.28.63.06.22.09.46.09.72,0,.71-.21,1.27-.63,1.7-.42.42-1.05.71-1.9.85-.2.04-.46.07-.79.1-.65.06-.98.24-.98.53s.45.47,1.35.56c.34.03.61.06.82.09.84.11,1.48.36,1.92.76.44.39.66.91.66,1.56,0,.85-.35,1.5-1.06,1.94-.71.44-1.75.66-3.14.66-1.27,0-2.27-.2-2.99-.62-.72-.41-1.08-.98-1.08-1.7,0-.46.18-.86.54-1.19.35-.33.87-.59,1.56-.76-.31-.09-.53-.22-.69-.38-.15-.16-.23-.36-.23-.59,0-.3.11-.56.33-.76.22-.2.55-.35,1-.45m1.65-3.79c-.43,0-.77.12-1.03.37s-.39.57-.39.98.13.73.39.98.6.37,1.03.37.77-.12,1.03-.37.39-.57.39-.98-.13-.73-.39-.98-.6-.37-1.03-.37Zm-1.84,7.78c0,.32.15.56.46.73.31.16.77.24,1.38.24s1.08-.08,1.39-.24c.32-.16.48-.4.48-.71,0-.29-.16-.51-.48-.68-.32-.16-.77-.24-1.34-.24-.6,0-1.07.08-1.4.24-.33.16-.49.38-.49.67h0Z"
            />
            <path
              className="cls-1"
              d="m233.68,105.93c-.39.4-.8.69-1.22.87s-.91.27-1.45.27c-.86,0-1.53-.26-1.99-.79-.47-.53-.7-1.28-.7-2.26v-4.99h2.49v4.65c0,.56.09.97.27,1.23.18.26.46.39.84.39.34,0,.66-.1.97-.31.3-.21.57-.52.8-.93v-5.03h2.49v7.89h-2.49v-.99h-.01Z"
            />
            <path
              className="cls-1"
              d="m237.96,106.92v-7.89h2.49v2.11c.42-.79.83-1.37,1.21-1.71.39-.35.81-.52,1.27-.52.23,0,.47.05.72.14s.54.24.85.44l-.67,2.27c-.25-.2-.48-.34-.69-.43-.21-.09-.42-.13-.62-.13-.47,0-.89.21-1.27.62-.37.42-.64.98-.8,1.71v3.39h-2.49Z"
            />
            <path
              className="cls-1"
              d="m245.17,96.48c0-.37.13-.69.39-.95.26-.26.57-.39.94-.39s.69.13.95.38c.26.26.38.57.38.95s-.13.69-.38.94c-.25.26-.57.38-.95.38s-.68-.13-.94-.39c-.26-.26-.39-.57-.39-.94m.09,10.44v-7.89h2.49v7.89h-2.49Z"
            />
            <path
              className="cls-1"
              d="m253.81,106.19c-.37.3-.76.52-1.16.67-.4.14-.83.22-1.29.22-.76,0-1.33-.18-1.72-.55s-.58-.92-.58-1.64c0-.63.29-1.15.86-1.56.57-.41,1.83-.89,3.77-1.45,0-.03,0-.06,0-.09v-.14c0-.37-.13-.65-.38-.83s-.64-.27-1.17-.27c-.44,0-.9.11-1.37.32-.47.22-.92.53-1.36.94l.03-1.96c.48-.32,1-.56,1.58-.72.57-.16,1.22-.24,1.93-.24,1.14,0,1.96.22,2.47.66s.77,1.14.77,2.1v3.14c0,.18.03.31.07.38.05.07.13.11.25.11.11,0,.22-.01.33-.04s.27-.08.45-.16v1.38c-.45.22-.85.37-1.19.47-.35.1-.68.15-.99.15-.39,0-.7-.07-.93-.22-.22-.15-.35-.37-.39-.66m-.12-1.16v-1.93c-.85.3-1.42.56-1.7.79-.29.22-.43.49-.43.8,0,.26.08.46.23.61.16.15.37.22.64.22.18,0,.38-.04.59-.12.21-.08.43-.2.66-.36h0Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
