import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider } from "@mui/material";
import {
  getElencoProntoSoccorso,
  fetchElencoProntoSoccorsoEnded,
  fetchElencoProntoSoccorso,
  getFilterValues,
  changeFilterValues,
  resetFilterValues,
  NOME_PS,
  CLASSIFICAZIONE_PS,
  CLASSIFICAZIONE_OSPEDALE_JSON,
  AFFOLLAMENTO_NORMO_AFFOLLATO,
  AFFOLLAMENTO_POCO_AFFOLLATO,
  AFFOLLAMENTO_AFFOLLATO,
  AFFOLLAMENTO_SOVRAFFOLLATO,
  GENOVA,
  SAVONA,
  IMPERIA,
  LA_SPEZIA,
  AFFOLLAMENTO_PS_JSON,
  GDA_JSON,
  PROVINCIA_OSPEDALE_JSON,
  PROVINCIA_GENOVA,
  PROVINCIA_SAVONA,
  PROVINCIA_IMPERIA,
  PROVINCIA_LA_SPEZIA,
  NOME_BREVE_OSPEDALE_JSON,
} from "../../modules/prontoSoccorso";
import { getConfig } from "../../modules/configuration";
import { HeaderDashboard } from "./HeaderDashboard";
import { EmptyData } from "../EmptyData";
import { BodyDashboard } from "./BodyDashboard";
import { LoadingInfo } from "../loading/LoadingInfo";

export const Dashboard = () => {
  const dispatch = useDispatch();
  const [elencoFiltrato, setElencoFiltrato] = useState([]);
  const [elencoDatasource, setElencoDatasource] = useState([]);
  const config = useSelector(getConfig);
  const elencoProntoSoccorso = useSelector(getElencoProntoSoccorso);
  const fetchEnded = useSelector(fetchElencoProntoSoccorsoEnded);
  const filterValues = useSelector(getFilterValues);
  const searchValue = filterValues[NOME_PS];
  const normoAffollatoChecked = filterValues[AFFOLLAMENTO_NORMO_AFFOLLATO];
  const pocoAffollatoChecked = filterValues[AFFOLLAMENTO_POCO_AFFOLLATO];
  const affollatoChecked = filterValues[AFFOLLAMENTO_AFFOLLATO];
  const sovraffollatoChecked = filterValues[AFFOLLAMENTO_SOVRAFFOLLATO];
  const genovaChecked = filterValues[GENOVA];
  const savonaChecked = filterValues[SAVONA];
  const imperiaChecked = filterValues[IMPERIA];
  const speziaChecked = filterValues[LA_SPEZIA];

  const resetFilter = () => {
    dispatch(changeFilterValues({ field: NOME_PS, value: "" }));
    dispatch(resetFilterValues());
  };

  useEffect(() => {
    dispatch(fetchElencoProntoSoccorso());
    resetFilter();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (config) {
      const intervallo = setInterval(() => {
        dispatch(fetchElencoProntoSoccorso());
      }, config.pollingInterval);
      return () => clearInterval(intervallo);
    }
    // eslint-disable-next-line
  }, [config]);

  useEffect(() => {
    if (elencoProntoSoccorso.length > 0)
      setElencoFiltrato(elencoProntoSoccorso.filter((ps) => ps[NOME_BREVE_OSPEDALE_JSON].toUpperCase().includes(searchValue.toUpperCase())));
  }, [elencoProntoSoccorso, searchValue]);

  useEffect(() => {
    const dataSource = elencoProntoSoccorso.filter((ps) => ps[NOME_BREVE_OSPEDALE_JSON].toUpperCase().includes(searchValue.toUpperCase()));
    let filtrato = [];
    let filtratoGda = [];
    let filtratoProvince = [];
    if (
      normoAffollatoChecked ||
      pocoAffollatoChecked ||
      affollatoChecked ||
      sovraffollatoChecked ||
      genovaChecked ||
      savonaChecked ||
      imperiaChecked ||
      speziaChecked
    ) {
      if (normoAffollatoChecked || pocoAffollatoChecked || affollatoChecked || sovraffollatoChecked) {
        let availableDatasource = dataSource.filter((ps) => ps[AFFOLLAMENTO_PS_JSON] && CLASSIFICAZIONE_PS.includes(ps[CLASSIFICAZIONE_OSPEDALE_JSON])); //prettier-ignore
        if (normoAffollatoChecked)
          filtratoGda.push(
            ...availableDatasource.filter((ps) => {
              const gda = parseInt(ps[AFFOLLAMENTO_PS_JSON][GDA_JSON]);
              return gda <= 60;
            })
          );
        if (pocoAffollatoChecked)
          filtratoGda.push(
            ...availableDatasource.filter((ps) => {
              const gda = parseInt(ps[AFFOLLAMENTO_PS_JSON][GDA_JSON]);
              return gda > 60 && gda <= 90;
            })
          );
        if (affollatoChecked)
          filtratoGda.push(
            ...availableDatasource.filter((ps) => {
              const gda = parseInt(ps[AFFOLLAMENTO_PS_JSON][GDA_JSON]);
              return gda > 90 && gda <= 150;
            })
          );

        if (sovraffollatoChecked)
          filtratoGda.push(
            ...availableDatasource.filter((ps) => {
              const gda = parseInt(ps[AFFOLLAMENTO_PS_JSON][GDA_JSON]);
              return gda > 150;
            })
          );
      } else filtratoGda = dataSource;

      if (genovaChecked || savonaChecked || imperiaChecked || speziaChecked) {
        if (genovaChecked) filtratoProvince.push(...filtratoGda.filter((ps) => ps[PROVINCIA_OSPEDALE_JSON] === PROVINCIA_GENOVA));
        if (savonaChecked) filtratoProvince.push(...filtratoGda.filter((ps) => ps[PROVINCIA_OSPEDALE_JSON] === PROVINCIA_SAVONA));
        if (imperiaChecked) filtratoProvince.push(...filtratoGda.filter((ps) => ps[PROVINCIA_OSPEDALE_JSON] === PROVINCIA_IMPERIA));
        if (speziaChecked) filtratoProvince.push(...filtratoGda.filter((ps) => ps[PROVINCIA_OSPEDALE_JSON] === PROVINCIA_LA_SPEZIA));
        filtrato = filtratoProvince;
      } else filtrato = filtratoGda;
      setElencoFiltrato(filtrato);
    } else setElencoFiltrato(dataSource);
  }, [
    elencoProntoSoccorso,
    searchValue,
    normoAffollatoChecked,
    pocoAffollatoChecked,
    affollatoChecked,
    sovraffollatoChecked,
    genovaChecked,
    savonaChecked,
    imperiaChecked,
    speziaChecked,
  ]);

  useEffect(() => {
    setElencoDatasource(elencoFiltrato.length > 0 ? elencoFiltrato : elencoProntoSoccorso);
  }, [elencoProntoSoccorso, elencoFiltrato]);

  return (
    <Box className="dashboard">
      <HeaderDashboard />
      {fetchEnded ? (
        <Box>
          {(searchValue !== "" ||
            pocoAffollatoChecked ||
            affollatoChecked ||
            sovraffollatoChecked ||
            genovaChecked ||
            savonaChecked ||
            imperiaChecked ||
            speziaChecked) &&
            elencoFiltrato.length === 0 && (
              <>
                <Box sx={{ display: "grid", justifyContent: "left", width: "100%", padding: "2em" }}>
                  <EmptyData />
                </Box>
                <Divider />
              </>
            )}
          <Box className="elencoDashboard">
            {elencoProntoSoccorso.length > 0 ? elencoDatasource.map((ps, index) => <BodyDashboard data={ps} key={index} />) : <EmptyData />}
          </Box>
        </Box>
      ) : (
        <LoadingInfo />
      )}
    </Box>
  );
};
