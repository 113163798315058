import { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { TextField, IconButton, Grid, Typography, Autocomplete } from "@mui/material";
import { PopoverFiltri } from "./PopoverFiltri";
import { AFFOLLATO_TITLE, CERCA, FILTRI, NORMO_AFFOLLATO_TITLE, POCO_AFFOLLATO_TITLE, SOVRAFFOLLATO_TITLE } from "../../modules/language";
import {
  getFilterValues,
  changeFilterValues,
  fetchElencoProntoSoccorso,
  getElencoProntoSoccorso,
  AFFOLLAMENTO_NORMO_AFFOLLATO,
  AFFOLLAMENTO_POCO_AFFOLLATO,
  AFFOLLAMENTO_AFFOLLATO,
  AFFOLLAMENTO_SOVRAFFOLLATO,
  GENOVA,
  IMPERIA,
  SAVONA,
  LA_SPEZIA,
  NOME_PS,
  NOME_BREVE_OSPEDALE_JSON,
  PROVINCIA_OSPEDALE_JSON,
  AFFOLLAMENTO_PS_JSON,
  GDA_JSON,
  PROVINCIA_GENOVA,
  PROVINCIA_IMPERIA,
  PROVINCIA_SAVONA,
  PROVINCIA_LA_SPEZIA,
} from "../../modules/prontoSoccorso";
import { getMessageString } from "../../utility";
import { CercaIcon, FiltriIcon } from "../../styles/images";
import { TagFiltri } from "./TagFiltri";

const regex = new RegExp("^([A-Z]+[\\s]?[.]?)+$");

export const RicercaOspedale = injectIntl(({ intl }) => {
  const dispatch = useDispatch();
  const [elencoFiltrato, setElencoFiltrato] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const elencoProntoSoccorso = useSelector(getElencoProntoSoccorso);
  const filterValues = useSelector(getFilterValues);
  const searchValue = filterValues[NOME_PS];
  const normoAffollatoChecked = filterValues[AFFOLLAMENTO_NORMO_AFFOLLATO];
  const pocoAffollatoChecked = filterValues[AFFOLLAMENTO_POCO_AFFOLLATO];
  const affollatoChecked = filterValues[AFFOLLAMENTO_AFFOLLATO];
  const sovraffollatoChecked = filterValues[AFFOLLAMENTO_SOVRAFFOLLATO];
  const genovaChecked = filterValues[GENOVA];
  const savonaChecked = filterValues[SAVONA];
  const imperiaChecked = filterValues[IMPERIA];
  const speziaChecked = filterValues[LA_SPEZIA];

  const tagFiltriProps = {
    [AFFOLLAMENTO_NORMO_AFFOLLATO]: { label: getMessageString(intl, NORMO_AFFOLLATO_TITLE), checked: normoAffollatoChecked },
    [AFFOLLAMENTO_POCO_AFFOLLATO]: { label: getMessageString(intl, POCO_AFFOLLATO_TITLE), checked: pocoAffollatoChecked },
    [AFFOLLAMENTO_AFFOLLATO]: { label: getMessageString(intl, AFFOLLATO_TITLE), checked: affollatoChecked },
    [AFFOLLAMENTO_SOVRAFFOLLATO]: { label: getMessageString(intl, SOVRAFFOLLATO_TITLE), checked: sovraffollatoChecked },
    [GENOVA]: { label: GENOVA, checked: genovaChecked },
    [SAVONA]: { label: SAVONA, checked: savonaChecked },
    [IMPERIA]: { label: IMPERIA, checked: imperiaChecked },
    [LA_SPEZIA]: { label: LA_SPEZIA, checked: speziaChecked },
  };

  const handleChange = (value) => {
    const valueSplitted = value.split(" ").map((el) => el.substr(0, 1).toUpperCase() + el.substr(1).toLowerCase()).join(" "); //prettier-ignore
    if (value === "" || regex.test(value.toUpperCase())) dispatch(changeFilterValues({ field: [NOME_PS], value: valueSplitted }));
  };

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    const dataSource = elencoProntoSoccorso.filter((ps) => ps[NOME_BREVE_OSPEDALE_JSON].toUpperCase().includes(searchValue.toUpperCase()));
    let filtrato = [];
    let filtratoGda = [];
    let filtratoProvince = [];
    if (
      normoAffollatoChecked ||
      pocoAffollatoChecked ||
      affollatoChecked ||
      sovraffollatoChecked ||
      genovaChecked ||
      savonaChecked ||
      imperiaChecked ||
      speziaChecked
    ) {
      if (normoAffollatoChecked || pocoAffollatoChecked || affollatoChecked || sovraffollatoChecked) {
        let availableDatasource = dataSource.filter((ps) => ps[AFFOLLAMENTO_PS_JSON]);
        if (normoAffollatoChecked)
          filtratoGda.push(
            ...availableDatasource.filter((ps) => {
              const gda = parseInt(ps[AFFOLLAMENTO_PS_JSON][GDA_JSON]);
              return gda <= 60;
            })
          );
        if (pocoAffollatoChecked)
          filtratoGda.push(
            ...availableDatasource.filter((ps) => {
              const gda = parseInt(ps[AFFOLLAMENTO_PS_JSON][GDA_JSON]);
              return gda > 60 && gda <= 90;
            })
          );
        if (affollatoChecked)
          filtratoGda.push(
            ...availableDatasource.filter((ps) => {
              const gda = parseInt(ps[AFFOLLAMENTO_PS_JSON][GDA_JSON]);
              return gda > 90 && gda <= 150;
            })
          );

        if (sovraffollatoChecked)
          filtratoGda.push(
            ...availableDatasource.filter((ps) => {
              const gda = parseInt(ps[AFFOLLAMENTO_PS_JSON][GDA_JSON]);
              return gda > 150;
            })
          );
      } else filtratoGda = dataSource;

      if (genovaChecked || savonaChecked || imperiaChecked || speziaChecked) {
        if (genovaChecked) filtratoProvince.push(...filtratoGda.filter((ps) => ps[PROVINCIA_OSPEDALE_JSON] === PROVINCIA_GENOVA));
        if (savonaChecked) filtratoProvince.push(...filtratoGda.filter((ps) => ps[PROVINCIA_OSPEDALE_JSON] === PROVINCIA_SAVONA));
        if (imperiaChecked) filtratoProvince.push(...filtratoGda.filter((ps) => ps[PROVINCIA_OSPEDALE_JSON] === PROVINCIA_IMPERIA));
        if (speziaChecked) filtratoProvince.push(...filtratoGda.filter((ps) => ps[PROVINCIA_OSPEDALE_JSON] === PROVINCIA_LA_SPEZIA));
        filtrato = filtratoProvince;
      } else filtrato = filtratoGda;
      setElencoFiltrato(filtrato);
    } else setElencoFiltrato(dataSource);
  }, [
    elencoProntoSoccorso,
    searchValue,
    normoAffollatoChecked,
    pocoAffollatoChecked,
    affollatoChecked,
    sovraffollatoChecked,
    genovaChecked,
    savonaChecked,
    imperiaChecked,
    speziaChecked,
  ]);

  useEffect(() => {
    if (elencoProntoSoccorso.length === 0) dispatch(fetchElencoProntoSoccorso());
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} className="ricercaOspedale">
          <Grid item xs={11}>
            <Autocomplete
              freeSolo
              onInputChange={(e) => {
                const value = e.target.value;
                if (value !== undefined) {
                  if (typeof value === "string" || value instanceof String) handleChange(value);
                } else handleChange("");
              }}
              onChange={(e, value) => dispatch(changeFilterValues({ field: [NOME_PS], value: value ? value.label : "" }))}
              options={elencoFiltrato.map((ps) => ({ label: ps[NOME_BREVE_OSPEDALE_JSON] }))}
              renderInput={(params) => {
                let paramsProps = params;
                paramsProps.inputProps.value = searchValue;
                delete paramsProps.inputProps.role;
                let paramsInputProps = params.InputProps;
                const endAdornment =
                  paramsInputProps.endAdornment !== undefined ? (
                    <div className="MuiAutocomplete-endAdornment customAutocomplete" style={{ position: "absolute" }}>
                      {paramsInputProps.endAdornment.props.children[0]}
                      <CercaIcon />
                    </div>
                  ) : (
                    <CercaIcon />
                  );
                paramsInputProps.endAdornment = endAdornment;

                return (
                  <TextField
                    {...paramsProps}
                    label={getMessageString(intl, CERCA)}
                    variant="outlined"
                    InputProps={{ ...paramsInputProps }}
                    fullWidth
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={1} className="filtri">
            <IconButton onClick={handleClick}>
              <FiltriIcon />
              <Typography variant="caption">{getMessageString(intl, FILTRI)}</Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TagFiltri {...tagFiltriProps} />
      </Grid>
      <PopoverFiltri anchorEl={anchorEl} onPopoverClose={handleClose} />
    </Grid>
  );
});
