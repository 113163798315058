import update from "immutability-helper";
import { createSlice } from "@reduxjs/toolkit";
import { setLocale, fetchMessagesSuccess } from "./index";

export const initialState = {
  locale: null,
  messages: JSON.stringify(Array.from(new Map().entries())),
};

const setLocaleHandler = (state, action) => {
  return update(state, {
    locale: { $set: action.payload },
  });
};

const fetchMessagesSuccessHandler = (state, action) => {
  const { locale, messages } = action.payload;
  let messagesStored = new Map(JSON.parse(state.messages));
  if (messagesStored.get(locale) === undefined) messagesStored.set(locale, messages);
  return update(state, {
    messages: { $set: JSON.stringify(Array.from(messagesStored.entries())) },
  });
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setLocale, (state, action) => setLocaleHandler(state, action));
    builder.addCase(fetchMessagesSuccess, (state, action) => fetchMessagesSuccessHandler(state, action));
  },
});

export const languageReducer = languageSlice.reducer;
