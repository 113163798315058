import { injectIntl } from "react-intl";
import { Box, Grid, Typography } from "@mui/material";
import { ORARIO } from "../../modules/language";
import { ORARIO_APERTURA_JSON, ORARIO_CHIUSURA_JSON } from "../../modules/prontoSoccorso";
import { getMessageString } from "../../utility";

export const OrarioProntoSoccorso = injectIntl(
  ({ data, intl }) =>
    data[ORARIO_APERTURA_JSON] &&
    data[ORARIO_APERTURA_JSON] !== undefined &&
    data[ORARIO_CHIUSURA_JSON] &&
    data[ORARIO_CHIUSURA_JSON] !== undefined && (
      <Box className="orarioDettaglio">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid item xs={5} md={4}>
                <Typography variant="caption">{getMessageString(intl, ORARIO)}</Typography>
              </Grid>
              <Grid item xs={7} md={8}>
                <Typography variant="caption">{data[ORARIO_APERTURA_JSON] + " - " + data[ORARIO_CHIUSURA_JSON]}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
);
