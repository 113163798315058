import "./NonDisponibile.scss";

export const NonDisponibile = () => (
  <svg id="uuid-aaeaf554-365c-4695-9e9e-dd2fe495879b_nonDisponibile" viewBox="0 0 81.38 114.57" className="nonDisponibileIcon">
    <g id="uuid-ac0e4bf5-0bf8-4563-b885-e58c5ea9f9a0_nonDisponibile">
      <g>
        <path
          id="uuid-b15a3a48-6a61-45cd-a927-e28bb31732ff_nonDisponibile"
          className="uuid-30ad095e-1533-4427-89e6-398068ceaa31"
          d="m78.19,33.99C74.59,13.28,54.88-.59,34.17,3.01c-4.75.83-9.29,2.54-13.4,5.06C8.64,15.65,2.78,26.91,2.44,41.98c.15,1.54.3,3.89.63,6.2,1.08,5.83,3.26,11.4,6.43,16.42,8.89,15.67,18.69,30.78,28.48,45.9.74,1.46,2.52,2.05,3.99,1.32.58-.29,1.05-.77,1.34-1.36,2.97-4.56,6-9.08,8.82-13.73,7.39-12.15,14.84-24.27,21.95-36.59,4.46-7.95,5.92-17.23,4.11-26.17"
        />
      </g>
    </g>
  </svg>
);
