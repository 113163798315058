import { injectIntl } from "react-intl";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { getMessageString } from "../../utility";
import { GOOGLE_MAPS_MODAL, NO_LABEL, YES_LABEL } from "../../modules/language";

export const GoogleMapsModalAlert = injectIntl(({ intl, modalOpen, handleClose, handleOk }) => (
  <Dialog open={modalOpen} onClose={handleClose} className="googleMapsModalAlert">
    <DialogContent>
      <DialogContentText>{getMessageString(intl, GOOGLE_MAPS_MODAL)}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} variant="outlined">
        {getMessageString(intl, NO_LABEL)}
      </Button>
      <Button onClick={handleOk} variant="outlined">
        {getMessageString(intl, YES_LABEL)}
      </Button>
    </DialogActions>
  </Dialog>
));
