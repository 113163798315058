import update from "immutability-helper";
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchDettaglioProntoSoccorsoSuccess,
  fetchElencoProntoSoccorsoSuccess,
  resetDettaglioProntoSoccorso,
  changeFilterValues,
  NOME_PS,
  AFFOLLAMENTO_NORMO_AFFOLLATO,
  AFFOLLAMENTO_POCO_AFFOLLATO,
  AFFOLLAMENTO_AFFOLLATO,
  AFFOLLAMENTO_SOVRAFFOLLATO,
  GENOVA,
  SAVONA,
  IMPERIA,
  LA_SPEZIA,
  resetFilterValues,
  fetchCodiciColoreSuccess,
  fetchFaqSuccess,
  fetchPrivacySuccess,
} from "./index";

export const initialState = {
  elencoProntoSoccorso: {
    data: [],
    fetchEnded: false,
  },
  dettaglioProntoSoccorso: {
    data: null,
    fetchEnded: false,
  },
  faq: {
    data: [],
    fetchEnded: false,
  },
  codiciColore: {
    data: [],
    fetchEnded: false,
  },
  privacy: {
    data: null,
    fetchEnded: false,
  },
  filterValues: {
    [NOME_PS]: "",
    [AFFOLLAMENTO_NORMO_AFFOLLATO]: false,
    [AFFOLLAMENTO_POCO_AFFOLLATO]: false,
    [AFFOLLAMENTO_AFFOLLATO]: false,
    [AFFOLLAMENTO_SOVRAFFOLLATO]: false,
    [GENOVA]: false,
    [SAVONA]: false,
    [IMPERIA]: false,
    [LA_SPEZIA]: false,
  },
};

const fetchElencoProntoSoccorsoSuccessHandler = (state, action) => {
  return update(state, {
    elencoProntoSoccorso: {
      data: { $set: action.payload.length > 0 ? action.payload : state.elencoProntoSoccorso.data },
      fetchEnded: { $set: true },
    },
  });
};

const fetchDettaglioProntoSoccorsoSuccessHandler = (state, action) => {
  return update(state, {
    dettaglioProntoSoccorso: {
      data: { $set: action.payload ? action.payload : state.dettaglioProntoSoccorso.data },
      fetchEnded: { $set: true },
    },
  });
};

const resetDettaglioProntoSoccorsoHandler = (state) => {
  return update(state, {
    dettaglioProntoSoccorso: { $set: initialState.dettaglioProntoSoccorso },
  });
};

const changeFilterValuesHandler = (state, action) => {
  const { field, value } = action.payload;
  return update(state, {
    filterValues: {
      [field]: { $set: value },
    },
  });
};

const resetFilterValuesHandler = (state) => {
  let initialFilterValues = Object.assign({}, initialState.filterValues);
  initialFilterValues[NOME_PS] = state.filterValues[NOME_PS];
  return update(state, {
    filterValues: { $set: initialFilterValues },
  });
};

const fetchFaqSuccessHandler = (state, action) => {
  return update(state, {
    faq: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchCodiciColoreSuccessHandler = (state, action) => {
  return update(state, {
    codiciColore: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchPrivacySuccessHandler = (state, action) => {
  return update(state, {
    privacy: {
      data: { $set: action.payload.text },
      fetchEnded: { $set: true },
    },
  });
};

const prontoSoccorsoSlice = createSlice({
  name: "prontoSoccorso",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchElencoProntoSoccorsoSuccess, (state, action) => fetchElencoProntoSoccorsoSuccessHandler(state, action));
    builder.addCase(fetchDettaglioProntoSoccorsoSuccess, (state, action) => fetchDettaglioProntoSoccorsoSuccessHandler(state, action));
    builder.addCase(resetDettaglioProntoSoccorso, (state, action) => resetDettaglioProntoSoccorsoHandler(state));
    builder.addCase(changeFilterValues, (state, action) => changeFilterValuesHandler(state, action));
    builder.addCase(resetFilterValues, (state, action) => resetFilterValuesHandler(state));
    builder.addCase(fetchFaqSuccess, (state, action) => fetchFaqSuccessHandler(state, action));
    builder.addCase(fetchCodiciColoreSuccess, (state, action) => fetchCodiciColoreSuccessHandler(state, action));
    builder.addCase(fetchPrivacySuccess, (state, action) => fetchPrivacySuccessHandler(state, action));
  },
});

export const prontoSoccorsoReducer = prontoSoccorsoSlice.reducer;
