import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton, Typography } from "@mui/material";
import { KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon } from "@mui/icons-material";
import { NOME_COMPLETO_OSPEDALE_JSON, NOME_BREVE_OSPEDALE_JSON, resetDettaglioProntoSoccorso, PREV_LOCATION } from "../../modules/prontoSoccorso";
import { OspedaleIcon } from "../../styles/images";
import { PATH_MAPPA } from "../../path";

export const HeaderProntoSoccorso = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onBack = () => {
    dispatch(resetDettaglioProntoSoccorso());
    const prevLocation = sessionStorage.getItem(PREV_LOCATION);
    if (prevLocation && prevLocation !== undefined) {
      sessionStorage.clear();
      navigate(prevLocation);
    } else navigate(PATH_MAPPA);
  };

  return (
    <Grid container spacing={2} className="headerDettaglio">
      <Grid item xs={12}>
        <IconButton onClick={onBack} aria-label="Torna indietro">
          <KeyboardArrowLeftOutlinedIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} className="nome">
          <Grid item xs={2}>
            <OspedaleIcon />
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h1">{data[NOME_BREVE_OSPEDALE_JSON]}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h2">({data[NOME_COMPLETO_OSPEDALE_JSON]})</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
