import "./Preferiti.scss";

export const Preferiti = () => (
  <svg id="uuid-771a0737-d35a-446f-8a0d-d6f85301f36b_preferiti" viewBox="0 0 37.03 35.9" className="preferitiIcon">
    <defs>
      <linearGradient id="uuid-70da5740-d113-491a-a078-d2391174a387_preferiti" x1="0" y1="17.95" x2="37.03" y2="17.95" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
    </defs>
    <g id="uuid-7df7a568-8be7-4c49-9993-4f128dd597ac_preferiti">
      <path
        className="uuid-05a7d8a2-8921-4e8f-a10f-6071930d2f3a"
        d="m35.18,12.11l-8.86-1.35c-.7-.11-1.3-.54-1.62-1.17L20.46,1.2c-.81-1.6-3.08-1.6-3.89,0l-4.24,8.4c-.32.63-.92,1.07-1.62,1.17l-8.86,1.35c-1.83.28-2.5,2.57-1.11,3.79l6.48,5.66c.58.5.85,1.28.71,2.03l-1.76,9.72c-.33,1.81,1.6,3.19,3.21,2.29l8.08-4.5c.66-.37,1.46-.37,2.12,0l8.08,4.5c1.61.9,3.53-.48,3.21-2.29l-1.76-9.72c-.14-.75.13-1.52.71-2.03l6.48-5.66c1.4-1.22.73-3.52-1.11-3.79Z"
      />
    </g>
  </svg>
);
