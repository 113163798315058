import "./Ppi.scss";

export const Ppi = () => (
  <svg id="uuid-e3b2a576-ee84-40ad-b47d-1961e5b539d1_ppi" viewBox="0 0 13.5 19.01" className="ppiIcon">
    <g id="uuid-7b42ac63-32d0-4a7e-ab59-85f0ec2c4eaf_ppi">
      <g id="uuid-d2ae1b21-e2d1-4fd8-ac2b-bda3554f9b3b_ppi">
        <path
          id="uuid-c08ee036-1d78-48b9-8388-c6a760a73329_ppi"
          className="uuid-4013b640-d35e-495a-8433-c10833de37bf"
          d="m12.97,5.64C12.37,2.2,9.1-.1,5.67.5c-.79.14-1.54.42-2.22.84C1.43,2.6.46,4.46.41,6.96c.03.26.05.65.1,1.03.18.97.54,1.89,1.07,2.72,1.48,2.6,3.1,5.11,4.72,7.62.12.24.42.34.66.22.1-.05.17-.13.22-.22.49-.76.99-1.51,1.46-2.28,1.23-2.02,2.46-4.03,3.64-6.07.74-1.32.98-2.86.68-4.34"
        />
      </g>
    </g>
  </svg>
);
