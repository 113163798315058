import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import {
  PATH_DASHBOARD,
  PATH_DETTAGLIO,
  PATH_ELENCO,
  PATH_INFO,
  PATH_MAPPA,
  PATH_INFO_CODICI_COLORE,
  PATH_INFO_GRADO_AFFOLLAMENTO,
  PATH_INFO_PRIVACY_POLICY,
} from "../path";
import { Dettaglio } from "../components/dettaglioProntoSoccorso/Dettaglio";
import { ElencoProntoSoccorso } from "../components/elencoProntoSoccorso/ElencoProntoSoccorso";
import { Mappa } from "../components/mappa/Mappa";
import { Dashboard } from "../components/dashboard/Dashboard";
import { CodiciColore } from "../components/info/CodiciColore.jsx";
import { GradoAffollamento } from "../components/info/GradoAffollamento";
import { Info } from "../components/info/Info";
import { NotFound } from "../components/NotFound";
import { PrivacyPolicy } from "../components/info/PrivacyPolicy";

export const SwitchContent = () => (
  <Box sx={{ height: "100%" }}>
    <Routes>
      <Route path={PATH_MAPPA} element={<Mappa />} />
      <Route path={PATH_ELENCO} element={<ElencoProntoSoccorso />} />
      <Route path={PATH_DASHBOARD} element={<Dashboard />} />
      <Route path={PATH_DETTAGLIO + "/:idProntoSoccorso"} element={<Dettaglio />} />
      <Route path={PATH_INFO}>
        <Route index element={<Info />} />
        <Route path={PATH_INFO_CODICI_COLORE} element={<CodiciColore />} />
        <Route path={PATH_INFO_GRADO_AFFOLLAMENTO} element={<GradoAffollamento />} />
        <Route path={PATH_INFO_PRIVACY_POLICY} element={<PrivacyPolicy />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Box>
);
