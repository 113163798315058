import { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, Typography } from "@mui/material";
import {
  fetchElencoProntoSoccorso,
  fetchElencoProntoSoccorsoEnded,
  getElencoProntoSoccorso,
  getFilterValues,
  changeFilterValues,
  resetFilterValues,
  NOME_PS,
  CLASSIFICAZIONE_PS,
  CLASSIFICAZIONE_OSPEDALE_JSON,
  PREFERITI,
  ID_OSPEDALE_JSON,
  AFFOLLAMENTO_NORMO_AFFOLLATO,
  AFFOLLAMENTO_POCO_AFFOLLATO,
  AFFOLLAMENTO_AFFOLLATO,
  AFFOLLAMENTO_SOVRAFFOLLATO,
  GENOVA,
  SAVONA,
  IMPERIA,
  LA_SPEZIA,
  AFFOLLAMENTO_PS_JSON,
  GDA_JSON,
  PROVINCIA_OSPEDALE_JSON,
  PROVINCIA_GENOVA,
  PROVINCIA_SAVONA,
  PROVINCIA_IMPERIA,
  PROVINCIA_LA_SPEZIA,
  NOME_BREVE_OSPEDALE_JSON,
} from "../../modules/prontoSoccorso";
import { getConfig } from "../../modules/configuration";
import { NAVBAR_ELENCO, PREFERITI_LABEL } from "../../modules/language";
import { ProntoSoccorso } from "./ProntoSoccorso";
import { EmptyData } from "../EmptyData";
import { RicercaOspedale } from "../ricercaOspedale/RicercaOspedale";
import { getMessageString } from "../../utility";
import { ElencoIcon, PreferitiIcon } from "../../styles/images";
import { LoadingInfo } from "../loading/LoadingInfo";

export const ElencoProntoSoccorso = injectIntl(({ intl }) => {
  const dispatch = useDispatch();
  const [elencoFiltrato, setElencoFiltrato] = useState([]);
  const [elencoDatasource, setElencoDatasource] = useState([]);
  const config = useSelector(getConfig);
  const elencoProntoSoccorso = useSelector(getElencoProntoSoccorso);
  const fetchEnded = useSelector(fetchElencoProntoSoccorsoEnded);
  const filterValues = useSelector(getFilterValues);
  const searchValue = filterValues[NOME_PS];
  const normoAffollatoChecked = filterValues[AFFOLLAMENTO_NORMO_AFFOLLATO];
  const pocoAffollatoChecked = filterValues[AFFOLLAMENTO_POCO_AFFOLLATO];
  const affollatoChecked = filterValues[AFFOLLAMENTO_AFFOLLATO];
  const sovraffollatoChecked = filterValues[AFFOLLAMENTO_SOVRAFFOLLATO];
  const genovaChecked = filterValues[GENOVA];
  const savonaChecked = filterValues[SAVONA];
  const imperiaChecked = filterValues[IMPERIA];
  const speziaChecked = filterValues[LA_SPEZIA];
  const [preferiti, setPreferiti] = useState([]);

  useEffect(() => {
    setPreferiti(localStorage.getItem(PREFERITI) ? JSON.parse(localStorage.getItem(PREFERITI)) : []);
  }, []);

  const resetFilter = () => {
    dispatch(changeFilterValues({ field: NOME_PS, value: "" }));
    dispatch(resetFilterValues());
  };

  useEffect(() => {
    dispatch(fetchElencoProntoSoccorso());
    resetFilter();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (config) {
      const intervallo = setInterval(() => {
        dispatch(fetchElencoProntoSoccorso());
      }, config.pollingInterval);
      return () => clearInterval(intervallo);
    }
    // eslint-disable-next-line
  }, [config]);

  useEffect(() => {
    if (elencoProntoSoccorso.length > 0)
      setElencoFiltrato(elencoProntoSoccorso.filter((ps) => ps[NOME_BREVE_OSPEDALE_JSON].toUpperCase().includes(searchValue.toUpperCase())));
  }, [elencoProntoSoccorso, searchValue]);

  useEffect(() => {
    const dataSource = elencoProntoSoccorso.filter((ps) => ps[NOME_BREVE_OSPEDALE_JSON].toUpperCase().includes(searchValue.toUpperCase()));
    let filtrato = [];
    let filtratoGda = [];
    let filtratoProvince = [];
    if (
      normoAffollatoChecked ||
      pocoAffollatoChecked ||
      affollatoChecked ||
      sovraffollatoChecked ||
      genovaChecked ||
      savonaChecked ||
      imperiaChecked ||
      speziaChecked
    ) {
      if (normoAffollatoChecked || pocoAffollatoChecked || affollatoChecked || sovraffollatoChecked) {
        let availableDatasource = dataSource.filter((ps) => ps[AFFOLLAMENTO_PS_JSON] && CLASSIFICAZIONE_PS.includes(ps[CLASSIFICAZIONE_OSPEDALE_JSON])); //prettier-ignore
        if (normoAffollatoChecked)
          filtratoGda.push(
            ...availableDatasource.filter((ps) => {
              const gda = parseInt(ps[AFFOLLAMENTO_PS_JSON][GDA_JSON]);
              return gda <= 60;
            })
          );
        if (pocoAffollatoChecked)
          filtratoGda.push(
            ...availableDatasource.filter((ps) => {
              const gda = parseInt(ps[AFFOLLAMENTO_PS_JSON][GDA_JSON]);
              return gda > 60 && gda <= 90;
            })
          );
        if (affollatoChecked)
          filtratoGda.push(
            ...availableDatasource.filter((ps) => {
              const gda = parseInt(ps[AFFOLLAMENTO_PS_JSON][GDA_JSON]);
              return gda > 90 && gda <= 150;
            })
          );

        if (sovraffollatoChecked)
          filtratoGda.push(
            ...availableDatasource.filter((ps) => {
              const gda = parseInt(ps[AFFOLLAMENTO_PS_JSON][GDA_JSON]);
              return gda > 150;
            })
          );
      } else filtratoGda = dataSource;

      if (genovaChecked || savonaChecked || imperiaChecked || speziaChecked) {
        if (genovaChecked) filtratoProvince.push(...filtratoGda.filter((ps) => ps[PROVINCIA_OSPEDALE_JSON] === PROVINCIA_GENOVA));
        if (savonaChecked) filtratoProvince.push(...filtratoGda.filter((ps) => ps[PROVINCIA_OSPEDALE_JSON] === PROVINCIA_SAVONA));
        if (imperiaChecked) filtratoProvince.push(...filtratoGda.filter((ps) => ps[PROVINCIA_OSPEDALE_JSON] === PROVINCIA_IMPERIA));
        if (speziaChecked) filtratoProvince.push(...filtratoGda.filter((ps) => ps[PROVINCIA_OSPEDALE_JSON] === PROVINCIA_LA_SPEZIA));
        filtrato = filtratoProvince;
      } else filtrato = filtratoGda;
      setElencoFiltrato(filtrato);
    } else setElencoFiltrato(dataSource);
  }, [
    elencoProntoSoccorso,
    searchValue,
    normoAffollatoChecked,
    pocoAffollatoChecked,
    affollatoChecked,
    sovraffollatoChecked,
    genovaChecked,
    savonaChecked,
    imperiaChecked,
    speziaChecked,
  ]);

  useEffect(() => {
    setElencoDatasource(elencoFiltrato.length > 0 ? elencoFiltrato : elencoProntoSoccorso);
  }, [elencoProntoSoccorso, elencoFiltrato]);

  return fetchEnded ? (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", paddingTop: "2em" }} className="elencoProntoSoccorso">
      <Box sx={{ display: "grid", padding: "0 2em", width: "100%" }}>
        <RicercaOspedale />
        <Box className="titoloPagina">
          <ElencoIcon />
          <Typography variant="h1">{getMessageString(intl, NAVBAR_ELENCO)}</Typography>
        </Box>
        {(searchValue !== "" ||
          pocoAffollatoChecked ||
          affollatoChecked ||
          sovraffollatoChecked ||
          genovaChecked ||
          savonaChecked ||
          imperiaChecked ||
          speziaChecked) &&
          elencoFiltrato.length === 0 && (
            <>
              <Box sx={{ display: "grid", justifyContent: "left", width: "100%", padding: "2em 1em" }}>
                <EmptyData />
              </Box>
              <Divider />
            </>
          )}
        {preferiti.length > 0 && (
          <Box className="preferiti">
            <Box className="title">
              <PreferitiIcon />
              <Typography variant="h2">{getMessageString(intl, PREFERITI_LABEL)}</Typography>
            </Box>
            <Box className="elencoOspedali">
              {elencoDatasource
                .filter((ps) => preferiti.includes(ps[ID_OSPEDALE_JSON]))
                .map((ps, index) => (
                  <ProntoSoccorso ospedale={ps} key={index} preferiti={preferiti} onChangePreferiti={(ids) => setPreferiti(ids)} />
                ))}
            </Box>
            <Divider />
          </Box>
        )}
        <>
          <Typography variant="h2" sx={{ height: 0 }}>
            <div style={{ display: "none" }}>Tutti gli ospedali</div>
          </Typography>
          <Box className="elencoOspedali">
            {elencoProntoSoccorso.length > 0 ? (
              elencoDatasource
                .filter((ps) => !preferiti.includes(ps[ID_OSPEDALE_JSON]))
                .map((ps, index) => <ProntoSoccorso ospedale={ps} key={index} preferiti={preferiti} onChangePreferiti={(ids) => setPreferiti(ids)} />)
            ) : (
              <EmptyData />
            )}
          </Box>
        </>
      </Box>
    </Box>
  ) : (
    <LoadingInfo />
  );
});
