import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Grid } from "@mui/material";
import { SwitchContent } from "./SwitchContent";
import { Notification } from "../components/notification/Notification";
import { Navbar } from "../components/Navbar";
import { SplashScreen } from "../components/SplashScreen";
import { LoadingRaw } from "../components/loading/LoadingRaw";
import { ConfigFailed } from "../components/ConfigFailed";
import { fetchConfig, fetchConfigEnded, getConfig, isFromSaluteSimplex } from "../modules/configuration/";
import { resetNotification } from "../modules/notification";
import { setLocale, getLocale, getMessages } from "../modules/language";
import { MobileHeader } from "../components/MobileHeader";

export const Index = () => {
  const dispatch = useDispatch();
  const config = useSelector(getConfig);
  const fetchEnded = useSelector(fetchConfigEnded);
  const locale = useSelector(getLocale);
  const messages = useSelector(getMessages);
  const fromSaluteSimplex = useSelector(isFromSaluteSimplex);
  const [waitingComplete, setWaitingComplete] = useState(false);
  const [showSplash, setShowSplash] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [mobileMode, setMobileMode] = useState(window.innerWidth < 769 ? true : false);

  useEffect(() => {
    const handleResize = () => setMobileMode(window.innerWidth < 769 ? true : false);
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!config && !fetchEnded) dispatch(fetchConfig());
    // eslint-disable-next-line
  }, [config, fetchEnded, locale, waitingComplete, messages]);

  useEffect(() => {
    if (config) {
      dispatch(resetNotification());
      if (!locale) dispatch(setLocale(config.defaultLanguage));
      if (messages && messages !== undefined && !waitingComplete) setWaitingComplete(true);
      if (!waitingComplete) {
        const timeout = setTimeout(() => {
          setLoadingError(false);
          setWaitingComplete(true);
        }, 1000);
        return () => clearTimeout(timeout);
      }
    } else {
      if (fetchEnded) {
        const timeout = setTimeout(() => {
          setLoadingError(true);
        }, 1000 * 5);
        return () => clearTimeout(timeout);
      }
    }
    // eslint-disable-next-line
  }, [config, fetchEnded, locale, waitingComplete, messages]);

  const retryLoading = () => {
    setLoadingError(false);
    dispatch(fetchConfig());
  };

  return !loadingError ? (
    waitingComplete ? (
      showSplash ? (
        <SplashScreen onTimeoutEnd={() => setShowSplash(false)} />
      ) : (
        <IntlProvider messages={messages} locale={locale.replace("_", "-")}>
          <Notification />
          <BrowserRouter>
            <MobileHeader />
            <Grid container spacing={2} sx={{ height: "100%" }}>
              <Grid item xs={9} className="switchContent" sx={{ top: mobileMode ? (fromSaluteSimplex ? "0" : "60px") : "0" }}>
                <SwitchContent />
              </Grid>
              <Grid item xs={3} className="navbar">
                <Navbar />
              </Grid>
            </Grid>
          </BrowserRouter>
        </IntlProvider>
      )
    ) : (
      <LoadingRaw />
    )
  ) : (
    <ConfigFailed onRetry={retryLoading} />
  );
};
