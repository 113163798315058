import "./LogoLD.scss";

export const LogoLD = () => (
  <svg id="Livello_2_ld" data-name="Livello 2" viewBox="0 0 212.84 120.39" className="logoLD">
    <g id="Livello_1-2_ld" data-name="Livello 1">
      <path
        className="cls-1"
        d="m0,105.75v-44.04h16.36c4.67,0,8.3.36,10.88,1.07,2.58.71,4.94,1.89,7.11,3.53,2.78,2.12,4.89,4.65,6.33,7.6,1.43,2.95,2.15,6.22,2.15,9.81,0,3.93-.87,7.49-2.62,10.69-1.74,3.2-4.27,5.87-7.57,8.01-1.87,1.2-3.94,2.05-6.2,2.57-2.26.51-5.62.77-10.07.77H0Zm10.91-35.13v26.22h8.13c3.7,0,6.72-1.22,9.05-3.67,2.34-2.44,3.51-5.6,3.51-9.46s-1.17-7.01-3.51-9.44c-2.34-2.43-5.36-3.65-9.05-3.65h-8.13Z"
      />
      <path
        className="cls-1"
        d="m47.42,66.25c0-1.41.48-2.6,1.45-3.57.97-.98,2.15-1.46,3.54-1.46s2.6.48,3.55,1.45c.96.97,1.43,2.16,1.43,3.59s-.48,2.59-1.43,3.56c-.96.97-2.14,1.45-3.55,1.45s-2.57-.49-3.54-1.46c-.97-.98-1.45-2.16-1.45-3.54m.34,9.66h9.32v29.84h-9.32v-29.84Z"
      />
      <path
        className="cls-1"
        d="m69.75,95.96c-2.62-.78-4.57-1.96-5.84-3.56-1.28-1.6-1.92-3.63-1.92-6.11,0-3.38,1.32-5.96,3.96-7.73,2.64-1.77,6.46-2.66,11.47-2.66h15.02v6.23h-4.18c.48.76.83,1.55,1.06,2.38.23.83.34,1.74.34,2.72,0,2.69-.78,4.83-2.35,6.44-1.57,1.61-3.93,2.68-7.09,3.23-.75.15-1.74.27-2.96.38-2.45.23-3.68.9-3.68,2.01s1.68,1.77,5.05,2.11c1.27.13,2.3.24,3.09.35,3.16.42,5.56,1.38,7.21,2.86,1.65,1.49,2.48,3.46,2.48,5.92,0,3.23-1.33,5.68-3.99,7.35-2.66,1.67-6.59,2.5-11.78,2.5-4.78,0-8.52-.78-11.22-2.33-2.7-1.55-4.05-3.7-4.05-6.45,0-1.74.67-3.24,2.01-4.5,1.34-1.26,3.29-2.22,5.84-2.9-1.14-.36-2-.84-2.57-1.45-.57-.61-.86-1.35-.86-2.24,0-1.15.41-2.11,1.23-2.86.82-.76,2.06-1.32,3.72-1.7m-.69,15.14c0,1.22.58,2.14,1.73,2.75,1.15.62,2.87.93,5.16.93s4.02-.31,5.22-.93c1.19-.62,1.79-1.52,1.79-2.69,0-1.09-.6-1.95-1.79-2.57-1.19-.62-2.87-.93-5.03-.93s-4.01.3-5.24.9c-1.23.6-1.84,1.44-1.84,2.53Zm6.89-29.47c-1.6,0-2.88.47-3.85,1.4-.97.93-1.45,2.17-1.45,3.7s.48,2.77,1.45,3.7c.97.93,2.25,1.4,3.85,1.4s2.88-.47,3.85-1.4c.97-.93,1.45-2.17,1.45-3.7s-.48-2.76-1.45-3.7c-.97-.93-2.25-1.4-3.85-1.4Z"
      />
      <path
        className="cls-1"
        d="m95.72,66.25c0-1.41.48-2.6,1.45-3.57.97-.98,2.14-1.46,3.54-1.46s2.6.48,3.55,1.45c.96.97,1.43,2.16,1.43,3.59s-.48,2.59-1.43,3.56c-.96.97-2.14,1.45-3.55,1.45s-2.57-.49-3.54-1.46c-.97-.98-1.45-2.16-1.45-3.54m.34,9.66h9.32v29.84h-9.32v-29.84Z"
      />
      <path
        className="cls-1"
        d="m112.78,82.43h-4.24v-2.01l12.06-13.22h1.4v8.72h10.1v6.52h-10.1v11.02c0,2.29.32,3.81.97,4.58.64.77,1.82,1.15,3.52,1.15.83,0,1.71-.12,2.63-.36.92-.24,1.92-.61,2.98-1.12l.06,7.02c-1.5.57-2.99.99-4.49,1.26-1.5.27-2.98.41-4.46.41-3.76,0-6.44-.98-8.04-2.93-1.6-1.95-2.4-5.31-2.4-10.07v-10.96Z"
      />
      <path
        className="cls-1"
        d="m152.16,102.98c-1.39,1.13-2.84,1.97-4.35,2.52-1.51.55-3.12.82-4.85.82-2.85,0-5-.7-6.45-2.09-1.45-1.4-2.18-3.47-2.18-6.22,0-2.39,1.08-4.36,3.23-5.9,2.15-1.54,6.86-3.37,14.13-5.49.02-.1.04-.22.05-.35.01-.13.02-.29.02-.5,0-1.43-.47-2.48-1.42-3.16-.95-.68-2.4-1.02-4.38-1.02-1.66,0-3.37.41-5.11,1.23-1.75.82-3.45,2-5.11,3.56l.12-7.43c1.79-1.22,3.76-2.12,5.91-2.72,2.15-.6,4.57-.9,7.25-.9,4.26,0,7.34.83,9.26,2.49,1.91,1.66,2.87,4.31,2.87,7.96v11.87c0,.69.09,1.18.28,1.45.19.27.5.41.93.41.39,0,.81-.05,1.23-.16.43-.1.99-.3,1.7-.6v5.23c-1.68.82-3.17,1.42-4.47,1.79-1.3.38-2.53.57-3.69.57-1.48,0-2.63-.28-3.47-.83-.84-.56-1.34-1.39-1.48-2.5m-.47-4.38v-7.3c-3.18,1.13-5.3,2.12-6.37,2.97-1.07.85-1.61,1.86-1.61,3.04,0,.97.29,1.73.87,2.3.58.57,1.38.85,2.4.85.69,0,1.42-.16,2.21-.47.79-.31,1.62-.78,2.49-1.39Z"
      />
      <rect className="cls-1" x="168.51" y="61.71" width="9.32" height="44.04" />
      <path
        className="cls-1"
        d="m212.15,102.48c-2.02,1.32-4.14,2.3-6.37,2.94-2.23.64-4.62.96-7.15.96-5.09,0-9.08-1.41-11.97-4.22-2.89-2.81-4.33-6.7-4.33-11.68,0-4.6,1.41-8.27,4.24-11.03,2.82-2.76,6.59-4.14,11.28-4.14s8.29,1.5,10.97,4.49c2.68,2.99,4.02,7.11,4.02,12.36v.32h-20.91v.13c0,2.37.8,4.25,2.4,5.62,1.6,1.37,3.79,2.06,6.58,2.06,1.87,0,3.74-.34,5.61-1.01,1.87-.67,3.75-1.67,5.64-2.99v6.2Zm-20.16-14.58l11.47-.03c-.17-1.93-.74-3.44-1.73-4.53-.99-1.09-2.26-1.64-3.82-1.64s-2.92.55-3.97,1.64c-1.05,1.09-1.7,2.61-1.95,4.57Z"
      />
      <polygon className="cls-2" points="0 44.54 0 .5 10.91 .5 10.91 36.05 29.7 36.05 29.7 44.54 0 44.54" />
      <path
        className="cls-2"
        d="m36.63,5.04c0-1.41.48-2.6,1.45-3.57.97-.98,2.14-1.46,3.54-1.46s2.6.48,3.55,1.45c.96.97,1.43,2.16,1.43,3.59s-.48,2.59-1.43,3.56c-.96.97-2.14,1.45-3.55,1.45s-2.57-.49-3.54-1.46c-.97-.98-1.45-2.16-1.45-3.54m.34,9.67h9.32v29.84h-9.32V14.7Z"
      />
      <path
        className="cls-2"
        d="m62.85,34.75c-2.62-.78-4.57-1.96-5.84-3.56-1.28-1.6-1.92-3.63-1.92-6.11,0-3.38,1.32-5.96,3.96-7.73,2.64-1.77,6.46-2.66,11.47-2.66h15.02v6.23h-4.18c.48.76.83,1.55,1.06,2.38.23.83.34,1.74.34,2.72,0,2.69-.78,4.83-2.35,6.44-1.57,1.61-3.93,2.68-7.09,3.23-.75.15-1.74.27-2.96.38-2.45.23-3.68.9-3.68,2.01s1.68,1.77,5.05,2.11c1.27.13,2.3.24,3.09.35,3.16.42,5.56,1.37,7.21,2.86,1.65,1.49,2.48,3.46,2.48,5.92,0,3.23-1.33,5.68-3.99,7.35-2.66,1.67-6.59,2.5-11.78,2.5-4.78,0-8.52-.78-11.22-2.33-2.7-1.55-4.05-3.7-4.05-6.45,0-1.74.67-3.24,2.01-4.5,1.34-1.26,3.29-2.22,5.84-2.9-1.14-.36-2-.84-2.57-1.45-.57-.61-.86-1.35-.86-2.24,0-1.15.41-2.11,1.23-2.86.82-.76,2.06-1.32,3.72-1.7m-.69,15.14c0,1.22.58,2.14,1.73,2.75,1.15.62,2.87.93,5.16.93s4.03-.31,5.22-.93c1.19-.62,1.79-1.52,1.79-2.69,0-1.09-.6-1.95-1.79-2.57-1.19-.62-2.87-.93-5.03-.93s-4.01.3-5.24.9c-1.23.6-1.84,1.44-1.84,2.53Zm6.89-29.47c-1.6,0-2.88.47-3.85,1.4-.97.93-1.45,2.17-1.45,3.7s.48,2.77,1.45,3.7c.97.93,2.25,1.4,3.85,1.4s2.88-.47,3.85-1.4c.97-.93,1.45-2.17,1.45-3.7s-.48-2.76-1.45-3.7c-.97-.93-2.25-1.4-3.85-1.4Z"
      />
      <path
        className="cls-2"
        d="m113.04,40.8c-1.48,1.51-3,2.61-4.58,3.29-1.58.68-3.4,1.02-5.45,1.02-3.24,0-5.73-.99-7.48-2.97-1.75-1.98-2.62-4.83-2.62-8.55V14.7h9.32v17.6c0,2.12.34,3.67,1.01,4.64.68.98,1.73,1.46,3.16,1.46,1.29,0,2.5-.39,3.63-1.18,1.13-.79,2.13-1.96,3.01-3.51V14.7h9.32v29.84h-9.32v-3.75Z"
      />
      <path
        className="cls-2"
        d="m132.31,44.54V14.7h9.32v8c1.58-3.02,3.1-5.19,4.55-6.5,1.45-1.31,3.04-1.97,4.77-1.97.85,0,1.75.18,2.7.53.95.36,2,.91,3.16,1.67l-2.49,8.56c-.94-.73-1.8-1.27-2.59-1.61-.79-.34-1.57-.5-2.34-.5-1.77,0-3.35.79-4.75,2.36-1.4,1.57-2.41,3.73-3.01,6.45v12.84h-9.32Z"
      />
      <path
        className="cls-2"
        d="m162.56,5.04c0-1.41.48-2.6,1.45-3.57.97-.98,2.14-1.46,3.54-1.46s2.6.48,3.55,1.45c.96.97,1.43,2.16,1.43,3.59s-.48,2.59-1.43,3.56c-.96.97-2.14,1.45-3.55,1.45s-2.57-.49-3.54-1.46c-.97-.98-1.45-2.16-1.45-3.54m.34,9.67h9.32v29.84h-9.32V14.7Z"
      />
      <path
        className="cls-2"
        d="m198.19,41.77c-1.39,1.13-2.84,1.97-4.35,2.52-1.51.55-3.12.82-4.85.82-2.85,0-5-.7-6.45-2.09-1.45-1.4-2.18-3.47-2.18-6.22,0-2.39,1.08-4.36,3.23-5.9,2.15-1.54,6.86-3.37,14.13-5.49.02-.1.04-.22.05-.35.01-.13.02-.29.02-.5,0-1.43-.47-2.48-1.42-3.16-.95-.68-2.4-1.02-4.38-1.02-1.66,0-3.37.41-5.11,1.23-1.75.82-3.45,2-5.11,3.56l.12-7.43c1.79-1.22,3.76-2.12,5.91-2.72,2.15-.6,4.57-.9,7.25-.9,4.26,0,7.34.83,9.26,2.49,1.91,1.66,2.87,4.31,2.87,7.97v11.87c0,.69.09,1.18.28,1.45.19.27.5.41.93.41.39,0,.81-.05,1.23-.16.43-.1.99-.3,1.7-.6v5.23c-1.68.82-3.17,1.42-4.47,1.79-1.3.38-2.53.57-3.69.57-1.48,0-2.63-.28-3.47-.83-.84-.56-1.34-1.39-1.48-2.5m-.47-4.38v-7.3c-3.18,1.13-5.3,2.12-6.37,2.97-1.07.85-1.61,1.86-1.61,3.04,0,.97.29,1.73.87,2.3.58.57,1.38.85,2.4.85.69,0,1.42-.16,2.21-.47.79-.31,1.62-.78,2.49-1.38Z"
      />
    </g>
  </svg>
);
