import "./Ospedale.scss";

export const Ospedale = () => (
  <svg id="uuid-a9af9ecd-5353-4c54-bb2d-78a049078fff_ospedale" viewBox="0 0 39.4 43.32" className="ospedaleIcon">
    <defs>
      <linearGradient id="uuid-ec3a6fbb-ba06-48c9-97bc-0f63cc72b0a2_ospedale" x1=".82" y1="9.1" x2="36.29" y2="40.8" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#4b78c6" />
        <stop offset="1" stopColor="#2b4281" />
      </linearGradient>
      <linearGradient
        id="uuid-565a96cd-a1c0-4308-80c4-ad25e8ed7543_ospedale"
        x1="1.67"
        y1="13.49"
        x2="35.54"
        y2="43.76"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
      <clipPath id="uuid-199db539-6d51-446c-af09-889d2f40cb9e_ospedale">
        <path
          className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5"
          d="m27.87,15.98h-1.07c-.64,0-1.17.52-1.17,1.17v7.85c0,.46-.38.84-.84.84h-9.39c-.46,0-.84-.38-.84-.84v-7.85c0-.64-.52-1.17-1.17-1.17h-1.04c-.64,0-1.17.52-1.17,1.17v21.55c0,.64.52,1.17,1.17,1.17h1.04c.64,0,1.17-.52,1.17-1.17v-9.04c0-.46.38-.84.84-.84h9.39c.46,0,.84.38.84.84v9.04c0,.64.52,1.17,1.17,1.17h1.07c.64,0,1.17-.52,1.17-1.17v-21.55c0-.64-.52-1.17-1.17-1.17Z"
        />
      </clipPath>
    </defs>
    <g id="uuid-0e947abc-8ead-407f-beb0-a7d5ff84fad6_ospedale">
      <path
        className="uuid-7aea5abd-5af1-4e5d-9aae-c9d9b9adc754"
        d="m35.96,40.35H3.44c-1.9,0-3.44-1.54-3.44-3.44V15.09c0-1.12.55-2.17,1.46-2.82L18.08.62c1.2-.84,2.81-.83,4,.04l15.91,11.61c.89.65,1.41,1.68,1.41,2.78v21.86c0,1.9-1.54,3.44-3.44,3.44Z"
      />
      <path
        className="uuid-8681750d-ea96-4a53-8401-91ce47cb5d86"
        d="m35.22,43.32H4.17c-1.81,0-3.28-1.47-3.28-3.28v-20.83c0-1.07.52-2.07,1.4-2.69l15.86-11.12c1.15-.81,2.69-.79,3.82.04l15.19,11.08c.85.62,1.35,1.6,1.35,2.65v20.87c0,1.81-1.47,3.28-3.28,3.28Z"
      />
      <g>
        <g>
          <path
            className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5"
            d="m27.87,15.98h-1.07c-.64,0-1.17.52-1.17,1.17v7.85c0,.46-.38.84-.84.84h-9.39c-.46,0-.84-.38-.84-.84v-7.85c0-.64-.52-1.17-1.17-1.17h-1.04c-.64,0-1.17.52-1.17,1.17v21.55c0,.64.52,1.17,1.17,1.17h1.04c.64,0,1.17-.52,1.17-1.17v-9.04c0-.46.38-.84.84-.84h9.39c.46,0,.84.38.84.84v9.04c0,.64.52,1.17,1.17,1.17h1.07c.64,0,1.17-.52,1.17-1.17v-21.55c0-.64-.52-1.17-1.17-1.17Z"
          />
          <g className="uuid-9321c4a5-65c1-4400-9499-6ca484f13dfb">
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="11.19" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="11.47" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="11.74" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="12.02" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="12.29" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="12.57" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="12.84" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="13.11" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="13.39" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="13.66" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="13.94" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="14.21" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="14.49" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="14.76" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="15.04" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="15.31" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="15.58" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="15.86" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="16.13" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="16.41" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="16.68" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="16.96" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="17.23" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="17.51" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="17.78" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="18.05" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="18.33" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="18.6" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="18.88" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="19.15" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="19.43" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="19.7" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="19.98" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="20.25" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="20.52" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="20.8" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="21.07" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="21.35" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="21.62" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="21.9" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="22.17" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="22.45" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="22.72" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="22.99" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="23.27" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="23.54" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="23.82" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="24.09" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="24.37" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="24.64" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="24.92" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="25.19" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="25.47" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="25.74" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="26.01" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="26.29" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="26.56" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="26.84" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="27.11" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="27.39" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="27.66" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="27.94" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="28.21" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="28.48" y="15.98" width=".27" height="23.89" />
            <rect className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5" x="28.76" y="15.98" width=".27" height="23.89" />
          </g>
        </g>
        <path
          className="uuid-2da825e6-e9d4-4ed6-aa79-05a6446fa8c5"
          d="m27.87,39.95h-1.07c-.69,0-1.25-.56-1.25-1.25v-9.04c0-.42-.34-.75-.75-.75h-9.39c-.42,0-.75.34-.75.75v9.04c0,.69-.56,1.25-1.25,1.25h-1.04c-.69,0-1.25-.56-1.25-1.25v-21.55c0-.69.56-1.25,1.25-1.25h1.04c.69,0,1.25.56,1.25,1.25v7.85c0,.42.34.75.75.75h9.39c.42,0,.75-.34.75-.75v-7.85c0-.69.56-1.25,1.25-1.25h1.07c.69,0,1.25.56,1.25,1.25v21.55c0,.69-.56,1.25-1.25,1.25Zm-12.46-11.21h9.39c.51,0,.92.41.92.92v9.04c0,.6.49,1.08,1.08,1.08h1.07c.6,0,1.08-.49,1.08-1.08v-21.55c0-.6-.49-1.08-1.08-1.08h-1.07c-.6,0-1.08.49-1.08,1.08v7.85c0,.51-.41.92-.92.92h-9.39c-.51,0-.92-.41-.92-.92v-7.85c0-.6-.49-1.08-1.08-1.08h-1.04c-.6,0-1.08.49-1.08,1.08v21.55c0,.6.49,1.08,1.08,1.08h1.04c.6,0,1.08-.49,1.08-1.08v-9.04c0-.51.41-.92.92-.92Z"
        />
      </g>
    </g>
  </svg>
);
