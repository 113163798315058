import "./GradoAffollamento.scss";

export const GradoAffollamento = () => (
  <svg id="uuid-c85d6988-0e2a-433c-a235-c8ccef67fb88_affollamento" viewBox="0 0 47.46 28.45" className="gradoAffollamentoIcon">
    <defs>
      <linearGradient
        id="uuid-65641fa3-2ae4-458e-8828-fe319b8efe80_affollamento"
        x1="25.21"
        y1="11.32"
        x2="47.46"
        y2="11.32"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4b78c6" />
        <stop offset="1" stopColor="#2b4281" />
      </linearGradient>
      <linearGradient
        id="uuid-4e5d2e4d-4e27-448f-8493-0ce45097d3cd_affollamento"
        x1="0"
        x2="22.26"
        xlinkHref="#uuid-65641fa3-2ae4-458e-8828-fe319b8efe80_affollamento"
      />
      <linearGradient
        id="uuid-10a3538f-403d-49c9-801a-2d403dea0bb2_affollamento"
        x1="12.6"
        y1="17.13"
        x2="34.86"
        y2="17.13"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
    </defs>
    <g id="uuid-49c3b0de-72d0-4539-9433-2fc00ea4e29e_affollamento">
      <path
        className="uuid-ec6a3f76-ffeb-4544-a767-1464b56dd5b1"
        d="m47.46,19.27c-.02-3.78-2.15-6.42-6.33-7.85,1.16-1.21,1.88-2.86,1.88-4.68,0-3.72-2.99-6.74-6.67-6.74s-6.67,3.02-6.67,6.74c0,1.82.72,3.47,1.88,4.68-4.2,1.44-6.33,4.08-6.33,7.86,0,.24,0,.46.03.65l.09.9.87.27c3.51,1.09,6.53,1.54,10.45,1.54,3.53,0,6.11-.41,9.85-1.58l.89-.28.07-.93c.01-.19.02-.37.01-.59Z"
      />
      <path
        className="uuid-c933d360-8ad0-4c06-81e0-1e9b14384ae9"
        d="m22.26,19.27c-.02-3.78-2.15-6.42-6.33-7.85,1.16-1.21,1.88-2.86,1.88-4.68,0-3.72-2.99-6.74-6.67-6.74s-6.67,3.02-6.67,6.74c0,1.82.72,3.47,1.88,4.68C2.13,12.86,0,15.51,0,19.28c0,.24,0,.46.03.65l.09.9.87.27c3.51,1.09,6.53,1.54,10.45,1.54,3.53,0,6.11-.41,9.85-1.58l.89-.28.07-.93c.01-.19.02-.37.01-.59Z"
      />
      <path
        className="uuid-148e5216-a9b9-4eeb-9d73-29259491d898"
        d="m34.86,25.08c-.02-3.78-2.15-6.42-6.33-7.85,1.16-1.21,1.88-2.86,1.88-4.68,0-3.72-2.99-6.74-6.67-6.74s-6.67,3.02-6.67,6.74c0,1.82.72,3.47,1.88,4.68-4.2,1.44-6.33,4.08-6.33,7.86,0,.24,0,.46.03.65l.09.9.87.27c3.51,1.1,6.53,1.54,10.45,1.54,3.53,0,6.11-.41,9.85-1.58l.89-.28.07-.93c.01-.19.02-.37.01-.59Z"
      />
    </g>
  </svg>
);
