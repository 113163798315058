import { Box, Grid, Typography } from "@mui/material";
import { injectIntl } from "react-intl";
import {
  GRADO_AFFOLLAMENTO,
  AFFLUENZA,
  AFFLUENZA_ATTESA,
  AFFLUENZA_IN_CURA,
  PAZIENTI_ATTESA,
  PAZIENTI_IN_CURA,
  LAST_UPDATE,
  LAST_UPDATE_GIORNO,
  LAST_UPDATE_GIORNI,
  LAST_UPDATE_ORA,
  LAST_UPDATE_ORE,
  LAST_UPDATE_MINUTO,
  LAST_UPDATE_MINUTI,
  LAST_UPDATE_SECONDI,
  LAST_UPDATE_AGO,
  NON_DISPONIBILE_TITLE,
  APERTO_TITLE,
} from "../../modules/language";
import {
  CLASSIFICAZIONE_OSPEDALE_JSON,
  AFFOLLAMENTO_PS_JSON,
  NUM_ATTESA_ARANCIONE_JSON,
  NUM_ATTESA_AZZURRO_JSON,
  NUM_ATTESA_BIANCO_JSON,
  NUM_ATTESA_ROSSO_JSON,
  NUM_ATTESA_TOT_JSON,
  NUM_ATTESA_VERDE_JSON,
  NUM_TRATTATO_ARANCIONE_JSON,
  NUM_TRATTATO_AZZURRO_JSON,
  NUM_TRATTATO_BIANCO_JSON,
  NUM_TRATTATO_ROSSO_JSON,
  NUM_TRATTATO_TOT_JSON,
  NUM_TRATTATO_VERDE_JSON,
  LAST_UPDATE_JSON,
  GIORNI,
  ORE,
  MINUTI,
  SECONDI,
  CLASSIFICAZIONE_PS,
  NOTE_OSPEDALE_JSON,
} from "../../modules/prontoSoccorso";
import { getMessageString } from "../../utility";
import { DatoNonDisponibileIcon } from "../../styles/images";
import { ProgressBar } from "../ProgressBar";

export const AffluenzaProntoSoccorso = injectIntl(({ intl, data }) => {
  const infoAffollamento = data[AFFOLLAMENTO_PS_JSON];

  const renderLastUpdate = () => {
    const lastUpdate = infoAffollamento[LAST_UPDATE_JSON];
    const giorni = parseInt(lastUpdate[GIORNI]);
    const ore = parseInt(lastUpdate[ORE]);
    const minuti = parseInt(lastUpdate[MINUTI]);
    const secondi = parseInt(lastUpdate[SECONDI]);
    return (
      <>
        {giorni > 0 && giorni + " " + getMessageString(intl, giorni > 1 ? LAST_UPDATE_GIORNI : LAST_UPDATE_GIORNO)}
        {ore > 0 && " " + ore + " " + getMessageString(intl, ore > 1 ? LAST_UPDATE_ORE : LAST_UPDATE_ORA)}
        {minuti > 0 && " " + minuti + " " + getMessageString(intl, minuti > 1 ? LAST_UPDATE_MINUTI : LAST_UPDATE_MINUTO)}
        {minuti === 0 && secondi > 0 && " " + secondi + " " + getMessageString(intl, LAST_UPDATE_SECONDI)}
        {getMessageString(intl, LAST_UPDATE_AGO)}
      </>
    );
  };

  return (
    <Box className="affluenzaDettaglio">
      <Grid container spacing={2}>
        {CLASSIFICAZIONE_PS.includes(data[CLASSIFICAZIONE_OSPEDALE_JSON]) && (
          <Grid item xs={12}>
            <Typography variant="h3">{getMessageString(intl, GRADO_AFFOLLAMENTO)}</Typography>
          </Grid>
        )}
        {CLASSIFICAZIONE_PS.includes(data[CLASSIFICAZIONE_OSPEDALE_JSON]) ? (
          infoAffollamento ? (
            <>
              <Grid item xs={12} sx={{ padding: "0 !important" }}>
                <ProgressBar ospedale={data} />
              </Grid>
              <Grid item xs={12} className="affluenzaTitle">
                <Typography variant="h3">{getMessageString(intl, AFFLUENZA)}</Typography>
              </Grid>
              <Grid item xs={12} className="affluenza">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h4">{getMessageString(intl, AFFLUENZA_ATTESA)}</Typography>
                      </Grid>
                      <Grid item xs={12} className="pazientiTotali">
                        <Typography variant="caption">{infoAffollamento[NUM_ATTESA_TOT_JSON]}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h4">{getMessageString(intl, AFFLUENZA_IN_CURA)}</Typography>
                      </Grid>
                      <Grid item xs={12} className="pazientiTotali">
                        <Typography variant="caption">{infoAffollamento[NUM_TRATTATO_TOT_JSON]}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box className="dettaglioColoriPazienti">
                  <Typography variant="h4">{getMessageString(intl, PAZIENTI_ATTESA)}</Typography>
                  <Typography variant="caption">{infoAffollamento[NUM_ATTESA_TOT_JSON]}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={2} className="codiceEmergenza codiceRosso">
                    {infoAffollamento[NUM_ATTESA_ROSSO_JSON]}
                  </Grid>
                  <Grid item xs={2} className="codiceEmergenza codiceArancione">
                    {infoAffollamento[NUM_ATTESA_ARANCIONE_JSON]}
                  </Grid>
                  <Grid item xs={2} className="codiceEmergenza codiceAzzurro">
                    {infoAffollamento[NUM_ATTESA_AZZURRO_JSON]}
                  </Grid>
                  <Grid item xs={2} className="codiceEmergenza codiceVerde">
                    {infoAffollamento[NUM_ATTESA_VERDE_JSON]}
                  </Grid>
                  <Grid item xs={2} className="codiceEmergenza codiceBianco">
                    {infoAffollamento[NUM_ATTESA_BIANCO_JSON]}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box className="dettaglioColoriPazienti">
                  <Typography variant="h4">{getMessageString(intl, PAZIENTI_IN_CURA)}</Typography>
                  <Typography variant="caption">{infoAffollamento[NUM_TRATTATO_TOT_JSON]}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={2} className="codiceEmergenza codiceRosso">
                    {infoAffollamento[NUM_TRATTATO_ROSSO_JSON]}
                  </Grid>
                  <Grid item xs={2} className="codiceEmergenza codiceArancione">
                    {infoAffollamento[NUM_TRATTATO_ARANCIONE_JSON]}
                  </Grid>
                  <Grid item xs={2} className="codiceEmergenza codiceAzzurro">
                    {infoAffollamento[NUM_TRATTATO_AZZURRO_JSON]}
                  </Grid>
                  <Grid item xs={2} className="codiceEmergenza codiceVerde">
                    {infoAffollamento[NUM_TRATTATO_VERDE_JSON]}
                  </Grid>
                  <Grid item xs={2} className="codiceEmergenza codiceBianco">
                    {infoAffollamento[NUM_TRATTATO_BIANCO_JSON]}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="lastUpdate">
                <Typography variant="caption">
                  {getMessageString(intl, LAST_UPDATE)}
                  {renderLastUpdate()}
                </Typography>
              </Grid>
            </>
          ) : (
            <Box className="datoNonDisponibile">
              <DatoNonDisponibileIcon />
              <Typography variant="caption">{getMessageString(intl, NON_DISPONIBILE_TITLE)}</Typography>
            </Box>
          )
        ) : (
          <Box className="datoNonRilevato">
            <Typography variant="caption" sx={{ textAlign: "center" }}>
              {getMessageString(intl, APERTO_TITLE)}
            </Typography>
            {data[NOTE_OSPEDALE_JSON] && data[NOTE_OSPEDALE_JSON] !== undefined && (
              <Typography className="noteOspedale" sx={{ textAlign: "center" }}>
                {data[NOTE_OSPEDALE_JSON]}
              </Typography>
            )}
          </Box>
        )}
      </Grid>
    </Box>
  );
});
