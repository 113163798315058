export const NOME = "nome";
export const INDIRIZZO = "indirizzo";
export const CIVICO = "civico";
export const CAP = "cap";
export const COMUNE = "comune";
export const PROVINCIA = "provincia";
export const CLASSIFICAZIONE = "classificazione";
export const AREA = "area";
export const ENTE = "ente";
export const NOTE = "note";
export const AFFOLLAMENTO = "affollamento";
export const AFFLUENZA = "affluenza";
export const IN_ATTESA = "inAttesa";
export const CODICE_BIANCO = "bianco";
export const CODICE_VERDE = "verde";
export const CODICE_AZZURRO = "azzurro";
export const CODICE_ARANCIONE = "arancione";
export const CODICE_ROSSO = "rosso";
export const TRATTATI = "trattati";
export const PAZIENTI_TOTALI = "totale,";
export const LAST_UPDATE = "ultimoAggiornamento";
// Filtri
export const NOME_PS = "nomePs";
export const AFFOLLAMENTO_NORMO_AFFOLLATO = "normale";
export const AFFOLLAMENTO_POCO_AFFOLLATO = "poco_affollato";
export const AFFOLLAMENTO_AFFOLLATO = "affollato";
export const AFFOLLAMENTO_SOVRAFFOLLATO = "sovraffollato";
export const GENOVA = "Genova";
export const PROVINCIA_GENOVA = "GE";
export const SAVONA = "Savona";
export const PROVINCIA_SAVONA = "SV";
export const IMPERIA = "Imperia";
export const PROVINCIA_IMPERIA = "IM";
export const LA_SPEZIA = "La Spezia";
export const PROVINCIA_LA_SPEZIA = "SP";
// JSON ospedali
export const SORT_OSPEDALE_API = "sorterPosition";
export const ID_OSPEDALE_API = "codOsp";
export const NOME_COMPLETO_OSPEDALE_API = "descOsp";
export const NOME_BREVE_OSPEDALE_API = "breveOsp";
export const AREA_OSPEDALE_API = "area";
export const ASL_ENTE_API = "descAzi";
export const CLASSIFICAZIONE_OSPEDALE_API = "classificazione";
export const DETTAGLIO_CLASSIFICAZIONE_OSPEDALE_API = "dettaglioClassificazione";
export const NOTE_OSPEDALE_API = "note";
export const INDIRIZZO_OSPEDALE_API = "indirizzo";
export const LATITUDINE_OSPEDALE_API = "latitudine";
export const LONGITUDINE_OSPEDALE_API = "longitudine";
export const CIVICO_OSPEDALE_API = "civico";
export const CAP_OSPEDALE_API = "cap";
export const COMUNE_OSPEDALE_API = "comune";
export const PROVINCIA_OSPEDALE_API = "provincia";
export const LOCALITA_API = "localita";
export const ORARIO_APERTURA_API = "open";
export const ORARIO_CHIUSURA_API = "close";
export const IS_OSPEDALE_CLOSE_API = "nowClosed";
export const AFFOLLAMENTO_PS_API = "prontoSoccorsoAffollamento";
export const GDA_API_FALLBACK = "indGda";
export const GDA_API = "indGdaRl";
export const NUM_ATTESA_BIANCO_API = "numAttBia";
export const NUM_ATTESA_VERDE_API = "numAttVer";
export const NUM_ATTESA_AZZURRO_API = "numAttAzz";
export const NUM_ATTESA_ARANCIONE_API = "numAttAra";
export const NUM_ATTESA_ROSSO_API = "numAttRos";
export const NUM_TRATTATO_BIANCO_API = "numTraBia";
export const NUM_TRATTATO_VERDE_API = "numTraVer";
export const NUM_TRATTATO_AZZURRO_API = "numTraAzz";
export const NUM_TRATTATO_ARANCIONE_API = "numTraAra";
export const NUM_TRATTATO_ROSSO_API = "numTraRos";
export const LAST_UPDATE_API = "dataEla";
// Mapping JSON ospedali
export const SORT_OSPEDALE_JSON = "sortPos";
export const ID_OSPEDALE_JSON = "id";
export const NOME_COMPLETO_OSPEDALE_JSON = "nomeCompleto";
export const NOME_BREVE_OSPEDALE_JSON = "nomeBreve";
export const AREA_OSPEDALE_JSON = "area";
export const ASL_ENTE_JSON = "asl";
export const CLASSIFICAZIONE_OSPEDALE_JSON = "classificazione";
export const DETTAGLIO_CLASSIFICAZIONE_OSPEDALE_JSON = "dettaglioClassificazione";
export const NOTE_OSPEDALE_JSON = "note";
export const INDIRIZZO_OSPEDALE_JSON = "indirizzo";
export const LATITUDINE_OSPEDALE_JSON = "latitudine";
export const LONGITUDINE_OSPEDALE_JSON = "longitudine";
export const DISTANZA_OSPEDALE_JSON = "distanza";
export const CIVICO_OSPEDALE_JSON = "civico";
export const CAP_OSPEDALE_JSON = "cap";
export const COMUNE_OSPEDALE_JSON = "comune";
export const PROVINCIA_OSPEDALE_JSON = "provincia";
export const LOCALITA_JSON = "localita";
export const ORARIO_APERTURA_JSON = "orarioApertura";
export const ORARIO_CHIUSURA_JSON = "orarioChiusura";
export const IS_OSPEDALE_CLOSE_JSON = "isClosed";
export const AFFOLLAMENTO_PS_JSON = "affollamento";
export const GDA_JSON = "gda";
export const GDA_PROGRESS_JSON = "gdaProgress";
export const NUM_ATTESA_TOT_JSON = "attesaTotali";
export const NUM_TRATTATO_TOT_JSON = "trattatoTotali";
export const NUM_ATTESA_BIANCO_JSON = "attesaBianco";
export const NUM_ATTESA_VERDE_JSON = "attesaVerde";
export const NUM_ATTESA_AZZURRO_JSON = "attesaAzzurro";
export const NUM_ATTESA_ARANCIONE_JSON = "attesaArancione";
export const NUM_ATTESA_ROSSO_JSON = "attesaRosso";
export const NUM_TRATTATO_BIANCO_JSON = "trattatoBianco";
export const NUM_TRATTATO_VERDE_JSON = "trattatoVerde";
export const NUM_TRATTATO_AZZURRO_JSON = "trattatoAzzurro";
export const NUM_TRATTATO_ARANCIONE_JSON = "trattatoArancione";
export const NUM_TRATTATO_ROSSO_JSON = "trattatoRosso";
export const LAST_UPDATE_JSON = "lastUpdate";
export const GIORNI = "GG";
export const ORE = "HH";
export const MINUTI = "MM";
export const SECONDI = "SS";
// Storage
export const PREFERITI = "preferiti";
export const PREV_LOCATION = "prevLocation";
// Etichette di classificazione indicanti i ps
export const CLASSIFICAZIONE_PS = ["PS", "DEA I", "DEA II"];
