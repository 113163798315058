import { injectIntl } from "react-intl";
import { Typography, Grid, Box, Avatar, Card, CardHeader, CardContent } from "@mui/material";
import {
  GRADO_AFFOLLAMENTO_MENU,
  GRADO_AFFOLLAMENTO_SOTTOTITOLO,
  NORMO_AFFOLLATO_TITLE,
  POCO_AFFOLLATO_TITLE,
  AFFOLLATO_TITLE,
  SOVRAFFOLLATO_TITLE,
  NON_DISPONIBILE_TITLE,
  NON_DISPONIBILE_DESCRIZIONE,
  PPI_TITLE,
  PPI_DESCRIZIONE,
} from "../../modules/language";
import {
  GradoAffollamentoIcon,
  NormoAffollatoIcon,
  PocoAffollatoIcon,
  AffollatoIcon,
  SovraffollatoIcon,
  NonDisponibileIcon,
  PpiIcon,
} from "../../styles/images";
import { getMessageString } from "../../utility";

export const GradoAffollamento = injectIntl(({ intl }) => {
  const gradi = [
    {
      title: NORMO_AFFOLLATO_TITLE,
      avatar: <NormoAffollatoIcon />,
    },
    {
      title: POCO_AFFOLLATO_TITLE,
      avatar: <PocoAffollatoIcon />,
    },
    {
      title: AFFOLLATO_TITLE,
      avatar: <AffollatoIcon />,
    },
    {
      title: SOVRAFFOLLATO_TITLE,
      avatar: <SovraffollatoIcon />,
    },
    {
      title: NON_DISPONIBILE_TITLE,
      avatar: <NonDisponibileIcon />,
      description: NON_DISPONIBILE_DESCRIZIONE,
    },
    {
      title: PPI_TITLE,
      avatar: <PpiIcon />,
      description: PPI_DESCRIZIONE,
    },
  ];

  return (
    <Box className="gradoAffollamento">
      <Grid container spacing={2}>
        <Grid item xs={12} className="gradoAffollamentoTitle">
          <GradoAffollamentoIcon />
          <Typography variant="h1">{getMessageString(intl, GRADO_AFFOLLAMENTO_MENU)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">{getMessageString(intl, GRADO_AFFOLLAMENTO_SOTTOTITOLO)}</Typography>
        </Grid>
        <Box className="elencoGradi">
          {gradi.map((grado, index) => (
            <Grid item xs={12} key={index}>
              <Card>
                <CardHeader
                  avatar={<Avatar>{grado.avatar}</Avatar>}
                  title={<Typography variant="h2">{getMessageString(intl, grado.title)}</Typography>}
                />
                {grado.description && grado.description !== undefined && (
                  <CardContent>
                    <Typography variant="caption">{getMessageString(intl, grado.description)}</Typography>
                  </CardContent>
                )}
              </Card>
            </Grid>
          ))}
        </Box>
      </Grid>
    </Box>
  );
});
