import "./Loading.scss";

export const Loading = () => (
  <svg id="uuid-6cb50887-93c6-4319-932b-4970fd40a682_loading" viewBox="0 0 47.46 50.63" className="loadingIcon">
    <defs>
      <linearGradient
        id="uuid-f09f010c-b9fe-49fd-9c19-57b6112bec37_loading"
        x1="12.28"
        y1="40.44"
        x2="41.83"
        y2="40.44"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#4b78c6" />
        <stop offset="1" stopColor="#2b4281" />
      </linearGradient>
      <linearGradient
        id="uuid-1e328252-dbbc-4d92-a4a2-6a472d15e3cd_loading"
        x1="0"
        y1="16.05"
        x2="26.94"
        y2="16.05"
        xlinkHref="#uuid-f09f010c-b9fe-49fd-9c19-57b6112bec37_loading"
      />
      <linearGradient
        id="uuid-76fb758b-043a-4677-8113-0547f119421f_loading"
        x1="3.52"
        y1="26.25"
        x2="23.45"
        y2="26.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
      <linearGradient
        id="uuid-c5034fdd-a365-471d-a0d7-5f9f272aa2fc_loading"
        x1="14.19"
        y1="2.61"
        x2="19.41"
        y2="2.61"
        xlinkHref="#uuid-76fb758b-043a-4677-8113-0547f119421f_loading"
      />
      <linearGradient
        id="uuid-070bc37f-00fc-45cd-861e-dfc208720929_loading"
        x1="7.67"
        y1="2.61"
        x2="12.88"
        y2="2.61"
        xlinkHref="#uuid-76fb758b-043a-4677-8113-0547f119421f_loading"
      />
      <linearGradient
        id="uuid-03e01857-9309-436e-b4bc-fa60388bf9a2_loading"
        x1="34.59"
        y1="24.78"
        x2="47.46"
        y2="24.78"
        xlinkHref="#uuid-76fb758b-043a-4677-8113-0547f119421f_loading"
      />
      <linearGradient
        id="uuid-f4a423ee-9622-4f93-a5d8-882f49a35624_loading"
        x1="38.34"
        y1="24.78"
        x2="43.71"
        y2="24.78"
        xlinkHref="#uuid-f09f010c-b9fe-49fd-9c19-57b6112bec37_loading"
      />
    </defs>
    <g id="uuid-48eee8dc-1ac5-4f63-815e-19938af7425e_loading">
      <g id="uuid-d62e3ae0-65a7-40bf-ab83-d05bdd3be2f8_loading">
        <path
          className="uuid-c3e3c02d-d585-4037-aa34-d7356050bade"
          d="m27.05,50.63c-4.02,0-7.77-2.17-10.57-6.11-2.71-3.82-4.21-8.88-4.21-14.25h2.3c0,4.89,1.35,9.48,3.78,12.92,2.35,3.31,5.44,5.14,8.69,5.14h0c6.88,0,12.47-8.11,12.47-18.07h2.3c0,5.37-1.49,10.43-4.2,14.25-2.79,3.94-6.55,6.11-10.57,6.12h0Z"
        />
      </g>
      <g id="uuid-75003276-3588-4432-af22-659c358d8fca_loading">
        <path
          className="uuid-c5ced42b-738e-46de-83a0-dd6e484ac606"
          d="m13.68,30.59c-.07,0-.14,0-.21,0-.07,0-.14,0-.22,0h0c-3.43-.21-6.58-2.83-9.12-7.61C1.73,18.47.48,13.16.1,9.7c-.29-2.68.03-4.5,1.02-5.7,1.32-1.61,3.51-1.81,6.05-2.04,1.13-.1,2.31-.21,3.54-.45l.31,1.63c-1.31.25-2.58.37-3.69.47-2.26.21-4.05.37-4.92,1.44-.68.83-.89,2.29-.65,4.47.87,8.05,5.42,19.17,11.72,19.41,6.3-.24,10.85-11.36,11.72-19.41.24-2.18.02-3.65-.65-4.47-.88-1.07-2.66-1.24-4.92-1.44-1.12-.1-2.38-.22-3.69-.47l.31-1.63c1.23.23,2.4.34,3.54.45,2.54.23,4.74.44,6.05,2.04.99,1.21,1.31,3.02,1.02,5.7-.38,3.46-1.63,8.77-4.03,13.28-2.54,4.78-5.69,7.4-9.12,7.6h0s0,.01,0,.01Z"
        />
      </g>
      <g id="uuid-3b08adb0-0a61-4992-b94e-4339d38054d2_loading">
        <path
          className="uuid-09e95773-ddbf-4607-b851-5f2d81235623"
          d="m13.69,31.21c-.07,0-.14,0-.21,0-.07,0-.15,0-.22,0-.11,0-.21-.01-.31-.03-3.5-.32-6.69-2.98-9.26-7.73-.38-.7-.12-1.58.58-1.96s1.58-.12,1.96.58c1.51,2.79,3.98,6.11,7.25,6.25,3.27-.14,5.75-3.47,7.26-6.27.38-.7,1.25-.96,1.96-.59.7.38.96,1.25.59,1.96-2.57,4.77-5.77,7.43-9.28,7.76-.1.02-.21.04-.32.03Z"
        />
      </g>
      <g id="uuid-5abc21c1-ecef-47e2-b47e-1042ed00e8ab_loading">
        <circle className="uuid-800d2fde-cacc-41aa-8e3e-378f77a4e8a3" cx="16.8" cy="2.61" r="2.61" />
      </g>
      <g id="uuid-75b229e5-45ce-4332-bcff-e13cdf32b86c_loading">
        <circle className="uuid-e4894053-eb09-4e3e-8d58-e8ccba0528fc" cx="10.28" cy="2.61" r="2.61" />
      </g>
      <path
        className="uuid-96bdf614-c0d8-4b80-8a04-1640059151d0"
        d="m41.03,18.35c-3.55,0-6.44,2.88-6.44,6.44s2.88,6.44,6.44,6.44,6.44-2.88,6.44-6.44-2.88-6.44-6.44-6.44Zm0,10.73c-2.37,0-4.29-1.92-4.29-4.29s1.92-4.29,4.29-4.29,4.29,1.92,4.29,4.29-1.92,4.29-4.29,4.29Z"
      />
      <circle
        id="uuid-c38427ce-4585-4789-9817-1909c2dae432_loading"
        className="uuid-a5685b37-c91c-44d4-9718-82163f2d9f1a"
        cx="41.03"
        cy="24.78"
        r="2.68"
      />
    </g>
  </svg>
);
