import { Box, Typography } from "@mui/material";
import { injectIntl } from "react-intl";
import { NO_DATA } from "../modules/language";
import { getMessageString } from "../utility";
import { AlertIcon } from "../styles/images";

export const EmptyData = injectIntl(({ intl }) => (
  <Box className="emptyData">
    <AlertIcon />
    <Typography variant="h4">{getMessageString(intl, NO_DATA)}</Typography>
  </Box>
));
