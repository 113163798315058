import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { RicercaOspedale } from "../ricercaOspedale/RicercaOspedale";
import { PATH_DETTAGLIO, PATH_MAPPA } from "../../path";
import {
  changeFilterValues,
  getFilterValues,
  resetFilterValues,
  PREV_LOCATION,
  NOME_PS,
  AFFOLLAMENTO_NORMO_AFFOLLATO,
  AFFOLLAMENTO_POCO_AFFOLLATO,
  AFFOLLAMENTO_AFFOLLATO,
  AFFOLLAMENTO_SOVRAFFOLLATO,
  GENOVA,
  SAVONA,
  IMPERIA,
  LA_SPEZIA,
  getElencoProntoSoccorso,
  NOME_BREVE_OSPEDALE_JSON,
  ID_OSPEDALE_JSON,
  IS_OSPEDALE_CLOSE_JSON,
} from "../../modules/prontoSoccorso";
import { getUserPosition, LATITUDINE, LONGITUDINE } from "../../modules/configuration";
import { OspedaleClosedModal } from "../elencoProntoSoccorso/OspedaleClosedModal";
import { LegendaGradoAffollamento } from "./LegendaGradoAffollamento";

/*
Legenda affollamento per mappa
S = Sovraffollato
A = Affollato
P = Poco affollato

GE, SV, SP, IM
*/

export const Mappa = () => {
  const [data, setData] = useState(null);
  const [showClosedModal, setShowClosedModal] = useState(false);
  const [filtriMappa, setFiltriMappa] = useState("");
  const elencoProntoSoccorso = useSelector(getElencoProntoSoccorso);
  const filterValues = useSelector(getFilterValues);
  const userPosition = useSelector(getUserPosition);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeFilterValues({ field: NOME_PS, value: "" }));
    dispatch(resetFilterValues());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let filtri = "";
    let ospedale = null;
    let affollamento = [];
    let provincia = [];
    if (filterValues[NOME_PS] !== "") {
      const ps = elencoProntoSoccorso.find((ps) => ps[NOME_BREVE_OSPEDALE_JSON].toUpperCase() === filterValues[NOME_PS].toUpperCase());
      if (ps !== undefined) ospedale = ps[ID_OSPEDALE_JSON];
    }
    if (filterValues[AFFOLLAMENTO_NORMO_AFFOLLATO]) affollamento.push("N");
    if (filterValues[AFFOLLAMENTO_POCO_AFFOLLATO]) affollamento.push("P");
    if (filterValues[AFFOLLAMENTO_AFFOLLATO]) affollamento.push("A");
    if (filterValues[AFFOLLAMENTO_SOVRAFFOLLATO]) affollamento.push("S");
    if (filterValues[GENOVA]) provincia.push("GE");
    if (filterValues[SAVONA]) provincia.push("SV");
    if (filterValues[IMPERIA]) provincia.push("IM");
    if (filterValues[LA_SPEZIA]) provincia.push("SP");
    if (ospedale) filtri += "&ospedale=" + ospedale;
    if (affollamento.length > 0) filtri += "&affollamento=" + affollamento.join();
    if (provincia.length > 0) filtri += "&provincia=" + provincia.join();
    if (userPosition[LATITUDINE] && userPosition[LONGITUDINE]) filtri += "&lat=" + userPosition[LATITUDINE] + "&lng=" + userPosition[LONGITUDINE];
    setFiltriMappa(filtri);
    // eslint-disable-next-line
  }, [filterValues]);

  useEffect(() => {
    window.addEventListener(
      "message",
      (event) => {
        if (event.data.messaggio === "scheda-ospedale") {
          if (event.data.ospedale && event.data.ospedale !== undefined) {
            const ospedaleSelezionato = elencoProntoSoccorso.find((ps) => ps[ID_OSPEDALE_JSON].toString() === event.data.ospedale.toString());
            if (ospedaleSelezionato !== undefined) {
              if (ospedaleSelezionato[IS_OSPEDALE_CLOSE_JSON]) {
                setData(ospedaleSelezionato);
                setShowClosedModal(true);
              } else {
                sessionStorage.setItem(PREV_LOCATION, PATH_MAPPA);
                navigate(PATH_DETTAGLIO + "/" + event.data.ospedale);
              }
            }
          }
        }
      },
      false
    );
    // eslint-disable-next-line
  }, [elencoProntoSoccorso]);

  return (
    <Box sx={{ height: "100%" }} className="mappa">
      <Typography variant="h1" sx={{ height: 0 }}>
        <div style={{ display: "none" }}>Mappa</div>
      </Typography>
      <RicercaOspedale />
      <Box sx={{ position: "relative", height: "100%" }}>
        <LegendaGradoAffollamento />
        <iframe
          src={"https://srvcarto.regione.liguria.it/geoservices/apps/viewer/pages/apps/pronto-soccorso/?domain=" + window.location.origin + filtriMappa} //prettier-ignore
          id="mappaProntoSoccorso"
          title="Mappa dei pronto soccorso liguri"
          width="100%"
          height="83%"
        />
      </Box>
      <OspedaleClosedModal
        data={data}
        modalOpen={showClosedModal}
        handleClose={() => {
          setShowClosedModal(false);
          setData(null);
        }}
      />
    </Box>
  );
};
