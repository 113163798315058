import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Typography, Grid, Box, Avatar, Card, CardHeader, CardContent } from "@mui/material";
import { CODICI_COLORE_MENU, CODICI_COLORE_SOTTOTITOLO } from "../../modules/language";
import { CodicePrioritàIcon } from "../../styles/images";
import { getMessageString } from "../../utility";
import { CODICE_ARANCIONE, CODICE_AZZURRO, CODICE_BIANCO, fetchCodiciColoreEnded, getCodiciColore } from "../../modules/prontoSoccorso";
import { LoadingInfo } from "../loading/LoadingInfo";

export const CodiciColore = injectIntl(({ intl }) => {
  const colori = useSelector(getCodiciColore);
  const fetchEnded = useSelector(fetchCodiciColoreEnded);

  return fetchEnded ? (
    <Box className="codiciColore">
      <Grid container spacing={2}>
        <Grid item xs={12} className="codiciTitle">
          <CodicePrioritàIcon />
          <Typography variant="h1">{getMessageString(intl, CODICI_COLORE_MENU)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">{getMessageString(intl, CODICI_COLORE_SOTTOTITOLO)}</Typography>
        </Grid>
        <Box className="elencoCodici">
          {colori.map((colore, index) => (
            <Grid
              item
              xs={12}
              key={index}
              sx={{
                gridColumn: colore.col,
                gridRow: colore.row,
                ...((colore.id === CODICE_ARANCIONE || colore.id === CODICE_BIANCO || colore.id === CODICE_AZZURRO) && {
                  borderTop: "1px solid #339c98",
                }),
                ...((colore.id === CODICE_BIANCO || colore.id === CODICE_AZZURRO) && { borderBottom: "1px solid #339c98" }),
              }}
            >
              <Card>
                <CardHeader
                  avatar={<Avatar sx={{ bgcolor: colore.color, ...(colore.id === CODICE_BIANCO && { border: "1px solid black" }) }} />}
                  title={colore.title}
                  subheader={colore.subtitle}
                />
                <CardContent>
                  {Array.isArray(colore.description) ? (
                    colore.description.map((desc, indexDesc) => (
                      <Typography variant="caption" key={indexDesc}>
                        {desc}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="caption">{colore.description}</Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Box>
      </Grid>
    </Box>
  ) : (
    <LoadingInfo />
  );
});
