import "./Filtri.scss";

export const Filtri = () => (
  <svg id="uuid-1f822c70-7ff1-427a-b00a-eddae5896ec1_filtri" viewBox="0 0 40.48 48.7" className="filtriIcon">
    <defs>
      <linearGradient id="uuid-c1077baa-f574-49bb-aea8-3b19f802349c_filtri" x1="0" y1="29.05" x2="40.48" y2="29.05" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#95d0e5" />
        <stop offset="1" stopColor="#009ee3" />
      </linearGradient>
      <linearGradient id="uuid-afebac06-3fbd-4972-8dbe-d39bbc05b5a6_filtri" x1="5.2" y1="8.05" x2="21.29" y2="8.05" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#4b78c6" />
        <stop offset="1" stopColor="#2b4281" />
      </linearGradient>
      <linearGradient
        id="uuid-9b13807d-4e54-40db-8b0f-fee63e412afe_filtri"
        x1="22.45"
        y1="13.34"
        x2="35.56"
        y2="13.34"
        xlinkHref="#uuid-afebac06-3fbd-4972-8dbe-d39bbc05b5a6_filtri"
      />
      <linearGradient
        id="uuid-40e536e6-e8d8-47bb-9f0b-ed211777b2dd_filtri"
        x1="15.14"
        y1="22.69"
        x2="23.87"
        y2="22.69"
        xlinkHref="#uuid-afebac06-3fbd-4972-8dbe-d39bbc05b5a6_filtri"
      />
    </defs>
    <g id="uuid-79aa1895-d412-447a-82da-e8db7125d42c_filtri">
      <path
        className="uuid-8ba856cb-9044-42bc-ace0-f952cbdaa424"
        d="m13.56,32.12c.61.61.95,1.43.95,2.29v13.25c0,.37.2.71.51.9.17.1.35.15.54.15.17,0,.34-.04.5-.13l9.37-5.06c.34-.18.55-.54.55-.92v-8.19c0-.86.34-1.68.95-2.29l13.26-13.26c.2-.2.31-.46.31-.74v-4.44c0-2.36-1.92-4.28-4.28-4.28H4.28c-2.36,0-4.28,1.92-4.28,4.28v4.44c0,.28.11.54.31.74l13.26,13.26Z"
      />
      <path
        className="uuid-01a5f897-9e16-42c6-b0a3-7f35ea050fce"
        d="m13.24,0c-2.15,0-4.17.84-5.69,2.36-1.52,1.52-2.36,3.54-2.36,5.69,0,2.15.84,4.17,2.36,5.69,1.57,1.57,3.63,2.35,5.69,2.35,2.06,0,4.12-.78,5.69-2.35h0c1.52-1.52,2.36-3.54,2.36-5.69,0-2.15-.84-4.17-2.36-5.69C17.42.84,15.4,0,13.24,0h0"
      />
      <path
        className="uuid-24ebe8bb-a808-4577-9e3b-3ea63c0c96f7"
        d="m29.01,6.79c-1.75,0-3.39.68-4.63,1.92-1.24,1.24-1.92,2.88-1.92,4.63,0,1.75.68,3.39,1.92,4.63,1.28,1.28,2.95,1.92,4.63,1.92,1.68,0,3.35-.64,4.63-1.92h0c1.24-1.24,1.92-2.88,1.92-4.63,0-1.75-.68-3.39-1.92-4.63-1.24-1.24-2.88-1.92-4.63-1.92h0"
      />
      <path
        className="uuid-f934bc27-7bb1-43af-8298-7d006d3b499f"
        d="m19.5,18.33c-1.17,0-2.26.45-3.09,1.28-.82.82-1.28,1.92-1.28,3.09,0,1.17.45,2.26,1.28,3.09.85.85,1.97,1.28,3.09,1.28,1.12,0,2.24-.43,3.09-1.28h0c.82-.82,1.28-1.92,1.28-3.09,0-1.17-.45-2.26-1.28-3.09-.82-.82-1.92-1.28-3.09-1.28h0"
      />
    </g>
  </svg>
);
