import { createSelector } from "@reduxjs/toolkit";

const getProntoSoccorsoStore = (state) => state.prontoSoccorso;

export const getElencoProntoSoccorso = createSelector(getProntoSoccorsoStore, (prontoSoccorso) => prontoSoccorso.elencoProntoSoccorso.data);

export const fetchElencoProntoSoccorsoEnded = createSelector(getProntoSoccorsoStore, (prontoSoccorso) => prontoSoccorso.elencoProntoSoccorso.fetchEnded); //prettier-ignore

export const getDettaglioProntoSoccorso = createSelector(getProntoSoccorsoStore, (prontoSoccorso) => prontoSoccorso.dettaglioProntoSoccorso.data);

export const fetchDettaglioProntoSoccorsoEnded = createSelector(getProntoSoccorsoStore, (prontoSoccorso) => prontoSoccorso.dettaglioProntoSoccorso.fetchEnded); //prettier-ignore

export const getFilterValues = createSelector(getProntoSoccorsoStore, (prontoSoccorso) => prontoSoccorso.filterValues);

export const getFaq = createSelector(getProntoSoccorsoStore, (prontoSoccorso) => prontoSoccorso.faq.data);

export const fetchFaqEnded = createSelector(getProntoSoccorsoStore, (prontoSoccorso) => prontoSoccorso.faq.fetchEnded);

export const getCodiciColore = createSelector(getProntoSoccorsoStore, (prontoSoccorso) => prontoSoccorso.codiciColore.data);

export const fetchCodiciColoreEnded = createSelector(getProntoSoccorsoStore, (prontoSoccorso) => prontoSoccorso.codiciColore.fetchEnded);

export const getContatti = createSelector(getProntoSoccorsoStore, (prontoSoccorso) => prontoSoccorso.contatti.data);

export const fetchContattiEnded = createSelector(getProntoSoccorsoStore, (prontoSoccorso) => prontoSoccorso.contatti.fetchEnded);

export const getClassificazione = createSelector(getProntoSoccorsoStore, (prontoSoccorso) => prontoSoccorso.classificazione.data);

export const fetchClassificazioneEnded = createSelector(getProntoSoccorsoStore, (prontoSoccorso) => prontoSoccorso.classificazione.fetchEnded);

export const getPrivacyPolicy = createSelector(getProntoSoccorsoStore, (prontoSoccorso) => prontoSoccorso.privacy.data);

export const fetchPrivacyPolicyEnded = createSelector(getProntoSoccorsoStore, (prontoSoccorso) => prontoSoccorso.privacy.fetchEnded);
