import { configureStore } from "@reduxjs/toolkit";
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { createReducers } from "./appReducers";
import logger from "redux-logger";
import { configurationMiddleware } from "../../modules/configuration";
import { languageMiddleware } from "../../modules/language";
import { prontoSoccorsoMiddleware } from "../../modules/prontoSoccorso";

const createStore = (
  withLogs = false,
  whitelist = [
    /*"configuration"*/
  ]
) => {
  const allReducers = createReducers(whitelist);
  const store = configureStore({
    reducer: allReducers,
    middleware: (getDefaultMiddleware) => {
      let appSagas = getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          // ignoredActionPaths: ["payload.value", "payload.dataDiNascita"],
          // ignoredPaths: ["language.messages"],
        },
      });
      if (withLogs && withLogs === true) appSagas = appSagas.prepend(logger);
      // Aggiungere qui eventuali middlewares
      appSagas = appSagas.prepend(languageMiddleware.middleware, prontoSoccorsoMiddleware.middleware, configurationMiddleware.middleware);
      return appSagas;
    },
    devutility: process.env.NODE_ENV !== "production",
  });
  const persistor = persistStore(store);
  return { store, persistor };
};

const { store, persistor } = createStore();

export { store, persistor };
