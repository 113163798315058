// Label comuni
export const NO_DATA = "common.noData";
export const CERCA = "common.cerca";
export const FILTRI = "common.filtri";
export const GRADO_AFFOLLAMENTO = "common.gradoAffollamento";
export const PROVINCIA = "common.provincia";
export const AZZERA = "common.azzera";
export const OK = "common.ok";
export const PREFERITI_LABEL = "common.preferiti";
export const NO_LABEL = "common.no";
export const YES_LABEL = "common.yes";
export const LOADING = "common.loading";

// Label navbar
export const NAVBAR_MAPPA = "navbar.mappa";
export const NAVBAR_ELENCO = "navbar.elenco";
export const NAVBAR_DASHBOARD = "navbar.dashboard";
export const NAVBAR_INFO = "navbar.info";

// Label per le info
export const CODICI_COLORE_MENU = "info.menu.codiciColore";
export const GRADO_AFFOLLAMENTO_MENU = "info.menu.gradoAffollamento";
export const PRIVACY_POLICY_MENU = "info.menu.privacyPolicy";

// Label per l'ospedale chiuso
export const OSPEDALE_CHIUSO_INFO = "ospedale.chiuso.info";
export const OSPEDALE_CHIUSO_INFO_2 = "ospedale.chiuso.info2";
export const OSPEDALE_CHIUSO_DETAIL = "ospedale.chiuso.detail";
export const OSPEDALE_CHIUSO_DETAIL_2 = "ospedale.chiuso.detail2";

// Label per i codici priorità
export const CODICI_COLORE_SOTTOTITOLO = "info.codiciColore.sottotitolo";

// Label per la mappa
export const GRADO_AFFOLLAMENTO_LEGENDA = "mappa.gradoAffollamento.legenda";

// Label per il grado affollamento
export const GRADO_AFFOLLAMENTO_SOTTOTITOLO = "info.gradoAffollamento.sottotitolo";
export const NORMO_AFFOLLATO_TITLE = "info.gradoAffollamento.normoAffollatoTitle";
export const NORMO_AFFOLLATO_DESCRIZIONE = "info.gradoAffollamento.normoAffollatoDescrizione";
export const POCO_AFFOLLATO_TITLE = "info.gradoAffollamento.pocoAffollatoTitle";
export const POCO_AFFOLLATO_DESCRIZIONE = "info.gradoAffollamento.pocoAffollatoDescrizione";
export const AFFOLLATO_TITLE = "info.gradoAffollamento.affollatoTitle";
export const AFFOLLATO_DESCRIZIONE = "info.gradoAffollamento.affollatoDescrizione";
export const SOVRAFFOLLATO_TITLE = "info.gradoAffollamento.sovraffollatoTitle";
export const SOVRAFFOLLATO_DESCRIZIONE = "info.gradoAffollamento.sovraffollatoDescrizione";
export const NON_DISPONIBILE_TITLE = "info.gradoAffollamento.nonDisponibileTitle";
export const NON_DISPONIBILE_DESCRIZIONE = "info.gradoAffollamento.nonDisponibileDescrizione";
export const PPI_TITLE = "info.gradoAffollamento.ppiTitle";
export const PPI_DESCRIZIONE = "info.gradoAffollamento.ppiDescrizione";
export const APERTO_TITLE = "info.gradoAffollamento.apertoTitle";
export const CHIUSO_TITLE = "info.gradoAffollamento.chiusoTitle";

// Label per il dettaglio
export const AREA = "dettaglio.area";
export const ASL = "dettaglio.asl";
export const CLASSIFICAZIONE = "dettaglio.classificazione";
export const SPECIALIZZAZIONE = "dettaglio.specializzazione";
export const ORARIO = "dettaglio.orario";
export const ORARIO_DESCRIZIONE = "dettaglio.orarioDescrizione";
export const INDIRIZZO = "dettaglio.indirizzo";
export const NAVIGA = "dettaglio.naviga";
export const AFFLUENZA = "dettaglio.affluenza";
export const AFFLUENZA_ATTESA = "dettaglio.personeAttesa";
export const AFFLUENZA_IN_CURA = "dettaglio.personeTrattate";
export const PAZIENTI_ATTESA = "dettaglio.pazientiAttesa";
export const PAZIENTI_IN_CURA = "dettaglio.pazientiTrattati";
export const LAST_UPDATE = "dettaglio.lastUpdate";
export const LAST_UPDATE_GIORNO = "dettaglio.updateGiorno";
export const LAST_UPDATE_GIORNI = "dettaglio.updateGiorni";
export const LAST_UPDATE_ORA = "dettaglio.updateOra";
export const LAST_UPDATE_ORE = "dettaglio.updateOre";
export const LAST_UPDATE_MINUTO = "dettaglio.updateMinuto";
export const LAST_UPDATE_MINUTI = "dettaglio.updateMinuti";
export const LAST_UPDATE_SECONDI = "dettaglio.updateSecondi";
export const LAST_UPDATE_AGO = "dettaglio.updateAgo";
export const GOOGLE_MAPS_MODAL = "dettaglio.googleMaps";

// Label per le faq
export const FAQ_SEARCH = "info.faq.search";

// Label per la pagina not found
export const NOT_FOUND_TITLE = "notFound.title";
export const NOT_FOUND_DESCRIPTION = "notFound.description";
export const RESOURCE_NOT_FOUND_DESCRIPTION = "resourceNotFound.description";

// Label per i sagas
export const CONFIG_ERROR_TITLE = "sagas.configErrorTitle";
export const CONFIG_ERROR_DESCRIPTION = "sagas.configErrorDescription";
export const ELENCO_OSPEDALI_ERROR_TITLE = "sagas.elencoOspedaliErrorTitle";
export const ELENCO_OSPEDALI_ERROR_DESCRIPTION = "sagas.elencoOspedaliErrorDescription";
export const DETTAGLIO_OSPEDALE_ERROR_TITLE = "sagas.dettaglioOspedaleErrorTitle";
export const DETTAGLIO_OSPEDALE_ERROR_DESCRIPTION = "sagas.dettaglioOspedaleErrorDescription";
export const FAQ_ERROR_TITLE = "sagas.faqErrorTitle";
export const FAQ_ERROR_DESCRIPTION = "sagas.faqErrorDescription";
export const CODICI_COLORE_ERROR_TITLE = "sagas.codiciColoreErrorTitle";
export const CODICI_COLORE_ERROR_DESCRIPTION = "sagas.codiciColoreErrorDescription";
export const PRIVACY_ERROR_TITLE = "sagas.privacyErrorTitle";
export const PRIVACY_ERROR_DESCRIPTION = "sagas.privacyErrorDescription";
