import "./LogoRegione.scss";

export const LogoRegione = () => (
  <svg id="Livello_2_regione" data-name="Livello 2" viewBox="0 0 563.27 278.03" className="logoRegione">
    <g id="Livello_1-2_regione" data-name="Livello 1">
      <g>
        <path
          className="cls-1"
          d="m1.06,162.93V42.76h0v-2.15c0-2.24.13-4.32.43-6.27.31-2.04.8-3.96,1.53-5.8.72-1.82,1.68-3.56,2.93-5.25,1.24-1.66,2.74-3.25,4.56-4.77,2.78-2.32,7.86-4.6,14.62-6.69,6.77-2.09,15.29-4.04,24.9-5.68,9.43-1.62,19.88-2.94,30.73-3.83h0c10.17-.84,20.76-1.3,31.26-1.27,10.5-.03,21.09.43,31.26,1.27h0c10.85.89,21.3,2.22,30.73,3.83,9.6,1.65,18.13,3.59,24.9,5.68,6.76,2.09,11.84,4.36,14.62,6.69,1.82,1.52,3.33,3.11,4.56,4.77,1.25,1.69,2.21,3.43,2.93,5.25.73,1.84,1.22,3.76,1.53,5.8.3,1.95.42,4.04.43,6.27v2.15s0,0,0,0v122.32H1.06v-2.15Z"
        />
        <rect className="cls-4" x="106.7" y="1.62" width="10.66" height="162.83" />
        <rect className="cls-4" x="1.24" y="83.07" width="220.97" height="10.66" />
        <path
          className="cls-3"
          d="m3.79,169.51l53.78,2.86,2.08.11-.05,2.08v.42s-.01,1.07-.01,1.07c0,8.81,1.78,17.19,5,24.83,3.35,7.94,8.26,15.1,14.36,21.09,6.09,5.98,13.37,10.81,21.47,14.1,7.4,3.01,15.46,4.74,23.9,4.91h1.39c8.46,0,16.57-1.57,24.04-4.43,7.73-2.96,14.76-7.31,20.76-12.74,5.93-5.36,10.87-11.8,14.5-19.01,3.51-6.97,5.79-14.65,6.55-22.79l.18-1.96h31.23l-.36,2.46c-1.95,13.15-6.19,25.5-12.29,36.66-6.28,11.49-14.54,21.72-24.29,30.24-10,8.75-21.58,15.72-34.22,20.47-12.25,4.6-25.47,7.1-39.21,7.1-14.62,0-28.62-2.82-41.46-7.96-13.3-5.32-25.33-13.12-35.52-22.85-10.09-9.63-18.36-21.15-24.25-34-5.68-12.4-9.13-26.04-9.83-40.43l-.12-2.37,2.37.13Z"
        />
        <path
          className="cls-2"
          d="m3.19,40.61h0v122.32h217.65V40.61h.02c0-4.2-.49-7.92-1.82-11.28-1.31-3.32-3.48-6.33-6.87-9.17-5.19-4.34-19.71-8.68-38.51-11.9-18.17-3.11-40.21-5.14-61.64-5.07-21.43-.06-43.47,1.96-61.64,5.07-18.8,3.22-33.32,7.56-38.51,11.9-3.39,2.84-5.56,5.85-6.87,9.17-1.33,3.37-1.81,7.08-1.82,11.28m-3.19,0h0c0-4.6.54-8.7,2.03-12.46,1.51-3.81,3.96-7.23,7.8-10.44,5.62-4.7,20.7-9.29,40.02-12.6C68.15,1.97,90.38-.06,112.03,0c21.65-.06,43.88,1.97,62.18,5.11,19.32,3.31,34.4,7.9,40.02,12.6,3.83,3.21,6.29,6.63,7.8,10.44,1.49,3.76,2.02,7.86,2.03,12.46h0v125.52h-.06s-98.47,0-98.47,0H0V40.61Z"
        />
        <polygon
          className="cls-3"
          points="170.78 110.01 165.94 120.27 154.65 119.33 161.11 128.64 154.65 137.96 165.94 137.02 170.84 147.19 175.61 137.02 186.92 137.91 180.45 128.64 186.92 119.36 175.61 120.27 170.78 110.01"
        />
        <polygon
          className="cls-3"
          points="53.45 110.01 48.61 120.27 37.32 119.33 43.78 128.64 37.32 137.96 48.61 137.02 53.51 147.19 58.28 137.02 69.59 137.91 63.12 128.64 69.59 119.36 58.28 120.27 53.45 110.01"
        />
        <polygon
          className="cls-3"
          points="170.78 24.64 165.94 34.9 154.65 33.96 161.11 43.27 154.65 52.58 165.94 51.64 170.84 61.82 175.61 51.64 186.92 52.54 180.45 43.27 186.92 33.99 175.61 34.9 170.78 24.64"
        />
        <polygon
          className="cls-3"
          points="53.45 24.64 48.61 34.9 37.32 33.96 43.78 43.27 37.32 52.58 48.61 51.64 53.51 61.82 58.28 51.64 69.59 52.54 63.12 43.27 69.59 33.99 58.28 34.9 53.45 24.64"
        />
        <rect className="cls-5" x="110.18" y="164.86" width="3.71" height="75.43" />
        <path
          className="cls-2"
          d="m57.46,174.51l-53.78-2.86c1.41,28.72,13.97,54.41,33.42,72.97,19.63,18.74,46.27,30.21,75.5,30.21,27.42,0,52.71-10.13,72.01-27.03,18.83-16.48,31.98-39.39,35.87-65.6h-26.79c-1.6,17.13-9.69,32.28-21.74,43.19-12.2,11.04-28.48,17.73-46.24,17.73h0c-18.84,0-35.91-7.5-48.26-19.64-12.36-12.14-20.01-28.92-20.01-47.44h0c0-.38,0-.9.03-1.54m3.32-2.46l-.05.75c-.03.59-.05,1.14-.07,1.65-.01.39-.02.92-.02,1.6h0c0,17.64,7.28,33.61,19.05,45.17,11.78,11.57,28.04,18.72,46.02,18.72h0c16.93,0,32.45-6.38,44.08-16.9,11.63-10.52,19.37-25.18,20.76-41.74v-2.01h33.39v1.34h0c-3.61,27.85-17.33,52.2-37.2,69.58-19.88,17.39-45.91,27.83-74.14,27.83-30.09,0-57.51-11.81-77.72-31.11C14.79,227.75,1.83,201.18.46,171.48h0l-.15-2.87,60.47,3.45Z"
        />
        <path
          className="cls-5"
          d="m281.38,104.87v-43.99h16.24c5.97,0,10.38,1.04,13.23,3.11,2.84,2.07,4.27,5.29,4.27,9.65,0,2.43-.63,4.51-1.89,6.24-1.26,1.73-3.18,3.19-5.78,4.38,1.09.4,2.07,1.06,2.94,2,.87.93,1.62,2.11,2.28,3.54l7.02,15.07h-12.42l-5.6-12.68c-.9-2.02-1.93-3.4-3.08-4.15-1.16-.76-2.79-1.14-4.92-1.14-.16,0-.39.01-.65.03-.28.02-.48.03-.63.03v17.9h-11.01Zm16.31-25.95c2.04,0,3.55-.43,4.55-1.28,1-.85,1.49-2.14,1.49-3.88s-.52-2.92-1.56-3.79c-1.04-.87-2.53-1.3-4.49-1.3h-5.29v10.24h5.29Z"
        />
        <polygon
          className="cls-5"
          points="323.17 104.87 323.17 60.88 352.6 60.88 352.6 69 334.18 69 334.18 77.95 351.77 77.95 351.77 86.13 334.18 86.13 334.18 96.63 353.12 96.63 353.12 104.87 323.17 104.87"
        />
        <path
          className="cls-5"
          d="m400.27,99.7c-2.68,2.02-5.53,3.51-8.54,4.49-3.01.98-6.3,1.46-9.89,1.46s-6.64-.53-9.54-1.59c-2.89-1.06-5.4-2.6-7.51-4.6-2.22-2.12-3.92-4.59-5.08-7.42-1.17-2.83-1.75-5.87-1.75-9.12,0-6.86,2.29-12.37,6.85-16.54,4.56-4.18,10.61-6.26,18.14-6.26,2.75,0,5.46.32,8.15.98,2.68.64,5.42,1.65,8.21,3.02v9.66c-2.92-1.49-5.69-2.61-8.34-3.34-2.64-.74-5.11-1.11-7.42-1.11-4.45,0-7.93,1.2-10.47,3.62-2.52,2.41-3.79,5.73-3.79,9.97s1.2,7.42,3.62,9.87c2.4,2.44,5.65,3.66,9.71,3.66,1.08,0,2.17-.12,3.23-.34,1.07-.24,2.14-.57,3.21-1.01v-5.54h-6.05v-8.24h17.26v18.4Z"
        />
        <rect className="cls-5" x="408.27" y="60.88" width="11.34" height="43.99" />
        <path
          className="cls-5"
          d="m426.29,82.91c0-3.27.58-6.29,1.73-9.07,1.16-2.78,2.88-5.26,5.16-7.44,2.22-2.09,4.74-3.66,7.57-4.68,2.82-1.03,5.96-1.55,9.43-1.55s6.67.52,9.5,1.56c2.83,1.04,5.36,2.6,7.58,4.67,2.27,2.18,3.98,4.66,5.14,7.44,1.17,2.78,1.75,5.8,1.75,9.07s-.58,6.26-1.75,9.04c-1.16,2.78-2.88,5.24-5.14,7.38-2.24,2.09-4.79,3.67-7.63,4.73-2.83,1.06-5.99,1.59-9.45,1.59s-6.57-.53-9.39-1.57c-2.82-1.05-5.36-2.64-7.6-4.75-2.26-2.14-3.98-4.59-5.14-7.38-1.17-2.78-1.75-5.79-1.75-9.04m11.34,0c0,3.92,1.18,7.15,3.53,9.67,2.36,2.52,5.37,3.79,9.02,3.79s6.68-1.27,9.04-3.79c2.35-2.52,3.53-5.75,3.53-9.67s-1.18-7.18-3.53-9.71c-2.36-2.54-5.38-3.81-9.04-3.81s-6.66,1.27-9.02,3.81c-2.35,2.53-3.53,5.78-3.53,9.71Z"
        />
        <polygon
          className="cls-5"
          points="491.47 77.23 491.47 104.87 480.59 104.87 480.59 60.88 490.24 60.88 513.82 87.1 513.82 60.88 524.7 60.88 524.7 104.87 516.08 104.87 491.47 77.23"
        />
        <polygon
          className="cls-5"
          points="533.32 104.87 533.32 60.88 562.76 60.88 562.76 69 544.34 69 544.34 77.95 561.92 77.95 561.92 86.13 544.34 86.13 544.34 96.63 563.27 96.63 563.27 104.87 533.32 104.87"
        />
        <polygon className="cls-5" points="281.12 165.36 281.12 121.37 292.14 121.37 292.14 156.86 311.07 156.86 311.07 165.36 281.12 165.36" />
        <rect className="cls-5" x="320.1" y="121.37" width="11.34" height="43.99" />
        <path
          className="cls-5"
          d="m384.19,160.19c-2.68,2.02-5.53,3.51-8.54,4.49-3.01.98-6.3,1.46-9.89,1.46s-6.64-.53-9.54-1.59c-2.89-1.06-5.4-2.6-7.51-4.6-2.22-2.12-3.92-4.59-5.08-7.42-1.17-2.83-1.75-5.87-1.75-9.12,0-6.86,2.29-12.37,6.85-16.54,4.56-4.18,10.61-6.26,18.14-6.26,2.75,0,5.46.32,8.15.98,2.68.64,5.42,1.65,8.21,3.02v9.66c-2.92-1.49-5.69-2.61-8.34-3.34-2.64-.74-5.11-1.11-7.42-1.11-4.45,0-7.93,1.2-10.47,3.62-2.52,2.41-3.79,5.73-3.79,9.97s1.2,7.42,3.62,9.87c2.4,2.44,5.65,3.66,9.71,3.66,1.08,0,2.17-.12,3.23-.34,1.07-.24,2.14-.57,3.21-1.01v-5.54h-6.05v-8.24h17.26v18.4Z"
        />
        <path
          className="cls-5"
          d="m436.9,121.37v25.18c0,6.16-1.82,10.96-5.48,14.41-3.66,3.45-8.77,5.17-15.33,5.17s-11.65-1.73-15.31-5.17c-3.66-3.45-5.48-8.24-5.48-14.41v-25.18h11.08v24.74c0,3.5.84,6.17,2.51,8.03,1.67,1.86,4.11,2.78,7.29,2.78s5.58-.92,7.24-2.77c1.65-1.84,2.48-4.52,2.48-8.04v-24.74h11.01Z"
        />
        <path
          className="cls-5"
          d="m449.28,165.36v-43.99h16.24c5.97,0,10.38,1.04,13.23,3.11,2.84,2.07,4.27,5.29,4.27,9.65,0,2.43-.63,4.51-1.89,6.24-1.26,1.73-3.18,3.19-5.78,4.38,1.1.4,2.07,1.06,2.94,2,.87.93,1.62,2.11,2.28,3.54l7.02,15.07h-12.42l-5.6-12.68c-.9-2.02-1.93-3.4-3.08-4.15-1.16-.76-2.79-1.14-4.92-1.14-.16,0-.39.01-.65.03-.28.02-.48.03-.63.03v17.9h-11.01Zm16.31-25.95c2.04,0,3.55-.43,4.55-1.28,1-.85,1.49-2.14,1.49-3.88s-.52-2.92-1.56-3.79c-1.04-.87-2.53-1.3-4.49-1.3h-5.29v10.24h5.29Z"
        />
        <rect className="cls-5" x="495.13" y="121.37" width="11.33" height="43.99" />
        <path
          className="cls-5"
          d="m513.49,165.36l19.18-43.99h12.7l17.13,43.99h-11.67l-3.15-8.57h-18.96l-3.52,8.57h-11.72Zm18.42-16.1h12.99l-6.16-16.75-6.83,16.75Z"
        />
      </g>
    </g>
  </svg>
);
